import moment from "moment";
import { format } from "date-fns";
import { lv } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import UserData from "../../types/models/user-data";

const MoodBar = (props: any) => {
  const { t, i18n } = useTranslation();

  const numberOfMoodsInColumn = 6;
  const isToday = moment(props.day.date).isSame(moment(), "day");
  const data = props.day.models.map((x: UserData) => x.value);

  function getWeekday(): string {
    if (moment(props.day.date).isSame(moment(), "day")) {
      return t("general.text.today");
    } else {
      return format(new Date(props.day.date), i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM", {
        locale: i18n.resolvedLanguage === "lv" ? lv : null,
      });
    }
  }

  function title(index: number): string {
    const hasMoodData = data.includes(index);
    if (!hasMoodData) {
      return "";
    }

    switch (index) {
      case 1:
        return "😭";
      case 2:
        return "🙁";
      case 3:
        return "😐";
      case 4:
        return "🙂";
      case 5:
        return "😊";
      case 6:
        return "🤩";
      default:
        return "";
    }
  }

  function color(index: number): string {
    if (!data.includes(index)) {
      return isToday ? "bg-sky": "bg-moodbg opacity-30";
    }
    return null;
  }

  return (
    <>
      <li className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <>
            <p className={`font-bold px-1 py-4 ${isToday ? "text-active" : "text-dark"}`}>{getWeekday()} </p>
            <div className="items-center">
              {Array.from(Array(numberOfMoodsInColumn).keys()).map((index: number) => (
                <div key={index} className={`w-16 h-16 ${color(numberOfMoodsInColumn - index)} bg-active mb-2 rounded-full`}>
                  <div className="text-5xl p-2">{title(numberOfMoodsInColumn - index)}</div>
                </div>
              ))}
            </div>
          </>
        </div>
      </li>
    </>
  );
};

export default MoodBar;
