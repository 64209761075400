const LoadingComponent = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-sky  flex flex-col items-center justify-center">
      <div>
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-48 h-48 border-8 border-white border-solid rounded-full animate-spin"
        />
      </div>
    </div>
  );
};

export default LoadingComponent;
