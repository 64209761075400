import DailyPlanModule from "./daily-plan-module";
import {Expose, Type} from "class-transformer";

class DailyPlanHistoryItem {
  @Expose()
  id: number;

  @Expose()
  @Type(() => DailyPlanModule)
  userDailyPlanModule: DailyPlanModule;

  @Expose()
  createdAt: Date | string;
}

export default DailyPlanHistoryItem;
