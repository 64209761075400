import { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Formular-Black.otf";
import "./assets/fonts/Formular-BlackItalic.otf";
import "./assets/fonts/Formular-Bold.otf";
import "./assets/fonts/Formular-BoldItalic.otf";
import "./assets/fonts/Formular-Italic.otf";
import "./assets/fonts/Formular-Light.otf";
import "./assets/fonts/Formular-LightItalic.otf";
import "./assets/fonts/Formular-Medium.otf";
import "./assets/fonts/Formular-MediumItalic.otf";
import "./assets/fonts/Formular-Regular.otf";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LoadingComponent from "./components/loading/loading.component";
import { RotateModal } from "./components/vigo-modals";
import "./i18n";

const loadingMarkup = <LoadingComponent />;

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <RotateModal />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
