import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { EditIcon, TrashIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import moment from "moment";
import { lv } from "date-fns/locale";
import ActivityEditForm from "../activity-edit-form/activity-edit-form.component";
import DailyActivity from "../../types/models/daily-activity";

const ActivityChart = (props: any) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const { t, i18n } = useTranslation();

  const isToday = moment(props.day.date).isSame(moment(), "day");
  const models: DailyActivity[] = props.day.models ?? [];
  const vigoModel = models.find((x) => !x.type);
  const otherModels = models.filter((x) => x.type);

  const vigoMinutes = vigoModel ? Math.floor(vigoModel.value / 60) : 0;
  const otherMinutes = otherModels
    .filter((x) => x.type)
    .reduce((acc, x) => {
      return acc + Math.floor(x.value / 60);
    }, 0);

  const handleDelete = async (index: number) => {
    props.onDelete(otherModels[index].id);
  };

  const handleEdit = async (index: number) => {
    setEditIndex(index);
    setEdit(true);
  };

  const handleEditSubmit = async (values: DailyActivity) => {
    setEdit(false);
    values.id = otherModels[editIndex].id;
    props.onEdit(values);
  };

  function getWeekday(): string {
    if (moment(props.day.date).isSame(moment(), "day")) {
      return t("general.text.today");
    } else {
      return format(new Date(props.day.date), i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM", {
        locale: i18n.resolvedLanguage === "lv" ? lv : null,
      });
    }
  }

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className={
          "m-0 w-full cursor-pointer flex flex-col relative justify-end border-silver" + (isToday && " bg-chartBg")
        }
        style={{ height: "422px" }}
      >
        {/* {props.goal > 0 && (
          <div
            className="border-dashed border-b-2 border-sky w-full z-0 absolute"
            style={{ bottom: `${props.goal}px` }}
          />
        )} */}
        {/* <div className="border-dashed border-b border-silver w-full z-0 absolute" style={{ top: "22px" }}></div>
        <div className="border-dashed border-b border-silver h-1 w-full z-0 absolute" style={{ top: "202px" }} />
        <div className="border-dashed border-b border-silver h-1 w-full z-0 absolute" style={{ top: "382px" }} /> */}
        <div className="flex-col mx-4 z-10">
          <span className="flex justify-center text-center  font-bold text-dark z-50 ">
            {vigoMinutes + otherMinutes > 0 && (
              <>
                {vigoMinutes + otherMinutes} {t("general.text.min")}
              </>
            )}
          </span>
          {otherMinutes > 158 && (
            <div
              className={props.isColorImpairment ? "w-full rounded-t-xl bg-active" : "w-full rounded-t-xl bg-dark"}
              style={{ height: `${152 * 2.4}px` }}
            ></div>
          )}

          {otherMinutes < 158 && (
            <div
              className={props.isColorImpairment ? "w-full rounded-t-xl bg-active" : "w-full rounded-t-xl bg-dark"}
              style={{ height: `${otherMinutes * 2.4}px` }}
            ></div>
          )}
          <div
            className={
              props.isColorImpairment
                ? `${
                    otherMinutes === 0
                      ? "w-full  rounded-t-xl border-transparent activity-stripes"
                      : "w-full border-t-2  border-white activity-stripes"
                  }`
                : `${
                    otherMinutes === 0
                      ? "w-full  rounded-t-xl border-transparent bg-active"
                      : "w-full border-t-2  border-white bg-active"
                  }`
            }
            style={{ height: `${vigoMinutes * 2.44}px` }}
          ></div>
        </div>
        <div className="flex justify-center pt-4 bg-white">
          <p className={"text-center text-sm font-bold text-dark" + (isToday && " text-sky")}>{getWeekday()}</p>
        </div>
      </div>
      <Transition.Root show={edit ? !open : open} as={Fragment}>
        <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mb-3 text-left ">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("activity.day.title")}{" "}
                      {format(new Date(props.day.date), i18n.resolvedLanguage === "lv" ? "dd. MMMM" : "MMMM dd", {
                        locale: i18n.resolvedLanguage === "lv" ? lv : null,
                      })}
                    </Dialog.Title>
                  </div>
                  <table className="table-auto w-full">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>{t("activity.day.exercising.vigo")}</td>
                        <td>
                          {vigoMinutes} {t("general.text.min")}
                        </td>
                      </tr>
                      {otherModels.map((x: DailyActivity, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{t(`activity.input.activity.${x.type}`)}</td>
                            <td>
                              {Math.floor(x.value / 60)} {t("general.text.min")}
                            </td>
                            <td>
                              <div className="flex justify-end">
                                <img
                                  className="cursor-pointer h-4 w-4 mx-2"
                                  onClick={() => handleEdit(index)}
                                  src={EditIcon}
                                  alt=""
                                />
                                <img
                                  className="cursor-pointer h-4 w-4 mx-2"
                                  src={TrashIcon}
                                  onClick={() => handleDelete(index)}
                                  alt=""
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={edit} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setEdit}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mb-3 text-left ">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("activity.edit.title")}
                    </Dialog.Title>
                  </div>
                  <ActivityEditForm
                    initialValues={otherModels[editIndex]}
                    onSubmit={(values) => handleEditSubmit(values)}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default ActivityChart;
