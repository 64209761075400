import AbstractService from "../abstract/abstract.service";
import moment from "moment";
import ActivityReport from "../../types/models/activity-report";
import CompletionRate from "../../types/models/completion-rate";

class DailyPlanService extends AbstractService<any> {
  API_SEGMENT = "user-daily-plans";

  async fetchActivityReport(userId: number) {
    const dateStr = moment().format('yyyy-MM-DD');
    const url = `${this.API_SEGMENT}/activity-report/?userId=${userId}&startDate=1970-01-01&endDate=${dateStr}`
    const result = await this.axios.get<ActivityReport[]>(url);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async fetchCompletionRate(userId: number) {
    const dateStr = moment().format('yyyy-MM-DD');
    const url = `${this.API_SEGMENT}/completion-rate-total/?userId=${userId}&startDate=1970-01-01&endDate=${dateStr}`
    const result = await this.axios.get<CompletionRate[]>(url);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async automate(userId: number) {
    const dateStr = moment().format('yyyy-MM-DD');
    const params = {
      user: userId,
      date: dateStr,
    }
    return await this.axios.post(`${this.API_SEGMENT}/automate`, params);
  }

  async sendTimeStats(planId: number, interval: number, isExercising: boolean) {
    const params = {
      timeInApp: interval,
      timeInExercises: isExercising ? interval : 0,
    };
    return this.axios.put(`${this.API_SEGMENT}/${planId}/increment`, params);
  }
}

export default new DailyPlanService();
