import {useQuery} from "react-query";
import UserService from "../../services/user/user.service";

const useOnboardingUser = (hash: string) => {
    return useQuery(
        ['users', 'hash'],
        () => UserService.getUserByHash(hash),
        {staleTime: 30000}
    );
};

export default useOnboardingUser;
