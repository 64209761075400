import { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import classNames from "classnames";

export class VigoSelectOption {
  label: string;
  value: string;
}

class VigoSelectProps {
  showLabel?: boolean;
  labelText?: string;
  options?: VigoSelectOption[];
}

type VigoSelectPropsType = VigoSelectProps &
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

function VigoSelect(props: VigoSelectPropsType) {
  const { showLabel, labelText, name, options, required } = props;

  const selectProps = { ...props };
  delete selectProps.showLabel;
  delete selectProps.labelText;
  delete selectProps.options;

  return (
    <>
      {showLabel && (
        <label htmlFor={name} className="block font-bold text-sm text-dark">
          {labelText}
        </label>
      )}
      <select
        {...selectProps}
        required={required}
        id={name}
        style={{
          MozAppearance: "none",
          WebkitAppearance: "none",
          appearance: "none",
        }}
        className={classNames(
          "mt-1 focus:ring-sky focus:border-silver block w-full font-regular shadow-sm sm:text-lg border-gray rounded-sm py-2",
          required ? "border-red" : "",
        )}
      >
        {options?.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </>
  );
}

export default VigoSelect;
