import {SubscriptionPlanType} from "../../types/models/subscription-plan";

class ConstantsService {
    getSubscriptionPlanFeatures(key: SubscriptionPlanType) {
        switch (key) {
            case SubscriptionPlanType.Digital:
                return [
                    "general.subscription-plan.digital.feature.1",
                    "general.subscription-plan.digital.feature.2",
                    "general.subscription-plan.digital.feature.3",
                ];
            case SubscriptionPlanType.Full:
                return [
                    "general.subscription-plan.full.feature.1",
                    "general.subscription-plan.full.feature.2",
                    "general.subscription-plan.full.feature.3",
                    "general.subscription-plan.full.feature.4",
                    "general.subscription-plan.full.feature.5",
                    "general.subscription-plan.full.feature.6",
                ];
            case SubscriptionPlanType.Premium:
                return [
                    "general.subscription-plan.premium.feature.1",
                    "general.subscription-plan.premium.feature.2",
                    "general.subscription-plan.premium.feature.3",
                    "general.subscription-plan.premium.feature.4",
                    "general.subscription-plan.premium.feature.5",
                    "general.subscription-plan.premium.feature.6",
                    "general.subscription-plan.premium.feature.7",
                    "general.subscription-plan.premium.feature.8",
                ];
        }
    }
}

export default new ConstantsService();
