import {useQuery} from "react-query";
import PaymentsService from "../../services/payments/payments.service";

const useStripeCheckout = (returnUrl: string) => {
    return useQuery(
        ['payments', 'checkout'],
        () => PaymentsService.getCheckoutLink(returnUrl),
        {staleTime: 0}
    );
};

export default useStripeCheckout;
