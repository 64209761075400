import {Expose, Type} from "class-transformer";
import Intent from "./intent";

class ModuleLocalization {
  @Expose()
  id: number;

  @Expose()
  title: string;

  @Expose()
  thumbnail: string;

  @Expose()
  @Type(() => Intent)
  intents: Intent[];
}

export default ModuleLocalization;
