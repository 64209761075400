import {useQuery} from "react-query";
import PaymentsService from "../../services/payments/payments.service";

const usePaymentCustomer = () => {
    return useQuery(
        ['payments', 'customer'],
        () => PaymentsService.getPaymentCustomer(),
        {staleTime: Infinity}
    );
};

export default usePaymentCustomer;
