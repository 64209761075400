import Subscription, { SubscriptionState } from "../../types/models/subscription";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { lv } from "date-fns/locale";
class UpcomingChargeProps {
  subscription: Subscription;
}

function UpcomingCharge(props: UpcomingChargeProps) {
  const [lang, setLang] = useState(false);
  const { t, i18n } = useTranslation();
  const { subscription } = props;
  useEffect(() => {
    if (i18n.language === "lv") {
      setLang(true);
    }
  }, [i18n.language]);
  const subscriptionPlanKey = subscription.subscriptionPlan.key;
  const discount = subscription.promotionCode?.amountOff ?? 0;
  const subscriptionPlanPrice = (subscription.subscriptionPlan.price - discount) / 100;
  const upcomingChargeDate = format(new Date(subscription.expireDate), lang ? "dd. LLLL, yyyy" : "dd MMMM yyyy", {
    locale: lang ? lv : null,
  });
  const isCurrent = subscription.state === SubscriptionState.Paid || subscription.state === SubscriptionState.Active;

  return (
    isCurrent && (
      <div className=" mt-5 p-12 border border-silver rounded-lg">
        <h2 className="py-2 text-4xl font-bold text-dark">{t("general.subscription-plan.upcoming.title")}</h2>
        <div className="flex justify-start">
          <div className="py-4">
            <p className="">
              <span className="text-lg font-bold">{t("general.subscription-plan.plan")}</span>
              <br />
              <span className="text-dark text-xl">{t(`general.subscription-plan.${subscriptionPlanKey}`)}</span>
            </p>
          </div>
          <div className="py-4">
            <p className="ml-28">
              <span className="text-lg font-bold">{t("general.subscription-plan.amount")}</span>
              <br />
              <span className="text-dark text-xl">
                EUR {subscriptionPlanPrice}/{t("general.subscription-plan.month")}.
              </span>
            </p>
          </div>
          <div className="py-4">
            <p className="ml-28">
              <span className="text-lg  font-bold">{t("general.subscription-plan.date")}</span>
              <br />
              <span className="text-dark text-xl">{upcomingChargeDate}</span>
            </p>
          </div>
        </div>
      </div>
    )
  );
}

export default UpcomingCharge;
