import { useQuery } from "react-query";
import UserService from "../../services/user/user.service";

const useDailyPlan = (userId: number, localeId: string) => {
  return useQuery(["dailyPlan", userId, localeId], () => UserService.fetchDailyPlan(userId, localeId), {
    staleTime: 30000,
  });
};

export default useDailyPlan;
