import { useState, useEffect } from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { lv } from "date-fns/locale";
import Bar from "../../../../components/my-progress-chart/my-progress-chart.component";
import { RightArrowIcon, LeftArrowIcon } from "../../../../assets/icons";
import VigoButton from "../../../../components/vigo-button/vigo-button.component";
import useAuth from "../../../../hooks/useAuth/useAuth";
import useGoal from "../../../../hooks/useGoal/useGoal";
import useUserTest from "../../../../hooks/useUserTest/useUserTest";
import MyProgressGoals from "../../../../components/my-progress-goals/my-progress-goals.component";
import ChartBarData, { getDateRangeForChartBarData } from "../../../../types/classes/chart-bar-model";
import UserTest from "../../../../types/models/user-test";
import moment from "moment";
import MyProgressAssessment from "../../../../components/my-progress-assessment/my-progress-assessment.component";

const ProgramGoalsTab = () => {
  const { user } = useAuth();

  const goal = useGoal();
  const [goalData, setGoalData] = useState([]);
  const [currentGoalActivities, setCurrentGoalActivities] = useState([]);

  const userTest = useUserTest(user.id);
  const [userTestData, setUserTestData] = useState(null);

  const { t, i18n } = useTranslation();
  const [dataIndex, setDataIndex] = useState(1);

  const [test, setTest] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLeftArrowClick = () => {
    const newValue = dataIndex - 2;
    if (newValue < 1) return;
    setDataIndex(newValue);
  };
  const handleRightArrowClick = () => {
    const newValue = dataIndex + 2;
    if (newValue > userTestData.length - 1) return;
    setDataIndex(newValue);
  };

  useEffect(() => {
    if (goal.error || userTest.error) {
      setErrorMessage(`${goal.error || userTest.error}`);
      setError(true);
    }
  }, [goal.error, userTest.error]);

  useEffect(() => {
    if (!userTest.error && userTest.data) {
      const data = userTest.data;
      const uniqueDates = new Set(data.map((x) => x.createdAt));
      const chartDays = Array.from(uniqueDates)
        .map((x) => moment(x))
        .sort((a, b) => a.toDate().getTime() - b.toDate().getTime())
        .map((x) => {
          return {
            date: x.toDate(),
            models: data.filter((y) => moment(y.createdAt).isSame(x)),
          };
        });
      setUserTestData(chartDays);
    }
  }, [userTest.data]);

  useEffect(() => {
    if (userTestData && userTestData.length) {
      setCurrentGoalActivities(userTestData[userTestData.length - 1].models.map((x: UserTest) => x.goalActivity));
    } else {
      setCurrentGoalActivities([]);
    }
  }, [userTestData]);

  useEffect(() => {
    if (goal.data && !goal.error) {
      setGoalData(goal.data);
    }
  }, [goal.data]);

  function nextTestDate(): Date {
    if (userTestData && userTestData.length) {
      return new Date(
        new Date(userTestData[userTestData.length - 1].date).setDate(
          new Date(userTestData[userTestData.length - 1].date).getDate() + 28,
        ),
      );
    } else {
      return null;
    }
  }

  function isNextTestAvailable(): boolean {
    const nextDate = nextTestDate();
    if (nextDate) {
      const now = moment();
      const next = moment(nextDate);
      return now.isSame(next, "day") || now.toDate().getTime() > next.toDate().getTime();
    } else {
      return false;
    }
  }

  return (
    <>
      {test ? (
        <MyProgressAssessment
          isColorImpairment={user.isColorImpairment}
          setTest={setTest}
          goalData={goalData}
          currentGoalActivities={currentGoalActivities}
        />
      ) : (
        ""
      )}
      {isNextTestAvailable() ? (
        <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl">{t("goals.add.label")}</h2>
            <VigoButton onClick={() => setTest(true)} appearance={"primary"}>
              {t("goals.add.button")}
            </VigoButton>
          </div>
        </div>
      ) : (
        ""
      )}
      {!userTestData ? (
        <></>
      ) : !userTestData.length ? (
        <div>
          <div className="flex justify-center mt-5 p-8 border border-silver bg-white rounded-lg">
            <div className="flex-col p-32 text-center">
              <h2 className="text-3xl font-bold py-2">{t("goals.intro.title")}</h2>
              <p className="text-2xl font-normal py-2 max-w-lg">{t("goals.intro.subtitle")}</p>
              <br />
              <VigoButton
                disabled={!goalData.length}
                onClick={() => setTest(true)}
                appearance={goalData.length ? "primary" : "disabled"}
              >
                {t("goals.intro.button")}
              </VigoButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
          <div className="pr-0">
            <div className="flex justify-between xl:px-16">
              <div className="grid justify-items-start">
                <h2 className="leading-2 font-bold text-2xl pb-4">{t("goals.intro.title")}</h2>
                {userTestData.length > 1 && (
                  <div className="flex justify-center ">
                    <button onClick={handleLeftArrowClick}>
                      <img src={LeftArrowIcon} alt="left icon" />
                    </button>
                    <p className="text-center w-44">
                      {getDateRangeForChartBarData(
                        userTestData.slice(dataIndex === 1 ? 0 : dataIndex, dataIndex + 2),
                        i18n.resolvedLanguage === "lv" ? lv : null,
                        i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM",
                      )}
                    </p>
                    <button onClick={handleRightArrowClick}>
                      <img src={RightArrowIcon} alt="right icon" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex-col justify-center ">
                <div className="flex justify-between py-1 ">
                  <div
                    className={
                      user.isColorImpairment
                        ? "w-6 h-6 absolute mini-performance-stripes z-30"
                        : "w-6 h-6 absolute bg-lightGreen z-30"
                    }
                  />
                  <span className="pl-8 mx-1 text-lg">{t("goals.report.label.abilities")}</span>
                </div>

                <div className="flex justify-between py-1">
                  <div
                    className={
                      user.isColorImpairment
                        ? "w-6 h-6 absolute satisfaction-stripes z-30"
                        : "w-6 h-6 absolute bg-darkGreen z-30"
                    }
                  />
                  <span className="pl-8 mx-1 text-lg">{t("goals.report.label.satisfaction")}</span>
                </div>
              </div>
            </div>

            <div className="max-w-full mt-6 mx-auto xl:px-16 ">
              <div className="bg-white overflow-hidden">
                <div className="flex mx-4 z-10 justify-center">
                  <div
                    style={{
                      width: "63%",
                      maxWidth: "900px",
                      marginTop: "34px",
                    }}
                    className="absolute"
                  >
                    <div style={{ height: "1px" }} className="bg-silver" />
                    <span className="text-silver absolute -left-6 -top-3">10</span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span className="text-silver absolute -left-4 top-6">9</span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span className="text-silver absolute -left-4 top-16">8</span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "100px" }} className="text-silver absolute -left-4">
                      7
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "135px" }} className="text-silver absolute -left-4">
                      6
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "172px" }} className="text-silver absolute -left-4">
                      5
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "210px" }} className="text-silver absolute -left-4">
                      4
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "248px" }} className="text-silver absolute -left-4">
                      3
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "285px" }} className="text-silver absolute -left-4">
                      2
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "322px" }} className="text-silver absolute -left-4">
                      1
                    </span>
                    <div style={{ height: "1px" }} className="bg-silver mt-9" />
                    <span style={{ top: "358px" }} className="text-silver absolute -left-4">
                      0
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-0" style={{ height: "480px" }}>
                  <span
                    style={{ width: "2px" }}
                    className="h-96 absolute bg-lightGreen ml-48 xl:ml-56 lg:ml-52 mt-5"
                  ></span>
                  <Bar
                    isColorImpairment={user.isColorImpairment}
                    performance={
                      userTestData[0].models.reduce((accumulator: number, obj: UserTest) => {
                        return accumulator + obj.currentMark;
                      }, 0) / userTestData[0].models.length
                    }
                    satisfaction={
                      userTestData[0].models.reduce((accumulator: number, obj: UserTest) => {
                        return accumulator + obj.aimMark;
                      }, 0) / userTestData[0].models.length
                    }
                    date={userTestData[0].date}
                    title={t("goals.test.first")}
                  />
                  {userTestData.slice(dataIndex, dataIndex + 2).map((day: ChartBarData<UserTest>, index: number) => {
                    return (
                      <Bar
                        key={index}
                        isColorImpairment={user.isColorImpairment}
                        performance={
                          day.models.reduce((accumulator: number, obj: UserTest) => {
                            return accumulator + obj.currentMark;
                          }, 0) / day.models.length
                        }
                        satisfaction={
                          day.models.reduce((accumulator: number, obj: UserTest) => {
                            return accumulator + obj.aimMark;
                          }, 0) / day.models.length
                        }
                        date={day.date}
                        title={userTestData.length - 1 === dataIndex + index ? t("goals.test.last") : null}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {nextTestDate() && !isNextTestAvailable() ? (
        <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl">
              {" "}
              {t("goals.test.next")}{" "}
              <span className="font-bold text-dark">
                {
                  format(nextTestDate(), i18n.resolvedLanguage === "lv" ? "dd. MMMM" : "MMMM dd", {
                    locale: i18n.resolvedLanguage === "lv" ? lv : null,
                  }).split("-")[0]
                }
              </span>
            </h2>
            <VigoButton
              disabled={!isNextTestAvailable()}
              onClick={() => setTest(true)}
              appearance={isNextTestAvailable() ? "primary" : "disabled"}
            >
              {t("goals.add.button")}
            </VigoButton>
          </div>
        </div>
      ) : (
        ""
      )}
      {currentGoalActivities.length ? <MyProgressGoals currentGoalActivities={currentGoalActivities} /> : ""}
      <br />
    </>
  );
};

export default ProgramGoalsTab;
