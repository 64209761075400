import {useEffect, useState} from "react";
import ProfileForm from "../../../../components/profile-form/profile-form.component";
import ProfileStats from "../../../../components/profile-stats/profile-stats.component";
import useAuth from "../../../../hooks/useAuth/useAuth";
import UserService from "../../../../services/user/user.service";
import ProfileLogout from "../../../../components/profile-logout/profile-logout.component";

import ProfileSettings from "../../../../components/profile-settings/profile-settings.component";
import ProfileDisclaimer from "../../../../components/profile-disclaimer/profile-disclaimer.component";
import UserState from "../../../../types/enums/user-state";

function MyDataTab() {
  const { user, logout, refreshState } = useAuth();

  const handleSubmit = async (data: any) => {
    await UserService.updateUser(user.id, data);
    await refreshState();
  };

  return (
    <div className="py-4">
      {user?.state === UserState.Active && <ProfileStats />}
      <ProfileForm onSubmit={handleSubmit} initialValues={user} />
      {user?.state === UserState.Active && <ProfileSettings />}
      <ProfileLogout logout={logout} />
      <ProfileDisclaimer />
    </div>
  );
}

export default MyDataTab;
