import {useQuery} from "react-query";
import PaymentsService from "../../services/payments/payments.service";

const useStripePortal = (returnUrl: string) => {
    return useQuery(
        ['payments', 'portal'],
        () => PaymentsService.getPortalLink(returnUrl),
        {staleTime: 0}
    );
};

export default useStripePortal;
