import { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import VigoButton from "../vigo-button/vigo-button.component";
import { useTranslation } from "react-i18next";
import { PauseIcon, PlayIcon, ReplayIcon } from "../../assets/icons";
import VigoCheckbox from "../vigo-checkbox/vigo-checkbox.component";
import { DownArrowIcon, UpArrowIcon, CloseIcon } from "../../assets/icons";
import useAuth from "../../hooks/useAuth/useAuth";
import GoalActivity from "../../types/models/goal-activity";
import UserTest from "../../types/models/user-test";
import UserTestService from "../../services/user-test/user-test.service";
import GoalKey from "../../types/enums/goal-key";

enum MyProgressAssessmentStep {
  Intro,
  Checkbox,
  Order,
  OrderTotal,
  Five,
  Scale,
  Report,
}

const MyProgressAssessment = (props: any) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(0);

  const hasGoalActivities = props.currentGoalActivities && props.currentGoalActivities.length;
  const numberOfScaleSteps = hasGoalActivities ? props.currentGoalActivities.length : 5;
  const numberOfGoals = props.goalData.length;
  const numberOfSteps = hasGoalActivities ? numberOfScaleSteps + 2 : 2 * numberOfGoals + numberOfScaleSteps + 4;
  const lastStepIndex = numberOfSteps - 1;

  const initialUserTests = hasGoalActivities
    ? props.currentGoalActivities.map((x: GoalActivity) => {
        const userTest = new UserTest();
        userTest.goalActivity = x;
        userTest.currentMark = 0;
        userTest.aimMark = 0;
        return userTest;
      })
    : [];
  const [userTests, setUserTests] = useState(initialUserTests);

  const goals = props.goalData;
  const [activitiesPerGoal, setActivitiesPerGoal] = useState([[]]);
  const [activitiesTotal, setActivitiesTotal] = useState([]);

  const [audioImage, setAudioImage] = useState(PlayIcon);

  const onAudioButton = async () => {
    const audio = document.getElementsByTagName("audio")[0];
    if (audio.ended) {
      audio.currentTime = 0;
      await audio.play();
    } else if (audio.paused) {
      await audio.play();
    } else if (audio.played) {
      await audio.pause();
    }
  };

  const goForward = async () => {
    const newValue = currentIndex + 1;

    switch (stepForIndex(newValue)) {
      case MyProgressAssessmentStep.OrderTotal:
        const newValue: GoalActivity[] = [];
        const maxLength = Math.max(...activitiesPerGoal.map((x) => x.length));
        Array.from(Array(maxLength).keys()).forEach((ind: number) => {
          activitiesPerGoal.forEach((list) => {
            if (ind < list.length) {
              newValue.push(list[ind]);
            }
          });
        });
        setActivitiesTotal(newValue);
        break;
      case MyProgressAssessmentStep.Five:
        setUserTests(
          activitiesTotal.slice(0, 5).map((goalActivity: GoalActivity) => {
            const userTest = new UserTest();
            userTest.goalActivity = goalActivity;
            userTest.currentMark = 0;
            userTest.aimMark = 0;
            return userTest;
          }),
        );
        break;
      default:
        break;
    }

    if (newValue < numberOfSteps) {
      setCurrentIndex(newValue);
    } else {
      await UserTestService.createUserTests(userTests, user.id);
      // finish the assessment
      props.setTest(false);
      // reload page
      window.location.reload();
    }
  };

  const goBack = () => {
    switch (stepForIndex(currentIndex)) {
      case MyProgressAssessmentStep.Checkbox:
        setActivitiesPerGoal(activitiesPerGoal.slice(0, Math.floor(currentIndex / 2)));
        break;
      case MyProgressAssessmentStep.Scale:
        const userTestIndex = userTests.length - (lastStepIndex - currentIndex);
        userTests.slice(userTestIndex, userTests.length).forEach((x: UserTest) => {
          x.currentMark = 0;
          x.aimMark = 0;
        });
        break;
      default:
        break;
    }

    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setOpen(true);
    }
  };

  function IntroComponent() {
    setButtonEnabled(true);

    function getTitle(): string {
      if (user.formOfAddress && user.formOfAddress.length) {
        return `${t("goals.survey.intro.title")} ${user.formOfAddress}`;
      }
      return t("goals.survey.intro.title");
    }

    return !hasGoalActivities ? (
      <>
        <p className="text-left font-normal text-2xl">
          {getTitle()},
          <br />
          <br />
          {t("goals.survey.intro.message.0")}
          <br />
          <br />
          {t("goals.survey.intro.message.1")}
          <br />
          <br />
          {t("goals.survey.intro.message.2")}
          <br />
          <br />
          {t("goals.survey.intro.message.3")}
          <br />
          <br />
          {t("goals.survey.intro.message.4")}
        </p>
      </>
    ) : (
      <>
        <p className="text-left font-normal text-2xl">
          {getTitle()},
          <br />
          <br />
          {t("goals.survey.intro.repeated.message.0")}
          <br />
          <br />
          {t("goals.survey.intro.repeated.message.1")}
          <br />
          <br />
          {t("goals.survey.intro.repeated.message.2")}
          <br />
          <br />
          {t("goals.survey.intro.repeated.message.3")}
        </p>
      </>
    );
  }

  function CheckboxComponent(props: any) {
    const goal = goals[props.index];
    const [activities, setActivities] = useState(orderedAccordingToGoal(activitiesPerGoal[props.index] ?? []));

    function orderedAccordingToGoal(list: GoalActivity[]): GoalActivity[] {
      return goal.activities.filter((x: GoalActivity) => list.includes(x));
    }

    useEffect(() => {
      setButtonEnabled(activities.length > 1);

      const newValue = activitiesPerGoal;
      newValue[props.index] = activities;
      setActivitiesPerGoal(newValue);
    }, [activities]);

    const handleCheckboxChange = (e: any) => {
      const goalActivity = goal.activities.find((x: GoalActivity) => x.key === e.target.value);
      if (goalActivity) {
        const newList = activities.includes(goalActivity)
          ? activities.filter((item: any) => item !== goalActivity)
          : [...activities, goalActivity];
        setActivities(orderedAccordingToGoal(newList));
      }
    };

    return (
      <>
        <div>
          <p className="text-2xl">{t("goals.pick.title", { key: t(`goals.pick.category.${goal.key}`) })}</p>
          <p className="py-4 text-xl">{t("goals.pick.message")}</p>
        </div>
        {goal.activities.map((goalActivity: GoalActivity, index: number) => (
          <VigoCheckbox
            key={index}
            type="checkbox"
            name={t(`goal.${goalActivity.key}`)}
            className="mt-4"
            labelText={t(`goal.${goalActivity.key}`)}
            showLabel={true}
            checked={activities.includes(goalActivity)}
            onChange={handleCheckboxChange}
            value={goalActivity.key}
          />
        ))}
      </>
    );
  }

  function OrderComponent(props: any) {
    setButtonEnabled(true);

    const [activities, setActivities] = useState(
      props.index !== undefined ? activitiesPerGoal[props.index] : activitiesTotal,
    );

    useEffect(() => {
      if (props.index !== undefined) {
        const newValue = activitiesPerGoal;
        newValue[props.index] = activities;
        setActivitiesPerGoal(newValue);
      } else {
        setActivitiesTotal(activities);
      }
    }, [activities]);

    function changeOrder(index0: any, direction: any) {
      const index1 = index0 + (direction === "UP" ? -1 : 1);
      const min = Math.min(index0, index1);
      const max = Math.max(index0, index1);

      setActivities([
        ...activities.slice(0, min),
        ...[activities[max], activities[min]],
        ...activities.slice(max + 1, activities.length),
      ]);
    }

    return (
      <>
        <p className="text-2xl">{t("goals.order.title")}</p>
        <p className="py-4 text-xl max-w-xl">{t("goals.order.message")}</p>
        {activities.map((item: GoalActivity, index: number) => {
          return (
            <div key={index} className="">
              <ul className="flex items-center mb-4">
                <li className="w-96 text-lg font-regular rounded-l-lg shadow-3xl py-4 px-4">{t(`goal.${item.key}`)}</li>
                {t(`goal.${item.key}`).length > 41 ? (
                  <img
                    className={
                      index === 0
                        ? "hidden"
                        : "h-24 w-16 cursor-pointer" && index === activities.length - 1
                        ? "h-24 w-16 cursor-pointer bg-arrowBg rounded-r-lg"
                        : "h-24 w-16 cursor-pointer bg-arrowBg rounded-r-lg mr-2"
                    }
                    src={UpArrowIcon}
                    alt="up arrow"
                    onClick={() => changeOrder(index, "UP")}
                  />
                ) : (
                  <img
                    className={
                      index === 0
                        ? "hidden"
                        : "h-16 w-16 cursor-pointer" && index === activities.length - 1
                        ? "h-16 w-16 cursor-pointer bg-arrowBg rounded-r-lg"
                        : "h-16 w-16 cursor-pointer bg-arrowBg rounded-r-lg mr-2"
                    }
                    src={UpArrowIcon}
                    alt="up arrow"
                    onClick={() => changeOrder(index, "UP")}
                  />
                )}
                {/* <img
                  className={
                    index === 0
                      ? "hidden"
                      : "w-16 cursor-pointer" && index === activities.length - 1
                      ? "w-16 cursor-pointer bg-arrowBg rounded-r-lg"
                      : "w-16 cursor-pointer bg-arrowBg rounded-r-lg mr-2"
                  }
                  src={UpArrowIcon}
                  alt="up arrow"
                  onClick={() => changeOrder(index, "UP")}
                /> */}
                {t(`goal.${item.key}`).length > 41 ? (
                  <img
                    className={
                      index === activities.length - 1
                        ? "hidden"
                        : "h-24 w-16 cursor-pointer" && index === 0
                        ? "h-24 w-16 cursor-pointer bg-arrowBg rounded-r-lg"
                        : "h-24 w-16 cursor-pointer bg-arrowBg rounded-lg"
                    }
                    src={DownArrowIcon}
                    alt="down arrow"
                    onClick={() => changeOrder(index, "DOWN")}
                  />
                ) : (
                  <img
                    className={
                      index === activities.length - 1
                        ? "hidden"
                        : "h-16 w-16 cursor-pointer" && index === 0
                        ? "h-16 w-16 cursor-pointer bg-arrowBg rounded-r-lg"
                        : "h-16 w-16 cursor-pointer bg-arrowBg rounded-lg"
                    }
                    src={DownArrowIcon}
                    alt="down arrow"
                    onClick={() => changeOrder(index, "DOWN")}
                  />
                )}
              </ul>
            </div>
          );
        })}
      </>
    );
  }

  function FinalResultsComponent() {
    return (
      <div>
        <p className="text-2xl">{t("goals.five.title")}</p>
        <p className="py-4 text-xl max-w-xl">{t("goals.five.message")}</p>
        <ul className="list-decimal max-w-xl p-4 shadow-3xl">
          {activitiesTotal.slice(0, 5).map((item: any, index: any) => {
            return (
              <li key={index} className="text-xl ml-6">
                {t(`goal.${item.key}`)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  function ScaleComponent(props: any) {
    const [currentMark, setCurrentMark] = useState(props.userTest.currentMark);
    const [aimMark, setAimMark] = useState(props.userTest.aimMark);

    const ratingArray = Array.from(Array(10).keys());

    useEffect(() => {
      props.userTest.currentMark = currentMark;
      props.userTest.aimMark = aimMark;
      setButtonEnabled(currentMark && aimMark);
    }, [currentMark, aimMark]);

    return (
      <>
        <p className="text-2xl max-w-xl" />
        <p className="text-2xl">{t("goals.scale.title")}</p>
        <p className="py-4 text-xl max-w-xl">{t("goals.scale.message")}</p>
        <p className="font-bold text-xl">{t(`goal.${props.userTest.goalActivity.key}`)}</p>
        <div className="py-4 text-xl max-w-xl">
          <p>{t("goals.scale.my.abilities")}</p>
          <ul className="flex my-2  items-center">
            {ratingArray.map((_item: any, index: any) => {
              return (
                <li
                  key={index}
                  className={
                    index < currentMark
                      ? "h-10 w-12 mr-1 text-2xl py-1 px-1 bg-active text-white text-center   cursor-pointer"
                      : "h-10 w-12 mr-1 text-2xl py-1 px-1 bg-sky text-center text-active cursor-pointer hover:text-white hover:bg-active"
                  }
                  onClick={() => {
                    setCurrentMark(index + 1);
                  }}
                >
                  {index + 1}
                </li>
              );
            })}
          </ul>
          <p>{t("goals.scale.my.satisfaction")}</p>
          <ul className="flex my-2 items-center">
            {ratingArray.map((_item: any, index: any) => {
              return (
                <li
                  key={index}
                  className={
                    index < aimMark
                      ? "h-10 w-12 mr-1 text-2xl py-1 px-1 bg-active text-white text-center   cursor-pointer"
                      : "h-10 w-12 mr-1 text-2xl py-1 px-1 bg-sky text-center text-active cursor-pointer hover:text-white hover:bg-active"
                  }
                  onClick={() => {
                    setAimMark(index + 1);
                  }}
                >
                  {index + 1}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }

  function ReportComponent(props: any) {
    return (
      <>
        <div className="flex mx-4 z-10 justify-center ">
          <div style={{ maxWidth: "600px", marginTop: "132px" }} className="absolute w-full">
            <div style={{ height: "1px" }} className="bg-silver" />
            <span className="text-silver absolute -left-6 -top-3">10</span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span className="text-silver absolute -left-4 top-3">9</span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span className="text-silver absolute -left-4 top-10">8</span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span className="text-silver absolute -left-4 top-16">7</span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "90px" }} className="text-silver absolute -left-4">
              6
            </span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "115px" }} className="text-silver absolute -left-4">
              5
            </span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "138px" }} className="text-silver absolute -left-4">
              4
            </span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "163px" }} className="text-silver absolute -left-4">
              3
            </span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "189px" }} className="text-silver absolute -left-4">
              2
            </span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "213px" }} className="text-silver absolute -left-4">
              1
            </span>
            <div style={{ height: "1px" }} className="bg-silver mt-6" />
            <span style={{ top: "238px" }} className="text-silver absolute -left-4">
              0
            </span>
          </div>
        </div>
        <div className="flex mx-4 z-30 justify-center h-96">
          <div className="flex-col mt-auto" style={{ width: "20%", zIndex: 9999 }}>
            <div className="text-center px-14 ">
              <span className="shadow-3xl text-2xl font-bold p-2 bg-white">{props.performance}</span>
            </div>
            <span>
              <p
                className={`"text-lg rounded-r-2xl text-center absolute  text-white bg-lightGreen shadow-3xl" ${
                  props.performance > 2 ? "text-xl p-2 mt-8 ml-24" : "text-xl p-1 mt-2 ml-24"
                }`}
              >
                {t("goals.graph.legend.label.0")}
              </p>
            </span>
            <div
              className={
                props.isColorImpairment
                  ? "bg-lightGreen rounded-t-xl w-36 mx-auto mt-4"
                  : "rounded-t-xl performance-stripes w-36 mx-auto mt-4"
              }
              style={{ height: `${props.performance * 25.44}px` }}
            />
          </div>
          <div className="flex-col mt-auto" style={{ width: "20%", zIndex: 99 }}>
            <div className="text-center px-14">
              <span className="shadow-3xl text-2xl font-bold p-2 bg-white">{props.satisfaction}</span>
            </div>
            <span>
              <p
                className={`"text-lg rounded-r-2xl  text-center absolute  text-white bg-darkGreen shadow-3xl" ${
                  props.satisfaction > 2 ? "text-xl p-2 mt-8 ml-24" : "text-xl p-1 mt-2 ml-24"
                }`}
              >
                {t("goals.graph.legend.label.1")}
              </p>
            </span>
            <div
              className={
                props.isColorImpairment
                  ? "bg-darkGreen rounded-t-xl w-36 mx-auto mt-4"
                  : "rounded-t-xl satisfaction-stripes w-36 mx-auto mt-4"
              }
              style={{ height: `${props.satisfaction * 25.44}px` }}
            />
          </div>
        </div>
      </>
    );
  }

  function audioSrc(key: string): string {
    const filename = user.localeId === "en_US" ? `${key}_en` : key;
    return `https://vigoaudios.s3.eu-central-1.amazonaws.com/${filename}.mp3`;
  }

  function audioKey(index: number): string {
    switch (stepForIndex(index)) {
      case MyProgressAssessmentStep.Intro:
        return hasGoalActivities ? "intro_repeated" : "intro";
      case MyProgressAssessmentStep.Checkbox:
        const goal = goals[Math.floor(index / 2)];
        switch (goal.key) {
          case GoalKey.SelfCare:
            return "selfcare";
          case GoalKey.Productivity:
            return "productivity";
          case GoalKey.FreeTime:
            return "freetime";
        }
        return "";
      case MyProgressAssessmentStep.Order:
        return "list";
      case MyProgressAssessmentStep.OrderTotal:
        return "goals_total";
      case MyProgressAssessmentStep.Five:
        return "5goals";
      case MyProgressAssessmentStep.Scale:
        const userTestIndex = userTests.length - (lastStepIndex - currentIndex);
        if (userTestIndex === 0) {
          return "scale_start";
        } else if (userTestIndex === userTests.length - 1) {
          return "scale_end";
        } else {
          return "scale_middle";
        }
      case MyProgressAssessmentStep.Report:
        return "report";
    }
  }

  function stepForIndex(index: number): MyProgressAssessmentStep {
    if (index === 0) return MyProgressAssessmentStep.Intro;

    if (!hasGoalActivities) {
      const orderTotalIndex = numberOfGoals * 2 + 1;
      if (index < orderTotalIndex) {
        return index % 2 ? MyProgressAssessmentStep.Checkbox : MyProgressAssessmentStep.Order;
      } else if (index === orderTotalIndex) {
        return MyProgressAssessmentStep.OrderTotal;
      } else if (index === orderTotalIndex + 1) {
        return MyProgressAssessmentStep.Five;
      }
    }

    if (index < lastStepIndex) {
      return MyProgressAssessmentStep.Scale;
    } else {
      return MyProgressAssessmentStep.Report;
    }
  }

  function Component(index: number) {
    switch (stepForIndex(index)) {
      case MyProgressAssessmentStep.Intro:
        return <IntroComponent />;
      case MyProgressAssessmentStep.Checkbox:
        return <CheckboxComponent index={Math.floor(index / 2)} />;
      case MyProgressAssessmentStep.Order:
        return <OrderComponent index={Math.floor((index - 1) / 2)} />;
      case MyProgressAssessmentStep.OrderTotal:
        return <OrderComponent />;
      case MyProgressAssessmentStep.Five:
        return <FinalResultsComponent />;
      case MyProgressAssessmentStep.Scale:
        const userTestIndex = userTests.length - (lastStepIndex - index);
        return <ScaleComponent userTest={userTests[userTestIndex]} />;
      case MyProgressAssessmentStep.Report:
        return (
          <ReportComponent
            satisfaction={
              userTests.reduce((acc: number, obj: UserTest) => {
                return acc + obj.aimMark;
              }, 0) / userTests.length
            }
            performance={
              userTests.reduce((acc: number, obj: UserTest) => {
                return acc + obj.currentMark;
              }, 0) / userTests.length
            }
          />
        );
    }
  }

  return (
    <div className="fixed z-50 w-screen inset-0">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("goals.survey.exit.title")}
                    </Dialog.Title>
                    <p className="text-2xl">{t("goals.survey.exit.subtitle")}</p>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense ">
                      <VigoButton
                        appearance="secondary"
                        className="items-center justify-center w-full"
                        onClick={() => {
                          setOpen(false);
                          props.setTest(false);
                        }}
                      >
                        {t("goals.survey.exit.button.later")}
                      </VigoButton>
                      <VigoButton
                        appearance="primary"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {t("goals.survey.exit.button.continue")}
                      </VigoButton>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex flex-col h-screen justify-between border border-silver bg-white ">
        <div>
          <div className="h-10 p-8">
            <div className="flex justify-between items-center">
              <span />
              <p className="text-2xl font-normal text-center">
                {currentIndex === numberOfSteps - 1 ? t("goals.report.title") : t("goals.title")}
              </p>
              <img onClick={() => setOpen(true)} className="cursor-pointer" src={CloseIcon} alt="close" />
            </div>
          </div>
          <div className="w-full bg-sky h-2 mt-8">
            <div
              className="bg-active h-2"
              style={{
                width: `${((currentIndex + 1) / numberOfSteps) * 100}%`,
              }}
            />
          </div>
        </div>
        <div className="bg-white overflow-hidden ">
          <div
            className="overflow-auto px-32 "
            style={{
              maxHeight: "calc(100vh - 250px)",
            }}
          >
            <div className="py-8">
              {
                <>
                  <audio
                    src={audioSrc(audioKey(currentIndex))}
                    onPlay={() => {
                      setAudioImage(PauseIcon);
                    }}
                    onPause={() => {
                      setAudioImage(PlayIcon);
                    }}
                    onEnded={() => {
                      setAudioImage(ReplayIcon);
                    }}
                    autoPlay
                  />
                  {Component(currentIndex)}
                </>
              }
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-0 content-center bottom-0">
          <div
            style={{
              boxShadow: "0 -5px 5px -5px #b8b7b7",
            }}
            className="flex p-8 justify-between "
          >
            <div className="flex justify-between">
              <img
                width={80}
                className="cursor-pointer px-2"
                src={audioImage}
                alt="Play Icon"
                onClick={onAudioButton}
              />
            </div>
            <div>
              <VigoButton
                className="px-8"
                style={{
                  marginRight: "30px",
                }}
                onClick={goBack}
                appearance="secondary"
              >
                {t(currentIndex ? "general.button.back" : "goals.survey.button.cancel")}
              </VigoButton>
              <span />
              <VigoButton
                disabled={!buttonEnabled}
                className="px-16"
                onClick={goForward}
                appearance={buttonEnabled ? "primary" : "disabled"}
              >
                {t(currentIndex ? "general.button.continue" : "goals.survey.button.proceed")}
              </VigoButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProgressAssessment;
