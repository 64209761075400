const AnswerPhrase = (props: any) => {
  if (props.isOutgoing) {
    return (
      <button
        key={props.index}
        className="w-full text-2xl bg-active font-regular rounded-xl shadow-3xl py-3 px-4 text-center cursor-default"
      >
        <span className="text-white" style={{ fontSize: `${props.textSize}rem` }}>
          {props.answer.phrase}
        </span>
      </button>
    );
  } else {
    return (
      <button
        key={props.index}
        className="w-full text-2xl bg-active font-regular rounded-xl shadow-3xl py-3 px-4 text-center"
        onClick={() => {
          props.handleAnswer(props.answer);
          props.handleOutgoing(props.answer);
        }}
      >
        <span className="text-white" style={{ fontSize: `${props.textSize}rem` }}>
          {props.answer.phrase}
        </span>
      </button>
    );
  }
};

export default AnswerPhrase;
