import AbstractService from "../abstract/abstract.service";
import WordGame from "../../types/models/word-game";

class WordGameService extends AbstractService<WordGame> {
  API_SEGMENT = "word-games";

  async fetch(letters: string[], localeId: string) {
    const config = { headers: { 'Accept-Language': localeId } };
    const path = `/random?limit=10&letters=${letters.join('&letters=')}`
    const result = await this.axios.get<WordGame[]>(`${this.API_SEGMENT}${path}`, config);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }
}

export default new WordGameService();
