import { ExpandIcon } from "../../assets/icons";
import { useState } from "react";

const MessageImage = (props: any) => {
  const [expandImage, setExpandImage] = useState(false);

  return (
    <>
      {props.message.isExpandable ? (
        <>
          {expandImage && (
            <div
              onClick={() => setExpandImage(!expandImage)}
              className="fixed top-0 left-0 w-full h-full bg-dark bg-opacity-50 z-10"
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <img src={props.message.image} alt="video" />
              </div>
            </div>
          )}
          {!expandImage && (
            <button
              key={props.index}
              type="button"
              onClick={() => setExpandImage(!expandImage)}
              className="relative block  w-full rounded-t-2xl overflow-hidden"
            >
              <div className="home-img-gradient">
                <img
                  src={props.message.image}
                  alt="imageas"
                  className="w-full max-h-80  object-cover cursor-pointer "
                />
              </div>
              <div className="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
                <img src={ExpandIcon} alt="Expand" />
              </div>
            </button>
          )}
        </>
      ) : (
        <>
          <button
            key={props.index}
            type="button"
            className="relative block w-full rounded-t-2xl overflow-hidden cursor-default"
          >
            <div className="home-img-gradient">
              <img src={props.message.image} alt="imageas" className="w-full max-h-80  object-cover " />
            </div>
          </button>
        </>
      )}
    </>
  );
};

export default MessageImage;
