import {Expose} from "class-transformer";
import Module from "./module";

class Message {
    @Expose()
    text?: string;

    @Expose()
    image?: string;

    @Expose()
    isExpandable?: boolean;

    @Expose()
    isExercise?: boolean;

    @Expose()
    percentage?: number;

    @Expose()
    percentWatched?: number;

    @Expose()
    videoExerciseId?: number;

    @Expose()
    videoUrl?: string;

    @Expose()
    thumbnailUrl?: string;

    @Expose()
    speechGame?: string[];
}

export function fillLackingValuesIfNeeded(message: Message, module: Module): Message {
    if (message.text && Array.isArray(message.text)) {
        message.text = message.text[Math.floor(Math.random() * message.text.length)];
    }
    if (message.image && Array.isArray(message.image)) {
        message.image = message.image[Math.floor(Math.random() * message.image.length)];
    }
    if ((message.videoExerciseId || message.videoUrl) && !message.thumbnailUrl) {
        message.thumbnailUrl = module.localizedOrDefaultThumbnail;
    }
    if (message.speechGame && message.speechGame.length && !message.thumbnailUrl) {
        message.thumbnailUrl = module.localizedThumbnail ?? Module.DefaultSpeechGameThumbnail;
    }
    return message;
}

export default Message;
