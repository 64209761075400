import { Field } from "formik";

const VigoRadioButton = (props: { name: string; onChange: any; value: string; label: string; checked: boolean }) => {
  return (
    <label className="mr-2 flex items-center">
      <Field
        type="radio"
        style={{
          MozAppearance: "none",
          WebkitAppearance: "none",
          appearance: "none",
        }}
        checked={props.checked}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        className="focus:ring-tone h-6 w-6 bg-tone text-active border-gray"
      />
      <span className="ml-3 text-lg  text-dark">{props.label}</span>
    </label>
  );
};

export default VigoRadioButton;
