import {useQuery} from "react-query";
import SubscriptionService from "../../services/subscription/subscription.service";

const useSubscription = () => {
    return useQuery(
        ['subscription', 'latest'],
        () => SubscriptionService.getLatestSubscription(),
        {staleTime: 30000}
    );
};

export default useSubscription;
