import classNames from "classnames";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";

class VigoInputProps {
  showLabel?: boolean;
  labelText?: string;
}

type VigoInputPropsType = VigoInputProps & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

function VigoInput(props: VigoInputPropsType) {
  const { showLabel, labelText, name, disabled, required } = props;

  const inputProps = { ...props };
  delete inputProps.showLabel;
  delete inputProps.labelText;

  return (
    <>
      {showLabel && (
        <label htmlFor={name} className="block font-bold text-sm text-dark">
          {labelText}
        </label>
      )}
      <input
        {...inputProps}
        required={required}
        id={name}
        disabled={disabled}
        className={classNames(
          "mt-1 focus:ring-sky focus:border-silver block w-full font-regular shadow-sm sm:text-lg border-gray rounded-sm py-2",
          disabled ? "text-gray" : "",
          required ? "border-red" : "",
        )}
      />
    </>
  );
}

export default VigoInput;
