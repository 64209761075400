import Module from "./module";
import {Expose, Type} from "class-transformer";
import DailyPlanModuleStatus from "../enums/daily-plan-module-status";

class DailyPlanModule {
  @Expose()
  id: number;

  @Expose()
  @Type(() => Module)
  module: Module;

  @Expose()
  status: DailyPlanModuleStatus;

  @Expose()
  isFavorite: boolean;

  get isCompleted(): boolean {
    return this.status && this.status === 2;
  }

  canChangeStatusTo(newStatus: DailyPlanModuleStatus): boolean {
    switch (newStatus) {
      case DailyPlanModuleStatus.InProgress:
        return this.status === DailyPlanModuleStatus.NotStarted;
      case DailyPlanModuleStatus.Completed:
        return this.status !== DailyPlanModuleStatus.Completed;
      case DailyPlanModuleStatus.Exited:
        return this.status < DailyPlanModuleStatus.Completed;
      default:
        return false;
    }
  }
}

export default DailyPlanModule;
