import React, {ReactNode} from "react";
import {QueryClient, QueryClientProvider} from "react-query";


function QueryProvider(props: { children: ReactNode }) {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            {props.children}
        </QueryClientProvider>
    );
}

export default QueryProvider;
