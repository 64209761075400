import DailyPlanModule from "./daily-plan-module";
import {Expose, Type} from "class-transformer";
import DailyPlanModuleStatus from "../enums/daily-plan-module-status";

class DailyPlan {
  @Expose()
  id: number;

  @Expose()
  date: Date;

  @Expose()
  @Type(() => DailyPlanModule)
  userDailyPlanModules: DailyPlanModule[];

  @Expose()
  timeInApp: number;

  @Expose()
  timeInExercises: number;

  get isStarted(): boolean {
    const started = this.userDailyPlanModules.find(x => x.status > DailyPlanModuleStatus.NotStarted);
    return !!started;
  }

  nextDailyPlanModule(currentId?: number): DailyPlanModule {
    if (currentId) {
      const dailyPlanModuleIndex = this.userDailyPlanModules.findIndex(x => x.id === currentId);
      const dailyPlanModule = this.userDailyPlanModules[dailyPlanModuleIndex];
      const nextDailyPlanModuleIndex = dailyPlanModuleIndex + 1;
      if (dailyPlanModule.module.showInDailyPlan) {
        const after = this.userDailyPlanModules.slice(nextDailyPlanModuleIndex, this.userDailyPlanModules.length);
        const before = this.userDailyPlanModules.slice(0, dailyPlanModuleIndex);
        const all = after.concat(before);
        const first = all.find(x => x.module.showInDailyPlan && x.status !== DailyPlanModuleStatus.Completed);
        return first
            ? first
            : after.find(x => x.status !== DailyPlanModuleStatus.Completed);
      } else if (nextDailyPlanModuleIndex < this.userDailyPlanModules.length) {
        return this.userDailyPlanModules[nextDailyPlanModuleIndex];
      }
      return null;
    } else {
      return this.userDailyPlanModules.find(x => x.module.showInDailyPlan && x.status !== DailyPlanModuleStatus.Completed);
    }
  }
}

export default DailyPlan;
