import {Fragment, FunctionComponent, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {Link} from "react-router-dom";
import {
  DailyActiveIcon,
  DailyIcon,
  HomeActiveIcon,
  HomeIcon,
  Logo,
  MenuIcon,
  ProfileActiveIcon,
  ProfileIcon,
  ProgressActiveIcon,
  ProgressIcon,
  XIcon,
} from "../../assets/icons";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import useAuth from "../../hooks/useAuth/useAuth";
import UserState from "../../types/enums/user-state";

export const Layout: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const currentURL = window.location.pathname;

  const navigation = [
    { name: t("home.menu.button.home"), href: "/home", icon: HomeIcon, activeIcon: HomeActiveIcon },
    { name: t("home.menu.button.plan"), href: "/daily-plan", icon: DailyIcon, activeIcon: DailyActiveIcon },
    // { name: t("home.menu.button.advice"), href: "/advice", icon: AdviceIcon, activeIcon: AdviceActiveIcon },
    { name: t("home.menu.button.progress"), href: "/progress", icon: ProgressIcon, activeIcon: ProgressActiveIcon },
    { name: t("home.menu.button.profile"), href: "/profile", icon: ProfileIcon, activeIcon: ProfileActiveIcon },
  ];
  return (
    <>
      <div className="font-formular">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <img src={XIcon} className="h-6 w-6 text-white" aria-hidden="true" alt="Menu Icon" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="h-12 w-auto" src={Logo} alt="Logo Mobile" />
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {user?.state === UserState.Active &&
                      navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            currentURL === item.href
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                          )}
                        >
                          {/* <item.icon
                          className={classNames(
                            item.current
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        /> */}
                          <img
                            src={item.icon}
                            className={classNames(
                              currentURL === item.href ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6",
                            )}
                            aria-hidden="true"
                            alt="Mobile Menu Logo"
                          />
                          {item.name}
                        </Link>
                      ))}
                    {!(user?.state === UserState.Active) && (
                      <Link
                        to="/profile"
                        className={classNames(
                          currentURL === "/profile"
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                        )}
                      >
                        <img
                          src={ProfileIcon}
                          className={classNames(
                            currentURL === "/profile" ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6",
                          )}
                          aria-hidden="true"
                          alt="Mobile Menu Logo"
                        />
                        {t("home.menu.button.profile")}
                      </Link>
                    )}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col -mr-1 min-h-0 shadow bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex justify-center items-center h-screen ">
                <nav className="mt-5 flex-1 px-4 bg-white space-y-1 ">
                  {user?.state === UserState.Active &&
                    navigation.map((item) => (
                      <div
                        key={item.name}
                        className={classNames(
                          currentURL === item.href ? "bg-skyactive" : "hover:bg-skyactive ",
                          "rounded-md",
                        )}
                      >
                        <Link to={item.href} className="px-2 py-2 font-bold rounded-md">
                          <div className="mx-auto flex items-center justify-center h-10 w-10">
                            {currentURL === item.href ? (
                              <img src={item.activeIcon} aria-hidden="true" alt="Menu Logo" />
                            ) : (
                              <img src={item.icon} aria-hidden="true" alt="Menu Logo" />
                            )}
                          </div>

                          <div className="text-center ">
                            <p
                              className={classNames(
                                currentURL === item.href ? "text-active" : "hover:bg-skyactive",
                                "mt-1 items-center text-md font-bold rounded-md",
                              )}
                            >
                              {item.name}
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  {!(user?.state === UserState.Active) && (
                    <div
                      className={classNames(
                        currentURL === "/profile" ? "bg-skyactive" : "hover:bg-skyactive ",
                        "rounded-md",
                      )}
                    >
                      <Link to="/profile" className="px-2 py-2 font-bold rounded-md">
                        <div className="mx-auto flex items-center justify-center h-10 w-10">
                          {currentURL === "/profile" ? (
                            <img src={ProfileActiveIcon} aria-hidden="true" alt="Menu Logo" />
                          ) : (
                            <img src={ProfileIcon} aria-hidden="true" alt="Menu Logo" />
                          )}
                        </div>

                        <div className="text-center ">
                          <p
                            className={classNames(
                              currentURL === "/profile" ? "text-active" : "hover:bg-skyactive",
                              "mt-1 items-center text-md font-bold rounded-md",
                            )}
                          >
                            {t("home.menu.button.profile")}
                          </p>
                        </div>
                      </Link>
                    </div>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img src={MenuIcon} alt="Menu Icon" className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 bg-tone font-formular">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
