import VigoButton from "../vigo-button/vigo-button.component";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { MouseEventHandler } from "react";

function ProfileLogout(props: { logout: MouseEventHandler<HTMLButtonElement> }) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <>
      <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
        <div className="flex justify-between">
          <p className="mt-3 text-2xl">{t("profile.logout.text")}</p>
          <VigoButton onClick={() => setOpen(true)} appearance="secondary">
            {t("profile.logout.button")}
          </VigoButton>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("logout.screen.title")}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <VigoButton onClick={props.logout} appearance="" className="bg-disabled text-active">
                    {t("logout.screen.button.logout")}
                  </VigoButton>
                  <VigoButton onClick={() => setOpen(false)} appearance="" className="bg-active text-white">
                    {t("logout.screen.button.back")}
                  </VigoButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ProfileLogout;
