import { useQuery } from "react-query";
import DailyActivityService from "../../services/daily-activity/daily-activity.service";

const useDailyActivity = (userId: number) => {
  return useQuery(["dailyActivity", userId], () => DailyActivityService.getDailyActivitiesByUserId(userId), {
    staleTime: 5000,
  });
};

export default useDailyActivity;
