import { Fragment, useRef } from "react";
import { CloseWhiteIcon } from "../../assets/icons";
import { Dialog, Transition } from "@headlessui/react";

const VideoContainer = (props: any) => {

  function calcPercentWatched(): number {
    const video = document.getElementsByTagName("video")[0];
    let totalPlayed = 0;
    for (let i = 0; i < video.played.length; i++) {
      totalPlayed += video.played.end(i) - video.played.start(i);
    }
    return video.duration ? totalPlayed / video.duration * 100 : 0;
  }

  const close = () => {
    props.message.percentWatched = calcPercentWatched()
    props.setOpen(false);
  };

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <video
              onEnded={close}
              src={props.message.videoUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              controls
              autoPlay
          />
            <button
                className="absolute rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
                onClick={close}
            >
                <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
            </button>
        </div>
      </>
  );
};

const Video = (props: any) => {
  const cancelButtonRef = useRef(null);
  return (
    <div>
      <div className="">
        <Transition.Root show={props.open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={() => props.setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
              </Transition.Child>
              {/* trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white text-left overflow-hidden transform transition-all h-screen w-full">
                  <VideoContainer message={props.message} setOpen={props.setOpen} />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default Video;
