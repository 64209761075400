import { ErrorMessage, Field, Form, Formik } from "formik";
import { eyeClose, eyeOpen } from "../../assets/icons";
import * as Yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function LoginForm(props: { onLogin: any }) {
  const [initialValues] = useState({
    login: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    login: Yup.string().required(t("auth.screen.error")),
    password: Yup.string().required(t("auth.screen.error")),
  });

  const handleSubmit = async (formValue: { login: string; password: string }) => {
    try {
      setLoading(true);
      setMessage("");
      await props.onLogin(formValue.login, formValue.password);
    } catch (e: any) {
      setLoading(false);
      setMessage(t("general.error.message.invalid.data"));
    }
  };

  const toggleShowDisplay = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isValid, dirty }) => (
        <Form className="space-y-6 font-formular">
          <div className="form-group">
            <label htmlFor="login" className="block text-sm font-regular text-dark">
              {t("auth.screen.username.placeholder")}
            </label>
            <div className="mt-1">
              <Field
                id="user-text-field"
                name="login"
                autoComplete="username"
                type="text"
                className="appearance-none block w-full px-3 py-3 border border-gray rounded-sm shadow-sm placeholder-gray focus:outline-none focus:ring-active focus:border-active sm:text-sm form-control"
              />
            </div>
          </div>
          <ErrorMessage name="login" component="p" className="-mt-2 text-sm text-red" />
          <div className="relative rounded-md shadow-sm">
            <div className="form-group">
              <label htmlFor="password" className="block text-sm font-regular text-dark">
                {t("auth.screen.password.placeholder")}
              </label>
              <Field
                id="password-text-field"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                className="mt-1 appearance-none block w-full px-3 py-3 border border-gray rounded-sm shadow-sm placeholder-gray focus:outline-none focus:ring-active focus:border-active sm:text-sm form-control"
              />
              <div className="absolute inset-y-0 mt-7 right-0 pr-4 flex items-center pointer z-10 cursor-pointer">
                {dirty ? (
                  <img
                    className="w-6"
                    onClick={toggleShowDisplay}
                    src={showPassword ? eyeOpen : eyeClose}
                    alt="Vigo Health Logo"
                    aria-hidden="true"
                  />
                ) : (
                  <img className="w-6 cursor-not-allowed" src={eyeClose} alt="Vigo Health Logo" aria-hidden="true" />
                )}
              </div>
            </div>
          </div>
          <ErrorMessage name="password" component="p" className="text-sm text-red" />
          <div className="form-group">
            <button
              type="submit"
              className={classNames(
                "w-full flex justify-center py-3 px-4 border border-transparent rounded-sm shadow-sm text-sm font-bold text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-active",
                {
                  "cursor-not-allowed bg-sky": !dirty || !isValid || loading,
                  "bg-active": dirty && isValid && !loading,
                },
              )}
              disabled={!isValid || loading}
            >
              {loading ? (
                <div
                  style={{ borderTopColor: "transparent" }}
                  className="w-4 h-4 border-2 border-active border-solid rounded-full animate-spin"
                />
              ) : (
                <>{t("auth.screen.button.login")}</>
              )}
            </button>
          </div>

          {message && <p className="text-red text-sm">{message}</p>}
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
