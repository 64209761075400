import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { lv } from "date-fns/locale";
import Bar from "../../../../components/blood-pressure-chart/blood-pressure-chart.component";
import { RightArrowIcon, LeftArrowIcon } from "../../../../assets/icons";
import VigoButton from "../../../../components/vigo-button/vigo-button.component";
import BloodPressureForm from "../../../../components/blood-pressure-form/blood-pressure-form.component";
import BloodPressureService from "../../../../services/blood-pressure/blood-pressure.service";
import useAuth from "../../../../hooks/useAuth/useAuth";
import useBloodPressure from "../../../../hooks/useBloodPressure/useBloodPressure";
import { EllipseIcon, RectangleIcon } from "../../../../assets/icons";
import { getDateRangeForChartBarData, transformToDailyChartBarData } from "../../../../types/classes/chart-bar-model";
import { ErrorModal, SuccessModal } from "../../../../components/vigo-modals";

const BloodPressure = () => {
  const { user } = useAuth();
  const bloodPressure = useBloodPressure(user.id);
  const { t, i18n } = useTranslation();
  const [dataIndex, setDataIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [bloodPressureData, setBloodPressureData] = useState(transformToDailyChartBarData([]));

  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (bloodPressure.error) {
      setErrorMessage(`${bloodPressure.error}`);
      setError(true);
    }
    const weekData = transformToDailyChartBarData(bloodPressure.data ?? []);
    setDataIndex(weekData.length - 7);
    setBloodPressureData(weekData);
  }, [bloodPressure.data, bloodPressure.error]);

  const handleLeftArrowClick = () => {
    if (dataIndex === 0) return;
    setDataIndex(dataIndex - 7);
  };
  const handleRightArrowClick = () => {
    if (dataIndex + 7 > bloodPressureData.length - 1) return;
    setDataIndex(dataIndex + 7);
  };

  const handleSubmit = async (item: any) => {
    setOpen(false);

    await BloodPressureService.createBloodPressure(item, user.id)
      .then(function (response) {
        setSuccessMessage(t("message.success.data_saved"));
        setSuccess(true);
      })
      .catch(function (error) {
        setErrorMessage(`${error}`);
        setError(true);
      });

    await bloodPressure.refetch();
  };

  return (
    <>
      <ErrorModal setModal={setError} modal={error} text={errorMessage} />
      <SuccessModal setModal={setSuccess} modal={success} text={successMessage} />
      <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl"> {t("blood.pressure.screen.add.text")}</h2>
          <VigoButton appearance="primary" onClick={() => setOpen(true)}>
            {t("general.button.add")}
          </VigoButton>
        </div>
      </div>
      {/* Form modal */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("blood.pressure.screen.add.text")}
                    </Dialog.Title>
                  </div>
                  <BloodPressureForm onSubmit={handleSubmit} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
        <div className="pr-0">
          <div className="flex justify-between xl:px-16">
            <div className="grid justify-items-start">
              <h2 className="leading-2 font-bold text-2xl pb-4">
                {BloodPressureService.getMeasurementRangeString(bloodPressureData.slice(dataIndex, dataIndex + 7))}
              </h2>
              <div className="flex justify-center ">
                <button onClick={handleLeftArrowClick}>
                  <img src={LeftArrowIcon} alt="left icon" />
                </button>
                <p className="text-center w-44">
                  {getDateRangeForChartBarData(
                    bloodPressureData.slice(dataIndex, dataIndex + 7),
                    i18n.resolvedLanguage === "lv" ? lv : null,
                    i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM",
                  )}
                </p>

                <button onClick={handleRightArrowClick}>
                  <img src={RightArrowIcon} alt="right icon" />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 content-start xl:pr-12">
              <div className="flex justify-start items-center">
                <img className="w-8 h-8" src={EllipseIcon} alt="ellipse icon" />
                <span className="text-lg px-2">{t("general.text.systolic")}</span>
              </div>
              <div className="flex justify-start items-center">
                <img className="w-8 h-8" src={RectangleIcon} alt="rectangle icon" />
                <span className="text-lg px-2">{t("general.text.diastolic")}</span>
              </div>
            </div>
          </div>

          <div className="max-w-full mt-6 mx-auto xl:pl-16">
            <div className="bg-white overflow-hidden">
              <div className="grid grid-cols-8 gap-0">
                {bloodPressureData.slice(dataIndex, dataIndex + 7).map((day, index) => {
                  return <Bar key={index} day={day} />;
                })}

                <div className="bg-white overflow-hidden border-l border-silver" style={{ height: "440px" }}>
                  <div className="ml-1 z-20 absolute" style={{ top: "435px" }}>
                    200
                  </div>
                  <div className="ml-1 z-20 absolute" style={{ top: "535px" }}>
                    150
                  </div>
                  <div className="ml-1 z-20 absolute" style={{ top: "635px" }}>
                    100
                  </div>
                  <div className="ml-1 z-20 absolute" style={{ top: "735px" }}>
                    50
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default BloodPressure;
