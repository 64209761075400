import { CardIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import Subscription, { SubscriptionState } from "../../types/models/subscription";
import { PaymentMethod as PaymentMethodModel } from "../../types/models/subscription";
import { capitalize } from "lodash";
import VigoButton from "../vigo-button/vigo-button.component";

class PaymentMethodProps {
  subscription: Subscription;
  paymentMethod: PaymentMethodModel;
  onPortalOpen: () => void;
}

function PaymentMethod(props: PaymentMethodProps) {
  const { subscription, paymentMethod, onPortalOpen } = props;
  const { t } = useTranslation();
  const isCurrent = subscription.state === SubscriptionState.Paid || subscription.state === SubscriptionState.Active;

  return (
    isCurrent && (
      <div className=" mt-5 p-12 border border-silver rounded-lg">
        <h2 className="py-2 text-4xl font-bold text-dark">{t("general.subscription-plan.payment.title")}</h2>
        <div className="flex justify-between">
          <div className="py-4">
            <div className="flex">
              <img src={CardIcon} alt="Card Icon" className="w-14 h-14" />
              <p className="ml-4 ">
                <span className="text-lg font-bold">{capitalize(paymentMethod.brand)}</span>
                <br />
                <span className="text-dark text-xl">** {paymentMethod.last4}</span>
              </p>
            </div>
          </div>
          <div className="bg-white text-right">
            <VigoButton onClick={onPortalOpen} appearance="secondary">
              {t("general.subscription-plan.payment.change")}
            </VigoButton>
          </div>
        </div>
      </div>
    )
  );
}

export default PaymentMethod;
