const WelcomeComponent = (props: { welcomeText: string }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-sky  flex flex-col items-center justify-center">
      <div className="grid place-items-center h-screen">
        <p className="animate-pulse text-5xl">{props.welcomeText}</p>
      </div>
    </div>
  );
};

export default WelcomeComponent;
