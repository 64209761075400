import { useQuery } from "react-query";
import DailyPlanService from "../../services/daily-plan/daily-plan.service";

const useCompletionRate = (userId: number) => {
  return useQuery(["completionRate", userId], () => DailyPlanService.fetchCompletionRate(userId), {
    staleTime: 30000,
  });
};

export default useCompletionRate;
