import { useState, useEffect } from "react";
import MoodBar from "../../../../components/mood-bar/mood-bar.component";
import { lv } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { RightArrowIcon, LeftArrowIcon } from "../../../../assets/icons";
import useAuth from "../../../../hooks/useAuth/useAuth";
import useMood from "../../../../hooks/useMood/useMood";
import {getDateRangeForChartBarData, transformToDailyChartBarData} from "../../../../types/classes/chart-bar-model";

const MyMood = () => {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const mood = useMood(user.id);
  const [moodData, setMoodData] = useState(transformToDailyChartBarData([]));
  const [dataIndex, setDataIndex] = useState(0);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (mood.error) {
      setErrorMessage(`${mood.error}`);
      setError(true);
    }
    const weekData = transformToDailyChartBarData(mood.data ?? [])
    setDataIndex(weekData.length - 7);
    setMoodData(weekData);
  }, [mood.data, mood.error]);

  const handleLeftArrowClick = () => {
    if (dataIndex === 0) return;
    setDataIndex(dataIndex - 7);
  };
  const handleRightArrowClick = () => {
    if (dataIndex + 7 > moodData.length - 1) return;
    setDataIndex(dataIndex + 7);
  };

  return (
    <div className="py-8 h-full">
      <div className="flex justify-center py-4">
        <button onClick={handleLeftArrowClick}>
          <img src={LeftArrowIcon} alt="left icon" />
        </button>
        <p className="text-center w-44">
          {getDateRangeForChartBarData(
            moodData.slice(dataIndex, dataIndex + 7),
            i18n.resolvedLanguage === "lv" ? lv : null,
            i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM",
          )}
        </p>
        <button onClick={handleRightArrowClick}>
          <img src={RightArrowIcon} alt="right icon" />
        </button>
      </div>
      <ul className="mt-3 place-items-center grid grid-cols-1 gap-4 sm:gap-12 sm:grid-cols-2 lg:grid-cols-7">
        {moodData.slice(dataIndex, dataIndex + 7).map((day, index) => {
          return <MoodBar key={index} day={day} />;
        })}
      </ul>
    </div>
  );
};

export default MyMood;
