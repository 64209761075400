import BloodPressure from "../../types/models/blood-pressure";
import AbstractService from "../abstract/abstract.service";
import ChartBarData from "../../types/classes/chart-bar-model";

class BloodPressureService extends AbstractService<BloodPressure> {
  API_SEGMENT = "user-blood-pressure";

  async getBloodPressureByUserId(userId: number) {
    const path = `?join=user%7C%7Cid&filter=user.id%7C%7C%24eq%7C%7C${userId}`
    const result = await this.axios.get<BloodPressure[]>(`${this.API_SEGMENT}${path}`);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async createBloodPressure(this: any, data: BloodPressure, userId: number) {
    const params = {
      user: userId,
      createdAt: data.createdAt,
      systolic: data.systolic,
      diastolic: data.diastolic,
    };
    return await this.axios.post(this.API_SEGMENT, params);
  }

  getMeasurementRangeString(weekData: ChartBarData<BloodPressure>[]): string {
    const allModels = weekData
        .map(day => day.models ? day.models : [])
        .flat(1);

    if (allModels && allModels.length) {
      const minSys = Math.min(...allModels.map(bp => bp.systolic));
      const maxSys = Math.max(...allModels.map(bp => bp.systolic));
      const minDia = Math.min(...allModels.map(bp => bp.diastolic));
      const maxDia = Math.max(...allModels.map(bp => bp.diastolic));
      const chunks = [[minSys, maxSys], [minDia, maxDia]].map(p => (p[0] === p[1]) ? p[0] : `${p[0]}-${p[1]}`)

      return `${chunks[0]}/${chunks[1]} mmHg`;
    } else {

      return '0/0 mmHg';
    }
  }
}

export default new BloodPressureService();
