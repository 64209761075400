import { Fragment, useEffect, useRef, useState } from "react";
import { CloseWhiteIcon } from "../../assets/icons";
import useAuth from "../../hooks/useAuth/useAuth";
import useVideoExercise from "../../hooks/useVideoExercise/useVideoExercise";
import VideoExercise from "../../types/models/video-exercise";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import {ErrorModal} from "../vigo-modals";

enum VideoLocalizationScene {
  Title,
  Intro,
  Stats,
  ExerciseTitle,
  ExerciseDemo,
  ExerciseStart,
  Exercise,
  Final,
}

const TitleScreen = (props: any) => {
  const title = props.videoExercise.localizations[0].title;

  useEffect(() => {
    setTimeout(() => {
      if (props.videoExercise.introVideoUrl) {
        props.setScene(VideoLocalizationScene.Intro);
      } else {
        props.setScene(VideoLocalizationScene.ExerciseTitle);
      }
    }, 5000);
  }, []);

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <button
                className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
                onClick={() => props.setOpen(false)}
            >
              <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
            </button>
            <div className="inline-block justify-center self-center">
              <div className="slide-down bg-active py-8 px-8 rounded-2xl">
                <p className="text-white font-bold text-6xl ">{title}</p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const IntroVideo = (props: any) => {
  const videoUrl = props.videoExercise.introVideoUrl;

  const onVideoEnded = () => {
    props.setScene(VideoLocalizationScene.ExerciseTitle);
  };

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <button
              className="absolute rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
              onClick={() => props.setOpen(false)}
          >
            <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
          </button>
          <video
              onEnded={onVideoEnded}
              src={videoUrl}
              style={{
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              autoPlay
          />
        </div>
      </>
  );
};

const MinuteScreen = (props: any) => {
  const { t } = useTranslation();
  const minutes = 1;
  const exercises = 2;

  useEffect(() => {
    setTimeout(() => {
      props.setScene(VideoLocalizationScene.ExerciseTitle);
    }, 5000);
  }, []);

  function minutesString(): string {
    return minutes > 1 ? t("general.text.minutes") : t("general.text.minute");
  }

  function exercisesString(): string {
    return exercises > 1 ? t("video.stats.label.exercises") : t("video.stats.label.exercise");
  }

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="flex ">
              <div className="bg-active w-full h-screen grid place-items-center">
                <button
                    className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
                    onClick={() => props.setOpen(false)}
                >
                  <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
                </button>
                <div className="slide-down">
                  <span className="text-white font-bold text-9xl">{minutes}</span>
                  <p className="text-white font-bold text-4xl mt-4">{minutesString()}</p>
                </div>
              </div>
              <div className="bg-white w-full h-screen grid place-items-center">
                <div className="slide-down">
                  <span className="text-active font-bold text-9xl">{exercises}</span>
                  <p className="text-active font-bold text-4xl mt-4">{exercisesString()} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const ExerciseScreen = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      props.setScene(VideoLocalizationScene.ExerciseDemo);
    }, 5000);
  }, []);

  function screenTitle(): string {
    return t("video.message.exercise", { index: props.exerciseIndex + 1 });
  }

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-active text-left overflow-hidden transform transition-all h-screen w-full">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <button
                className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
                onClick={() => props.setOpen(false)}
            >
              <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
            </button>
            <div className="inline-block justify-center self-center">
              <div className="slide-down bg-white py-8 px-8 rounded-2xl">
                <p className="text-active font-bold text-6xl ">{screenTitle()}</p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const DemoVideo = (props: any) => {
  const { t } = useTranslation();
  const videoUrl = props.videoExercise.videoExerciseVideoExerciseItems[props.exerciseIndex]
      .videoExerciseItem.demoVideoUrl;
  const audioUrl = props.videoExercise.videoExerciseVideoExerciseItems[props.exerciseIndex]
      .videoExerciseItem.localizations[0].audioUrl;

  const onVideoEnded = () => {
    props.setScene(VideoLocalizationScene.ExerciseStart);
  };

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <div className="absolute slide-down bottom-20 right-20 bg-white text-active font-bold py-4 px-4 text-4xl rounded-lg">
            {t("video.label.demonstration")}
          </div>
          <button
              className="absolute rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
              onClick={() => props.setOpen(false)}
          >
            <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
          </button>


          <video
              onEnded={onVideoEnded}
              style={{
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              autoPlay
          >
            <source src={videoUrl} type="video/mp4" />
            <audio src={audioUrl} autoPlay />

          </video>
        </div>
      </>
  );
};

const DoItTogetherScreen = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      props.setScene(VideoLocalizationScene.Exercise);
    }, 5000);
  }, []);

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <button
                className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
                onClick={() => props.setOpen(false)}
            >
              <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
            </button>
            <div className="inline-block justify-center self-center">
              <div className="bg-white py-8 px-8 rounded-2xl slide-down">
                <p className="text-active font-bold text-6xl ">{t("video.message.do_together")}</p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const MainVideo = (props: any) => {
  const isLastExercise = props.exerciseIndex === (props.videoExercise.videoExerciseVideoExerciseItems.length - 1);
  const audioUrl = "https://vigoaudios.s3.eu-central-1.amazonaws.com/video_exercise_music.mp3";
  const videoUrl = props.videoExercise.videoExerciseVideoExerciseItems[props.exerciseIndex]
      .videoExerciseItem.rawExerciseVideoUrl;

  function calcPercentWatched(): number {
    const video = document.getElementsByTagName("video")[0];
    let totalPlayed = 0;
    for (let i = 0; i < video.played.length; i++) {
      totalPlayed += video.played.end(i) - video.played.start(i);
    }
    return video.duration ? totalPlayed / video.duration * 100 : 0;
  }

  const close = () => {
    if (isLastExercise) {
      props.message.percentWatched = calcPercentWatched();
    }
    props.setOpen(false);
  };

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const onTimeUpdate = (event: any) => {
    if (event.currentTarget.currentTime) {
      setCurrentTime(event.currentTarget.currentTime);
    }
  };

  const onLoadedMetadata = (event: any) => {
    if (event.currentTarget.duration) {
      setDuration(event.currentTarget.duration);
    }
  };

  const onVideoEnded = () => {
    if (props.exerciseIndex < props.videoExercise.videoExerciseVideoExerciseItems.length - 1) {
      props.setExerciseIndex(props.exerciseIndex + 1);
      props.setScene(VideoLocalizationScene.ExerciseTitle);
    } else {
      props.setScene(VideoLocalizationScene.Final);
    }
  };

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <button
              className="absolute rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
              onClick={close}
          >
            <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
          </button>
          <audio src={audioUrl} loop autoPlay />
          <video
              onTimeUpdate={onTimeUpdate}
              onLoadedMetadata={onLoadedMetadata}
              onEnded={onVideoEnded}
              src={videoUrl}
              style={{
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              autoPlay
          />
          <div className="flex justify-center">
            <div className="absolute w-4/5 rounded-full bottom-10 h-5 bg-white">
              <div
                  className="absolute max-w-4/5 rounded-full bottom-0 h-5 bg-active"
                  style={{
                    width: `${duration ? (currentTime / duration) * 100 : 0}%`,
                  }}
              />
            </div>
          </div>
        </div>
      </>
  );
};

const YouDidItScreen = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      close();
    }, 5000);
  }, []);

  const close = () => {
    props.message.percentWatched = 100;
    props.setOpen(false);
  };

  return (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto bg-white text-left overflow-hidden transform transition-all h-screen w-full">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <button
                className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
                onClick={close}
            >
              <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
            </button>
            <div className="inline-block justify-center self-center">
              <div className="bg-white py-8 px-8 rounded-2xl slide-down">
                <p className="text-active font-bold text-6xl ">{t("video.message.you_did_it")}</p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

const VideoLocalizationContainer = (props: any) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const videoExerciseData = useVideoExercise(props.message.videoExerciseId, user.localeId);
  const [videoExercise, setVideoExercise] = useState<VideoExercise>(null);

  const [scene, setScene] = useState<VideoLocalizationScene>(VideoLocalizationScene.Title);
  const [exerciseIndex, setExerciseIndex] = useState(0);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (videoExerciseData.error) {
      setErrorMessage(`${videoExerciseData.error}`);
      setError(true)
    } else if (videoExerciseData.data && !videoExercise) {
      setVideoExercise(videoExerciseData.data);
    }
  }, [videoExerciseData.data, videoExerciseData.error]);

  return (
    <>
      <ErrorModal setModal={setError}
                  modal={error}
                  text={errorMessage} />

      <button
          className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
          onClick={() => props.setOpen(false)}
      >
        <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
      </button>
      {videoExercise && (
        <>
          {scene === VideoLocalizationScene.Title && (
            <TitleScreen videoExercise={videoExercise} setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.Intro && (
              <IntroVideo videoExercise={videoExercise} setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.Stats && (
              <MinuteScreen videoExercise={videoExercise} setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.ExerciseTitle && (
              <ExerciseScreen videoExercise={videoExercise} exerciseIndex={exerciseIndex}
                              setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.ExerciseDemo && (
              <DemoVideo videoExercise={videoExercise} exerciseIndex={exerciseIndex}
                         setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.ExerciseStart && (
              <DoItTogetherScreen videoExercise={videoExercise} exerciseIndex={exerciseIndex}
                                  setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.Exercise && (
              <MainVideo message={props.message} videoExercise={videoExercise} exerciseIndex={exerciseIndex} setExerciseIndex={setExerciseIndex}
                                  setOpen={props.setOpen} setScene={setScene} />
          )}
          {scene === VideoLocalizationScene.Final && (
              <YouDidItScreen message={props.message} setOpen={props.setOpen} />
          )}
        </>
      )}
    </>
  );
};

const VideoLocalization = (props: any) => {
  const cancelButtonRef = useRef(null);
  return (
    <div>
      <div className="">
        <Transition.Root show={props.open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={() => props.setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
              </Transition.Child>
              {/* trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white text-left overflow-hidden transform transition-all h-screen w-full">
                  <VideoLocalizationContainer message={props.message} setOpen={props.setOpen} />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default VideoLocalization;
