import { useTranslation } from "react-i18next";
import GoalActivity from "../../types/models/goal-activity";

const MyProgressGoals = (props: any) => {
  const { t } = useTranslation();
  return (
      <div className="flex mt-5 p-8 border border-silver bg-white rounded-lg">
          <div className="flex-col text-left">
              <h2 className="text-xl font-bold py-2">{t("goals.five.cell.title")}</h2>
              <ul className="list-decimal px-6">
                  {props.currentGoalActivities.map((goalActivity: GoalActivity, index: number) => {
                      return (
                          <li className="py-2 text-lg" key={index}>
                              {t(`goal.${goalActivity.key}`)}
                          </li>
                      );
                  })}
              </ul>
              <ul></ul>
          </div>
      </div>
  );
};

export default MyProgressGoals;
