import ModuleLocalization from "./module-localization";
import {Expose, Type} from "class-transformer";
import Intent from "./intent";

class Module {
  static DefaultThumbnail = "https://vigothumbnails.s3.eu-central-1.amazonaws.com/thumbnail.jpg";
  static DefaultSpeechGameThumbnail = "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png";

  @Expose()
  id: number;

  @Expose()
  @Type(() => ModuleLocalization)
  localizations: ModuleLocalization[];

  @Expose()
  internalTitle: string;

  @Expose()
  showInDailyPlan: boolean;

  get startIntent(): Intent {
    const intents = this.localizedIntents;
    if (intents) {
      return intents.find(x => x.isStartIntent);
    }
    return null;
  }

  intentById(intentId: string) {
    return this.localizedIntents?.find((x) => x.intentId === intentId);
  }

  get localizedIntents(): Intent[] {
    if (this.localizations && this.localizations.length) {
      return this.localizations[0].intents;
    }
    return null;
  }

  get localizedThumbnail(): string {
    if (this.localizations && this.localizations.length) {
      return this.localizations[0].thumbnail;
    }
    return null;
  }

  get localizedOrDefaultThumbnail(): string {
    return this.localizedThumbnail ?? Module.DefaultThumbnail;
  }

  get localizedTitle(): string {
    if (this.localizations && this.localizations.length) {
      return this.localizations[0].title;
    }
    return null;
  }
}

export default Module;
