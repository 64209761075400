import VideoExercise from "../../types/models/video-exercise";
import AbstractService from "../abstract/abstract.service";

class VideoExerciseService extends AbstractService<VideoExercise> {
  API_SEGMENT = "video-exercises";

  async getVideoExercise(id: number, localeId: string) {
    const config = { headers: { 'Accept-Language': localeId } };
    const result = await this.axios.get<VideoExercise>(`${this.API_SEGMENT}/${id}`, config);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }
}

export default new VideoExerciseService();
