import React from "react";
import User from "../../types/models/user";

export class AuthContextState {
    isLoggedIn: boolean;
    user: User | null;
    login: (login: string, password: string) => Promise<void>;
    logout: () => void;
    refreshState: () => Promise<void>;
}

const initialValue: AuthContextState = {
    isLoggedIn: false,
    user: null,
    login: async () => {},
    logout: () => {},
    refreshState: async () => {}
};

const AuthContext = React.createContext(initialValue);

export default AuthContext;
