import LoginResponse from "../../types/classes/login-response";
import AbstractService from "../abstract/abstract.service";

class AuthService extends AbstractService<any> {
    API_SEGMENT = "/auth";

    login(login: string, password: string) {
        return this.axios
            .post<LoginResponse>(`${this.API_SEGMENT}/login`, {
                login,
                password
            })
            .then(res => {
                return res.data;
            });
    }

    getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) return JSON.parse(userStr);

        return null;
    }
}

export default new AuthService();
