import CurrentPlan from "../../../../components/current-plan/current-plan.component";
import useSubscription from "../../../../hooks/useSubscription/useSubscription";
import PaymentMethod from "../../../../components/payment-method/payment-method.component";
import usePaymentCustomer from "../../../../hooks/usePaymentCustomer/usePaymentCustomer";
import UpcomingCharge from "../../../../components/upcoming-charge/upcoming-charge.component";
// import usePaymentsHistory from "../../../../hooks/usePaymentsHistory/usePaymentsHistory";
// import PaymentsHistory from "../../../../components/payments-history/payments-history.component";
import useStripePortal from "../../../../hooks/useStripePortal/usePaymentsHistory";
import useStripeCheckout from "../../../../hooks/useStripeCheckout/usePaymentsHistory";

function PaymentsTab() {
  const { data: subscription } = useSubscription();
  const { data: paymentCustomer } = usePaymentCustomer();
  // const { data: paymentsHistory } = usePaymentsHistory();
  const { data: portal } = useStripePortal(window.location.href);
  const { data: checkout } = useStripeCheckout(window.location.href);

  const openPortal = () => {
    window.location.href = portal.url;
  };

  const openCheckout = () => {
    window.location.href = checkout.url;
  };

  return (
    <div className="py-5">
      {subscription && (
        <CurrentPlan onCheckoutOpen={openCheckout} onPortalOpen={openPortal} subscription={subscription} />
      )}
      {paymentCustomer?.paymentMethod && (
        <PaymentMethod
          onPortalOpen={openPortal}
          subscription={subscription}
          paymentMethod={paymentCustomer.paymentMethod}
        />
      )}
      {subscription && <UpcomingCharge subscription={subscription} />}
      {/* {subscription && paymentsHistory && (
        <PaymentsHistory currentSubscription={subscription} payments={paymentsHistory} subscription={subscription} />
      )} */}
    </div>
  );
}

export default PaymentsTab;
