import moment from "moment";
import format from "date-fns/format";

interface ChartBarModel {
    createdAt: Date;
}

class ChartBarData<T extends ChartBarModel> {
    models: T[];
    date: Date;
}

export default ChartBarData;

export function transformToDailyChartBarData<T extends ChartBarModel>(data: T[]): ChartBarData<T>[] {
    const chartBarModels: ChartBarData<T>[] = [];

    const moments = [moment()].concat(
        data.map(obj => moment(obj.createdAt))
    );
    const minDate =  moment(moment.min(moments)).startOf('isoWeek');
    const maxDate = moment(moment.max(moments)).endOf('isoWeek');
    while (minDate <= maxDate) {
        const chartBarModel = new ChartBarData<T>();
        chartBarModel.date = minDate.toDate();
        chartBarModel.models = data.filter(obj => moment(obj.createdAt).isSame(minDate, 'day'));
        chartBarModels.push(chartBarModel);

        minDate.add(1, 'day');
    }

    return chartBarModels;
}

export function getDateRangeForChartBarData<T extends ChartBarModel>(data: ChartBarData<T>[],
                                                                     locale: Locale,
                                                                     dateFormat: string): string {
    if (data && data.length) {
        const minDate = format(data[0].date, dateFormat, { locale });
        const maxDate = format(data[data.length - 1].date, dateFormat, { locale });
        return `${minDate} - ${ maxDate }`;
    }
    return '';
}
