import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const VigoPhoneInput = (props: { onChange: any; value: any; name: string }) => {
  return (
    <div>
      {" "}
      <label htmlFor="phone" className="block font-bold text-sm text-dark">
        {props.name}
      </label>
      <PhoneInput
        country="us"
        placeholder="+1 (234) 657-8"
        buttonStyle={{
          backgroundColor: "transparent",
          border: "none",
          padding: "8px",
          paddingTop: "8px",
        }}
        inputClass="phone-input"
        inputStyle={{
          border: "1px solid #1C3E5354",
          width: "100%",
          paddingTop: "22px",
          paddingBottom: "22px",
          borderRadius: "2px",
          fontSize: "1.1rem",
          fontWeight: "regular",
          outline: "none",
        }}
        containerStyle={{
          width: "100%",
          marginTop: "5px",
        }}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};

export default VigoPhoneInput;
