const MessageText = (props: any) => {
  return (
    <p
      key={props.index}
      id="text"
      className="font-regular rounded-tl-2xl shadow-3xl py-4 px-4"
      // hide for 3 seconds
      style={{ fontSize: `${props.textSize}rem` }}
    >
      {props.message.text}
    </p>
  );
};

export default MessageText;
