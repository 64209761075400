import useAuth from "../../hooks/useAuth/useAuth";
import { useHistory } from "react-router-dom";
import LocalStorageService from "../../services/local-storage/local-storage.service";
import { useEffect, useState } from "react";
import LoadingComponent from "../../components/loading/loading.component";

function ProtectedRoute(props: { children: any }) {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn, refreshState } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn && LocalStorageService.accessToken) {
      setIsLoading(true);
      refreshState()
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false);
          history.push("/");
        });
    }
  }, [history, isLoggedIn, refreshState]);

  if (!isLoggedIn && !LocalStorageService.accessToken) {
    history.push("/");
  }

  // here could be a spinner
  if (isLoading) {
    return <LoadingComponent />;
  }

  return props.children;
}

export default ProtectedRoute;
