import { Formik } from "formik";
import * as Yup from "yup";
import DailyActivity from "../../types/models/daily-activity";

import VigoInput from "../vigo-input/vigo-input.component";
import { useTranslation } from "react-i18next";
import DailyActivityType from "../../types/enums/daily-activity-type";
import VigoButton from "../vigo-button/vigo-button.component";
import VigoSelect, { VigoSelectOption } from "../vigo-select/vigo-select.component";
import VigoDatePicker from "../vigo-datepicker/vigo-datepicker.component";

class ActivityFormProps {
  initialValues?: DailyActivity;
  onSubmit: (values: DailyActivity) => void;
}

const Schema = Yup.object().shape({
  type: Yup.string(),
  value: Yup.number().max(150).min(1).required(),
  createdAt: Yup.date().required(),
});

const handleOnInput = (e: any) => {
  if (e.target.value.length > 3) {
    e.target.value = e.target.value.slice(0, 3);
  }
};

function ActivityForm(props: ActivityFormProps) {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation();

  const activityTypeOptions: VigoSelectOption[] = Object.values(DailyActivityType).map((at) => ({
    label: t(`activity.input.activity.${at}`),
    value: at,
  }));

  return (
    <div className="px-0 py-5 text-dark bg-white ">
      <Formik
        initialValues={{
          type: initialValues?.type,
          value: initialValues?.value,
          createdAt: initialValues?.createdAt,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          onSubmit({ type: values.type, createdAt: values.createdAt, value: values.value * 60 });
          resetForm({ values });
          setSubmitting(false);
        }}
        validationSchema={Schema}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, dirty, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit} action="#" method="POST">
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <VigoSelect
                  name="type"
                  required={false}
                  defaultValue={values.type}
                  onChange={(e: any) => {
                    setFieldValue("type", e.target.value);
                  }}
                  value={values.type}
                  showLabel={true}
                  labelText={t("activity.input.field.type")}
                  options={activityTypeOptions}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <VigoInput
                  onInput={handleOnInput}
                  placeholder="1-150"
                  type="number"
                  name="value"
                  min={1}
                  max={150}
                  autoComplete="off"
                  onChange={handleChange}
                  showLabel={true}
                  labelText={t("general.text.time")}
                />
              </div>
              <div className="col-span-6 sm:col-span-3 ">
                <div className="relative">
                  <VigoDatePicker
                    htmlFor="date"
                    name={t("general.text.date")}
                    locale={t("general.text.locale")}
                    required={true}
                    minDate={new Date(1900, 1, 1)}
                    maxDate={new Date(Date.now())}
                    error={false}
                    selected={values["createdAt"]}
                    onChange={(date: any) => {
                      setFieldValue("createdAt", date);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 flex justify-end">
              <VigoButton
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
                appearance={!isValid || !dirty || isSubmitting ? "disabled" : "primary"}
              >
                {t("general.button.add")}
              </VigoButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ActivityForm;
