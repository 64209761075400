import VigoButton from "../vigo-button/vigo-button.component";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { platforms, getPlatform } from "../../utils/platforms";
import { usePWAInstall } from "react-use-pwa-install";

const InstallModal = () => {
  const { t } = useTranslation();
  const platform = getPlatform();
  const install = usePWAInstall();
  const [isSupported, setIsSupported] = useState(false);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (platform === platforms.NATIVE) {
      setIsSupported(true);
    }
    if (platform !== platforms.OTHER) {
      setIsSupported(true);
    }
  }, [platform]);

  return (
    showModal &&
    isSupported &&
    install && (
      <div className="fixed z-40 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-xl leading-7 font-regular text-dark mb-8">{t("install.modal.message")}</h3>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 my-5 sm:mt-6 ">
              <VigoButton appearance="primary" onClick={() => setShowModal(false)}>
                {t("install.modal.cancel")}
              </VigoButton>
              <VigoButton appearance="active" id="" onClick={install}>
                {t("install.modal.accept")}
              </VigoButton>
            </div>
            <div className="mt-5 sm:mt-6"></div>
          </div>
        </div>
      </div>
    )
  );
};

export default InstallModal;
