import { useQuery } from "react-query";
import UserService from "../../services/user/user.service";

const useDailyPlanHistory = (key: string, localeId: string) => {
  return useQuery([key], () => UserService.fetchDailyPlanHistory(key, localeId), {
    staleTime: 5000,
  });
};

export default useDailyPlanHistory;
