import classNames from "classnames";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";

class VigoInputProps {
  showLabel?: boolean;
  labelText?: string;
}

type VigoInputPropsType = VigoInputProps & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

function VigoInput(props: VigoInputPropsType) {
  const { showLabel, labelText, name, checked, onChange, type } = props;

  const inputProps = { ...props };
  delete inputProps.showLabel;
  delete inputProps.labelText;

  return (
    <>
      <div className="flex items-center py-2">
        <input
          {...inputProps}
          id={name}
          type={type}
          checked={checked}
          onChange={onChange}
          className={classNames("h-6 w-6 text-active border-gray focus:ring-transparent focus:border-silver")}
        />
        {showLabel && (
          <label htmlFor={name} className="ml-4 block text-lg">
            {labelText}
          </label>
        )}
      </div>
    </>
  );
}

export default VigoInput;
