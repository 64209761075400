import {Expose, Type} from "class-transformer";
import Inventories from "./inventories";

class Answer {
    @Expose()
    phrase?: string;

    @Expose()
    isSelected?: boolean;

    @Expose()
    isCorrect?: boolean;

    @Expose()
    statisticsType?: string;

    @Expose()
    statisticsValue?: number;

    @Expose()
    nextIntentId?: string;

    @Expose()
    inventories?: Inventories

    @Expose()
    @Type(() => Answer)
    mood?: Answer[];

    @Expose()
    @Type(() => Answer)
    exgrade?: Answer[];

    @Expose()
    @Type(() => Answer)
    quiz?: Answer[];
}

export default Answer;
