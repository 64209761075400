import { RotateIcon, NoPhoneIcon } from "../../assets/icons";
import { useState, useEffect } from "react";

const RotateModal = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width < 1024 && width > 660 ? (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-active bg-opacity-100 transition-opacity" />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
          <div>
            <div className="px-8 py-4">
              <img src={RotateIcon} alt="Rotate" />
            </div>
            <div className="mt-3 text-center py-2 sm:mt-5">
              <h3 className="text-xl leading-7 font-medium text-dark">Please rotate your device</h3>
              <h2 className="text-xl leading-7 font-medium text-dark">We support horizontal orientation only</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    width < 660 && (
      <>
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-active bg-opacity-100 transition-opacity" />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div className="px-36">
                  <img src={NoPhoneIcon} alt="No Phone" />
                </div>
                <div className="mt-3 text-center py-2 sm:mt-5">
                  <h3 className="text-xl leading-7 font-medium text-dark">Mobile Devices Not Supported</h3>
                  <h2 className="text-xl leading-7 font-medium text-dark">
                    Please use your computer or tablet to use this app
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default RotateModal;
