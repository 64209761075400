import { CalenderIcon } from "../../assets/icons";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
const VigoDatePicker = (props: {
  open?: boolean;
  defaultValue?: Date;
  onChange?: any;
  name: string;
  locale: any;
  required: any;
  selected: any;
  minDate: any;
  maxDate: any;
  error: any;
  htmlFor: string;
}) => {
  const days = ["P", "O", "T", "C", "Pk", "S", "Sv"];
  const months = [
    "Janvāris",
    "Februāris",
    "Marts",
    "Aprīlis",
    "Maijs",
    "Jūnijs",
    "Jūlijs",
    "Augusts",
    "Septembris",
    "Oktobris",
    "Novembris",
    "Decembris",
  ];
  const { i18n } = useTranslation();
  const locale = {
    localize: {
      day: (n: any) => days[n],
      month: (n: any) => months[n],
    },
    formatLong: {
      date: () => (i18n.resolvedLanguage === "lv" ? "dd.MM.yyyy" : "MM/dd/yyyy"),
    },
  };
  return (
    <div>
      {" "}
      <label htmlFor={props.htmlFor} className="block font-bold text-sm text-dark">
        {props.name}
      </label>
      <div className="relative">
        <DatePicker
          open={props.open}
          locale={i18n.resolvedLanguage === "lv" ? locale : null}
          dateFormat={i18n.resolvedLanguage === "lv" ? "dd.MM.yyyy" : "MM/dd/yyyy"}
          required={props.required}
          selected={props.selected}
          minDate={props.minDate}
          maxDate={props.maxDate}
          className={
            props.error
              ? "mt-1 focus:ring-sky focus:border-silver block w-full font-regular shadow-sm sm:text-lg border-red rounded-sm py-2 "
              : "mt-1 focus:ring-sky focus:border-silver block w-full font-regular shadow-sm sm:text-lg border-gray rounded-sm py-2 "
          }
          name="strokeDate"
          onChange={props.onChange}
        />
        <div className="absolute cursor-pointer inset-y-0 right-0 pr-6 pt-1 flex items-center pointer-events-none">
          <img src={CalenderIcon} alt="Calender Icon" />
        </div>
      </div>
    </div>
  );
};

export default VigoDatePicker;
