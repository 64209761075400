import React from "react";
import { CheckIcon, TickIcon } from "../../assets/icons";
import Subscription, { SubscriptionState } from "../../types/models/subscription";
import { useTranslation } from "react-i18next";
import ConstantsService from "../../services/constants/constants.service";
import VigoButton from "../vigo-button/vigo-button.component";

class CurrentPlanProps {
  subscription: Subscription;
  onPortalOpen: () => void;
  onCheckoutOpen: () => void;
}

function CurrentPlan(props: CurrentPlanProps) {
  const { t } = useTranslation();

  const { subscription, onPortalOpen, onCheckoutOpen } = props;

  const subscriptionPlanKey = subscription.subscriptionPlan.key;
  const discount = subscription.promotionCode?.amountOff ?? 0;
  const subscriptionPlanPrice = (subscription.subscriptionPlan.price - discount) / 100;
  const features = ConstantsService.getSubscriptionPlanFeatures(subscriptionPlanKey);
  const isCurrent = subscription.state === SubscriptionState.Paid || subscription.state === SubscriptionState.Active;

  return (
    <div className="border border-silver bg-skybg rounded-lg">
      <div className="p-12">
        <div className="flex justify-between">
          <div>
            {isCurrent && <h3 className="text-2xl font-bold text-dark">{t("general.subscription-plan.current")}</h3>}
            <h2 className="py-2 text-6xl font-bold text-dark">
              {t(`general.subscription-plan.${subscriptionPlanKey}`)}
            </h2>
            <span className="text-xl opacity-70 text-dark">
              EUR {subscriptionPlanPrice}/{t("general.subscription-plan.month")}.
            </span>
          </div>
          {isCurrent && (
            <div className="">
              <img className="w-14 h-14" src={CheckIcon} alt="Check Icon" />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 p-12 bg-white  rounded-lg">
        <ul>
          {features.map((feature, index) => (
            <li key={index} className="py-2 flex justify-start">
              <img src={TickIcon} alt="Tick Icon" className="w-6 h-6" />
              <span className="text-xl text-dark ml-2">{t(feature)}</span>
            </li>
          ))}
        </ul>
        <div className="bg-white text-right">
          {isCurrent ? (
            <VigoButton onClick={onPortalOpen} appearance="secondary">
              {t("general.subscription-plan.cancel")}
            </VigoButton>
          ) : (
            <VigoButton onClick={onCheckoutOpen} appearance="secondary">
              {t("general.subscription-plan.subscribe")}
            </VigoButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default CurrentPlan;
