import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import classNames from "classnames";

class VigoButtonProps {
  appearance: "primary" | "secondary" | "disabled" | "active" | "danger" | "";
}

type VigoButtonPropsType = VigoButtonProps &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

function VigoButton(props: VigoButtonPropsType) {
  const className = classNames(props.className, {
    "inline-flex": true,
    "justify-center": true,
    "py-3": true,
    "px-6": true,
    border: true,
    "transition ease-in-out duration-150": true,
    "border-transparent": true,
    "shadow-sm": true,
    "text-lg": true,
    "font-bold": true,
    "rounded-md": true,
    "cursor-pointer": true,
    "text-active": props.appearance === "primary",
    "text-white": props.appearance === "active" || props.appearance === "danger",
    "text-dark": props.appearance === "secondary",
    "text-sky bg-disabled cursor-not-allowed": props.appearance === "disabled",
    "bg-active": props.appearance === "active",
    "bg-skybg": props.appearance === "primary",
    "bg-metal": props.appearance === "secondary",
    "bg-red": props.appearance === "danger",
    "hover:bg-skyhover": props.appearance === "primary",
    "focus:outline-none": true,
    "focus:ring-2": true,
    "focus:ring-offset-2": true,
    "ring-transparent": true,
  });

  return (
    <button {...props} className={className}>
      {props.children}
    </button>
  );
}

export default VigoButton;
