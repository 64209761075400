import { LeftArrowLongIcon, RightArrowLongIcon } from "../../assets/icons";
import { useState } from "react";
import PropTypes from "prop-types";

const Card = (props: any) => {
  const image = props.image;
  const title = props.title;
  const index = props.index;
  const isNew = props.new;
  console.log(index);
  return (
    <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg  overflow-hidden">
      <span className="inline-block relative">
        <img className="rounded-xl" src={image} alt="" />
        {isNew && (
          <p className="absolute top-0 text-xl font-bold text-white right-0 block p-2 rounded-tr-xl rounded-bl-xl  bg-green">
            Jauns
          </p>
        )}
        <p className="py-2 font-bold text-xl">{title}</p>
      </span>
    </div>
  );
};

const AdviceComponent = (props: any) => {
  const data = props.data;

  const [dataIndex, setDataIndex] = useState(0);
  const handleLeftArrowClick = () => {
    if (dataIndex === 0) return;
    setDataIndex(dataIndex - 3);
  };
  const handleRightArrowClick = () => {
    if (dataIndex >= data.length - 3) return;
    setDataIndex(dataIndex + 3);
  };

  return (
    <div className="px-16">
      <div className="flex justify-center my-5 p-8 border border-silver bg-white rounded-lg">
        <div>
          <div className="flex justify-between items-center py-4">
            <h1 className="text-2xl font-bold">{props.title}</h1>
            <div className="flex">
              <div className="flex items-center justify-between">
                {
                  // hide if dataIndex === 0
                  dataIndex !== 0 && (
                    <button
                      onClick={handleLeftArrowClick}
                      className="px-4 py-2 
                        rounded-sm
                        bg-sky"
                    >
                      <img className="h-5 w-5" src={LeftArrowLongIcon} alt="Left Arrow Icon" />
                    </button>
                  )
                }

                <span className="px-4 text-xl "> Nākamie</span>
                {
                  // hide if dataIndex === data.length - 3
                  dataIndex !== data.length - 3 && (
                    <button
                      onClick={handleRightArrowClick}
                      className="px-4 py-2 
                  rounded-sm
                  bg-sky"
                    >
                      <img
                        className="h-5 w-5"
                        onClick={handleRightArrowClick}
                        src={RightArrowLongIcon}
                        alt="Right Arrow Icon"
                      />
                    </button>
                  )
                }
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            {data.slice(dataIndex, dataIndex + 3).map((item: any, index: number) => (
              <Card key={index} new={item.new} index={index} image={item.image} title={item.title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdviceComponent;

AdviceComponent.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
