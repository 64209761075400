import { useTranslation } from "react-i18next";
import VigoCheckbox from "../../components/vigo-checkbox/vigo-checkbox.component";
import useAuth from "../../hooks/useAuth/useAuth";
import UserService from "../../services/user/user.service";
import UserConvoSpeed from "../../types/enums/user-convo-speed";
import {ErrorModal, SuccessModal} from "../vigo-modals";
import {useState} from "react";
const Settings = () => {
  const { user, refreshState } = useAuth();
  const { t } = useTranslation();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

  const onConvoSpeedChange = async (index: number) => {
    await UserService.updateUser(user.id, { convoSpeed: index })
        .then(function (response) {
          setSuccessMessage(t("profile.settings.speed.message.success"));
          setSuccess(true);
        })
        .catch(function (error) {
          setErrorMessage(`${error}`);
          setError(true);
        });
    await refreshState();
  };

  const onColorImpairmentChange = async () => {
    await UserService.updateUser(user.id, { isColorImpairment: !user.isColorImpairment })
        .then(function (response) {
          setSuccessMessage(t("message.success.data_saved"));
          setSuccess(true);
        })
        .catch(function (error) {
          setErrorMessage(`${error}`);
          setError(true);
        });
    await refreshState();
  };

  return (
      <>
          <ErrorModal setModal={setError}
                      modal={error}
                      text={errorMessage} />
          <SuccessModal setModal={setSuccess}
                        modal={success}
                        text={successMessage} />
    <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
      <div className="">
        <h2 className="text-2xl test-dark font-bold">{t("profile.settings.label")}</h2>
        <p className="py-4 text-lg">{t("profile.settings.speed.label")}</p>
      </div>
      <div className="grid grid-cols-3 gap-12 sm:grid-cols-3">
        {
            Object.keys(UserConvoSpeed).filter((v) => !isNaN(Number(v))).map((x, index) => {
            return (<button
                key={index}
                type="button"
                onClick={() => onConvoSpeedChange(index)}
                className={`${
                  user.convoSpeed === index
                      ? "bg-active border-transparent text-white border rounded-md py-4  flex items-center justify-center text-lg font-medium sm:flex-1"
                      : "bg-skybg border-transparent text-active border rounded-md py-4 flex items-center justify-center text-lg font-medium sm:flex-1"
                }`}
          >
            {t(`profile.settings.speed.${index}`)}
          </button>)
        })
        }
      </div>
      <p className="py-4 mt-4 text-xl">{t("profile.settings.blind.label")}</p>
      <div className="flex items-center">
        <VigoCheckbox
          name="color-blindness"
          type="checkbox"
          checked={user.isColorImpairment}
          onChange={onColorImpairmentChange}
          showLabel={true}
          labelText={t("profile.settings.blind.description")}
        />
      </div>
    </div>
      </>
  );
};

export default Settings;
