import AbstractService from "../abstract/abstract.service";
import Subscription from "../../types/models/subscription";

class SubscriptionService extends AbstractService<Subscription> {
    API_SEGMENT = 'subscriptions'

    async getLatestSubscription() {
        const result = await this.axios.get<Subscription>(`${this.API_SEGMENT}/latest`)

        return result.data;
    }
}

export default new SubscriptionService();
