import User from "./user";

class Inventories {
  add?: string;
  remove?: string;
}

export function calculateInventories(user: User, inventories: Inventories): string {
  const existing = user.inventories?.split(',') ?? [];
  const toAdd = inventories.add?.split(',') ?? [];
  const toRemove = inventories.remove?.split(',') ?? [];
  const added = new Set(existing.concat(toAdd));
  const removed = Array.from(added).filter(x => !toRemove.includes(x));
  return removed.join(',');
}

export default Inventories;
