const AnswerMood = (props: any) => {
    if (props.isOutgoing) {
        const selectedMood = props.answer.mood.find((x: any) => x.isSelected);
        return (
            <div className="flex justify-end">
                <button key={props.index} className="fade-in text-7xl py-2 mb-2 cursor-default">
                    {selectedMood.phrase}
                </button>
            </div>
        );
    } else {
        return (
            <div key={props.index} className="flex justify-end">
                {props.answer.mood.map((item: any, index: any) => {
                    return (
                        <button key={index} className="fade-in text-7xl py-2 mb-2 cursor-pointer w-full"
                                onClick={() => {
                                    item.isSelected = true;
                                    props.handleAnswer(item);
                                    props.handleOutgoing(props.answer);
                                }}>
                            {item.phrase}
                        </button>
                    );
                })}
            </div>
        );
    }
};

export default AnswerMood;
