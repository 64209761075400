import {Expose, Type} from "class-transformer";
import Message from "./message";
import Answer from "./answer";

class Intent {
    @Expose()
    intentId: string;

    @Expose()
    nextIntentId?: string;

    @Expose()
    @Type(() => Answer)
    answers?: Answer[];

    @Expose()
    @Type(() => Message)
    messages?: Message[];

    @Expose()
    isPaused?: boolean;

    @Expose()
    isStartIntent?: boolean;

    /**
     * @deprecated git rid after iOS is updated; change the format of 'quiz' answer; make changes in the DB
     */
    @Expose()
    isGameIntent?: boolean;

    @Expose()
    isTerminating?: boolean;

    @Expose()
    isExiting?: boolean;

    /**
     * @deprecated git rid after iOS is updated; change the format of 'quiz' answer; make changes in the DB
     */
    get transformedAnswers(): Answer[] {
        if (this.isGameIntent) {
            return [{ quiz: this.answers }];
        }
        return this.answers;
    }
}

export default Intent;
