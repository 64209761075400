import { useState } from "react";
import Answer from "../../types/models/answer";

const AnswerExgrade = (props: any) => {
  const initialIndex = props.answer.exgrade.findIndex((x: Answer) => x.isSelected);
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  function marginLeft(index: number) {
    switch (index) {
      case 0:
        return 10;
      case 1:
        return 120;
      case 2:
        return 195;
      case 3:
        return 292;
      case 4:
        return 400;
      case 5:
        return 510;
      default:
        return 0;
    }
  }

  if (props.isOutgoing) {
    return (
      <>
        <ul className="flex justify-between my-2 items-center  w-full">
          {props.answer.exgrade.map((item: Answer, index: any) => {
            return (
              <li
                key={index}
                className={
                  index > selectedIndex
                    ? "h-20 w-24 mr-1 text-6xl py-2 px-1 bg-sky text-center text-active cursor-default"
                    : "h-20 w-24 mr-1 text-6xl py-2 px-1 bg-active text-white text-center cursor-default"
                }
              >
                {item.statisticsValue}
              </li>
            );
          })}
        </ul>
        <div className="py-1">
          {props.answer.exgrade.map((_item: any, index: any) => {
            return (
              <p
                key={index}
                className="text-2xl"
                style={{
                  marginLeft: `${marginLeft(index)}px`,
                }}
              >
                {selectedIndex === index && props.answer.exgrade[selectedIndex].phrase}
              </p>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <ul className="flex justify-between my-1 items-center w-full ">
          {props.answer.exgrade.map((item: Answer, index: any) => {
            return (
              <li
                key={index}
                className={
                  selectedIndex < index
                    ? "h-20 w-24 mr-1 text-6xl py-2 px-1 bg-sky text-center text-active cursor-pointer hover:text-white hover:bg-active"
                    : "h-20 w-24 mr-1 text-6xl py-2 px-1 bg-active text-white text-center   cursor-pointer"
                }
                onClick={() => {
                  setSelectedIndex(index);
                }}
              >
                {item.statisticsValue}
              </li>
            );
          })}
        </ul>
        <div className="py-1">
          {props.answer.exgrade.map((_item: any, index: any) => {
            return (
              <p
                key={index}
                className="text-2xl"
                style={{
                  marginLeft: `${marginLeft(index)}px`,
                }}
              >
                {selectedIndex === index && props.answer.exgrade[selectedIndex].phrase}
              </p>
            );
          })}
        </div>
        {selectedIndex > -1 && (
          <div className="">
            <button
              key={props.index}
              className="w-full text-2xl bg-active font-regular rounded-xl shadow-3xl py-3 px-4 text-center"
              onClick={() => {
                props.answer.exgrade[selectedIndex].isSelected = true;
                props.handleAnswer(props.answer.exgrade[selectedIndex]);
                props.handleOutgoing(props.answer);
              }}
            >
              <span className="text-white" style={{ fontSize: `${props.textSize}rem` }}>
                {props.answer.phrase}
              </span>
            </button>
          </div>
        )}
      </>
    );
  }
};

export default AnswerExgrade;
