import { useQuery } from "react-query";
import WordGameService from "../../services/word-game/word-game.service";

const useWordGames = (letters: string[], localeId: string) => {
  return useQuery(["wordGames"], () => WordGameService.fetch(letters, localeId), {
    staleTime: 10000,
  });
};

export default useWordGames;
