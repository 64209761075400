import AbstractService from "../abstract/abstract.service";
import DailyPlanModuleStatus from "../../types/enums/daily-plan-module-status";

class DailyPlanModuleService extends AbstractService<any> {
  API_SEGMENT = "user-daily-plan-modules";

  async putStatus(id: number, status: DailyPlanModuleStatus) {
    const url = `${this.API_SEGMENT}/${id}/status/${status}`;
    return this.axios.put(url);
  }

  async putFeedback(id: number, feedback: number) {
    const params = {
      feedback: feedback,
    };
    const url = `${this.API_SEGMENT}/${id}`;
    return this.axios.put(url, params);
  }
}

export default new DailyPlanModuleService();
