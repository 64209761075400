import { Formik } from "formik";
import * as Yup from "yup";
import VigoInput from "../vigo-input/vigo-input.component";
import VigoButton from "../vigo-button/vigo-button.component";
import { useTranslation } from "react-i18next";
import User from "../../types/models/user";

class ActivityFormProps {
  initialValues?: User;
  onSubmit: (data: Partial<User>) => Promise<void>;
}

const Schema = Yup.object().shape({
  userActivityGoal: Yup.number().max(90).min(20).required(),
});

const handleOnInput = (e: any) => {
  if (e.target.value.length > 3) {
    e.target.value = e.target.value.slice(0, 3);
  }
};

function ActivityGoalForm(props: ActivityFormProps) {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <div className="px-0 py-5 text-dark bg-white ">
      <Formik
        initialValues={{
          userActivityGoal: initialValues?.userActivityGoal,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          onSubmit(values);
          resetForm({ values });
          setSubmitting(false);
        }}
        validationSchema={Schema}
      >
        {({ values, handleChange, handleSubmit, dirty, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit} action="#" method="POST">
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <VigoInput
                  onInput={handleOnInput}
                  placeholder="20-90"
                  type="number"
                  name="userActivityGoal"
                  defaultValue={initialValues?.userActivityGoal}
                  min={20}
                  max={90}
                  autoComplete="off"
                  onChange={handleChange}
                  showLabel={true}
                  labelText={t("general.text.time")}
                />
              </div>
            </div>
            <div className="mt-5 flex justify-end">
              <VigoButton
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
                appearance={!isValid || !dirty || isSubmitting ? "disabled" : "primary"}
              >
                {t("general.button.save")}
              </VigoButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ActivityGoalForm;
