import AbstractService from "../abstract/abstract.service";
import DailyActivity from "../../types/models/daily-activity";

class DailyActivityService extends AbstractService<DailyActivity> {
  API_SEGMENT = "user-daily-activity";

  async getDailyActivitiesByUserId(userId: number) {
    const path = `?join=user%7C%7Cid&filter=user.id%7C%7C%24eq%7C%7C${userId}`;
    const result = await this.axios.get<DailyActivity[]>(`${this.API_SEGMENT}${path}`);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async createDailyActivity(this: any, data: DailyActivity, userId: number): Promise<any> {
    const params = {
      user: userId,
      createdAt: data.createdAt,
      value: data.value,
      type: data.type,
    };
    return this.axios.post(this.API_SEGMENT, params);
  }

  async updateDailyActivity(this: any, data: DailyActivity): Promise<any> {
    const params = {
      createdAt: data.createdAt,
      value: data.value,
      type: data.type,
    };
    return this.axios.put(`${this.API_SEGMENT}/${data.id}`, params);
  }

  async deleteDailyActivity(this: any, dailyActivityId: number): Promise<any> {
    return this.axios.delete(`${this.API_SEGMENT}/${dailyActivityId}`);
  }
}

export default new DailyActivityService();
