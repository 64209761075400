import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {CheckIcon, VideoIcon} from "../../../../assets/icons";
import useAuth from "../../../../hooks/useAuth/useAuth";
import userService from "../../../../services/user/user.service";
import VigoButton from "../../../../components/vigo-button/vigo-button.component";
import useDailyPlan from "../../../../hooks/useDailyPlan/useDailyPlan";
import { SuccessModal, ErrorModal } from "../../../../components/vigo-modals/";
import LocalStorageService from "../../../../services/local-storage/local-storage.service";
import DailyPlanModule from "../../../../types/models/daily-plan-module";
import {VideoCall} from "../../../../components/video-call/video-call.component";

function PlanForToday() {
  const { user, refreshState } = useAuth();
  const { t } = useTranslation();
  const dailyPlan = useDailyPlan(user.id, user.localeId);
  const [dataItems, setDataItems] = useState([]);
  const [callRequestStatus, setCallRequestStatus] = useState(false);
  const [videoCall, setVideoCall] = useState(false);

  const [callRequestSuccessModal, setCallRequestSuccessModal] = useState(false);
  const [callRequestErrorModal, setCallRequestErrorModal] = useState(false);
  const [callRequestErrorMessage, setCallRequestErrorMessage] = useState('');

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCallRequest = async () => {
    setCallRequestStatus(false);
    await userService.postCallRequest()
        .then(function (response) {
          setCallRequestSuccessModal(true);
        })
        .catch(function (error) {
          setCallRequestErrorMessage(`${error}`);
          setCallRequestErrorModal(true);
        });

    await refreshState();
  };

  const handleVideoCallClick = async () => {
    setVideoCall(true)
  }

  const handleVideoCallClose = async () => {
    setVideoCall(false)
  }

  const handleDataItemClick = async (dailyPlanModule: DailyPlanModule) => {
    if (dailyPlanModule && dailyPlanModule.module) {
      LocalStorageService.dailyPlanModuleId = dailyPlanModule.id;
      LocalStorageService.module = dailyPlanModule.module;
      LocalStorageService.intent = dailyPlanModule.module.startIntent;
      window.location.href = "/home";
    }
  };

  useEffect(() => {
    setCallRequestStatus(user.callRequestAvailable);
  }, [user]);

  useEffect(() => {
    if (dailyPlan.error) {
      setErrorMessage(`${dailyPlan.error}`);
      setErrorModal(true);
    } else {
      if (dailyPlan.data && dailyPlan.data.dailyPlan && dailyPlan.data.dailyPlan.userDailyPlanModules) {
        setDataItems(dailyPlan.data.dailyPlan.userDailyPlanModules.filter((x) => x.module.showInDailyPlan));
      } else {
        setDataItems([]);
      }
    }
  }, [dailyPlan.data, dailyPlan.error]);

  function numOfExercises(): string {
    return t("plan.text.title", {
      number: dataItems.length,
      text: dataItems.length === 1 ? t("plan.text.task") : t("plan.text.tasks"),
    });
  }

  function doctorFullName(): string {
    if (user.doctor) {
      return `${user.doctor.first} ${user.doctor.last}`;
    } else {
      return "";
    }
  }

  return (
    <>
      {/* Videos Section */}
      <ErrorModal setModal={setErrorModal}
                  modal={errorModal}
                  text={errorMessage} />
      <ErrorModal setModal={setCallRequestErrorModal}
                    modal={callRequestErrorModal}
                    text={callRequestErrorMessage} />
      <SuccessModal setModal={setCallRequestSuccessModal}
                    modal={callRequestSuccessModal}
                    text={t("call.doctor.message.success")} />
      <div className="py-4 mb-6">
        <div className="flex justify-between max-w-5xl py-6">
          <div>
            <h2 className="font-bold md:text-3xl text-lg">{numOfExercises()}</h2>
          </div>
          <div className="flex">
            {dataItems.map((x, i) =>
              x.isCompleted ? (
                <img
                  key={i}
                  src={CheckIcon}
                  alt="Check Icon"
                  className="mx-1 block h-6 w-6 sm:h-8 sm:w-8 rounded-full bg-green"
                />
              ) : (
                <img
                  key={i}
                  src={CheckIcon}
                  alt="Check Icon"
                  className="mx-1 block h-6 w-6 sm:h-8 sm:w-8 rounded-full bg-green opacity-30"
                />
              ),
            )}
          </div>
        </div>
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
          {dataItems.map((item, index) => (
            <li onClick={() => handleDataItemClick(item)} key={index} className="relative cursor-pointer">
              <div className="group block w-full object-none aspect-w-10 aspect-h-7 rounded-lg  overflow-hidden">
                <span className="inline-block relative ">
                  <div className="img-gradient">
                    <img
                      className="rounded-xl z-0 w-72 h-48 object-cover"
                      src={item.module.localizedOrDefaultThumbnail}
                      alt=""
                    />
                    {item.isCompleted ? (
                      <img
                        className="absolute z-10 top-5 right-5 block h-7 w-7 rounded-full"
                        src={CheckIcon}
                        alt="Check Icon"
                      />
                    ) : null}
                  </div>
                </span>
              </div>
              <p className="mt-2 block text-xl font-bold text-dark  pointer-events-none">
                {item.module.localizedTitle}
              </p>
            </li>
          ))}
        </ul>
      </div>
      {/* CTA SECTOION */}
      <div className="inline-block align-bottom bg-white border border-silver rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xm transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-48 w-48  sm:mx-12 sm:h-48 sm:w-48">
            <img
              src={user.doctor ? user.doctor.avatarUrl : null}
              alt=""
              className="h-48 w-48 rounded-full object-cover"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-4 sm:text-left">
            <p className="text-2xl ">{t("call.doctor.title")}</p>
            <div className="mt-2">
              <p className="text-2xl font-bold ">{doctorFullName()}</p>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex">
              <VigoButton
                disabled={!callRequestStatus}
                onClick={handleCallRequest}
                className="w-full"
                appearance={callRequestStatus ? "primary" : "disabled"}
              >
                {t("call.doctor.button")}
              </VigoButton>
              <VigoButton
                  disabled={!callRequestStatus}
                  onClick={handleVideoCallClick}
                  appearance="danger"
                  className="ml-4 flex items-center"
              >
                <img src={VideoIcon} className="w-10" />
              </VigoButton>
              <VideoCall open={videoCall} onClose={handleVideoCallClose}></VideoCall>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanForToday;
