import UserData from "../../types/models/user-data";
import AbstractService from "../abstract/abstract.service";

class UserDataService extends AbstractService<UserData> {
  API_SEGMENT = "user-data";

  async getUserDataByUserId(userId: number, type: string) {
    const path = `?join=user%7C%7Cid&filter=user.id%7C%7C%24eq%7C%7C${userId}&filter%5B1%5D=type%7C%7C%24eq%7C%7C${type}`
    const result = await this.axios.get<UserData[]>(`${this.API_SEGMENT}${path}`);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async createUserData(this: any, data: Partial<UserData>, userId: number) {
    const params = {
      user: userId,
      value: data.value,
      type: data.type,
      title: data.title,
    };
    return await this.axios.post(this.API_SEGMENT, params);
  }
}

export default new UserDataService();
