import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import User from "../../types/models/user";
import VigoInput from "../vigo-input/vigo-input.component";
import VigoPhoneInput from "../vigo-phone-input/vigo-phone-input.component";
import VigoDatePicker from "../vigo-datepicker/vigo-datepicker.component";
import VigoSelect, { VigoSelectOption } from "../vigo-select/vigo-select.component";
import { useEffect } from "react";
// import UserGender from "../../types/enums/user-gender";
import UserStrokeType from "../../types/enums/user-stroke-type";
import UserStrokeSide from "../../types/enums/user-stroke-side";
import VigoButton from "../vigo-button/vigo-button.component";
// import VigoRadio from "../vigo-radio/vigo-radio.component";
import VigoRadioButton from "../vigo-radio-button/vigo-radio-button.component";
import UserService from "../../services/user/user.service";
import i18next from "i18next";

class OnboardingFormProps {
  initialValues?: User;
  onSubmit: (data: Partial<User>) => Promise<void>;
}

function OnboardingForm(props: OnboardingFormProps) {
  const { t } = useTranslation();

  const { initialValues, onSubmit } = props;

  const Schema = Yup.object().shape(
    {
      first: Yup.string().required("Required"),
      last: Yup.string().required("Required"),
      formOfAddress: Yup.string().required("Required"),
      gender: Yup.number().required("Required"),
      placeOfResidence: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      login: Yup.string()
        .required("Required")
        .test("checkExistingLogin", "Login already taken", async (value) => {
          if (value === initialValues.login) {
            return true;
          }
          const exist = await UserService.checkLogin(value);
          return !exist;
        }),
      strokeType: Yup.string().required("Required"),
      strokeSide: Yup.string().required("Required"),
      strokeDate: Yup.string().required("Required"),
      dob: Yup.string().required("Required"),
      acceptedTermsAndConditions: Yup.boolean().oneOf([true], "Required"),
      password: Yup.string().required("profile.form.error.password"),
      passwordConfirmation: Yup.string()
        .when("password", {
          is: (val: any) => !!val,
          then: Yup.string().required("profile.form.error.password"),
          otherwise: Yup.string().notRequired(),
        })
        .oneOf([Yup.ref("password"), null], "profile.form.error.confirmpassword"),
    },
    [["password", "passwordConfirmation"]],
  );

  useEffect(() => {
    i18next.changeLanguage(initialValues.localeId === "lv_LV" ? "lv" : "en");
  }, [initialValues]);
  // const genderOptions: VigoSelectOption[] = Object.values(UserGender).map((g) => ({
  //     label: t(`general.gender.${g}`),
  //     value: g,
  // }));

  const strokeTypeOptions: VigoSelectOption[] = Object.values(UserStrokeType).map((st) => ({
    label: t(`general.stroketype.${st}`),
    value: st,
  }));

  const strokeSideOptions: VigoSelectOption[] = Object.values(UserStrokeSide).map((ss) => ({
    label: t(`general.strokeside.${ss}`),
    value: ss,
  }));

  return (
    <>
      <Formik
        initialValues={{
          first: initialValues?.first,
          last: initialValues?.last,
          gender: initialValues?.gender,
          dob: initialValues?.dob && new Date(initialValues.dob),
          strokeDate: initialValues?.strokeDate && new Date(initialValues.strokeDate),
          strokeType: initialValues?.strokeType,
          strokeSide: initialValues?.strokeSide,
          login: initialValues?.login,
          phone: initialValues?.phone,
          placeOfResidence: initialValues?.placeOfResidence,
          formOfAddress: initialValues?.formOfAddress,
          email: initialValues?.email,
          password: initialValues?.password,
          passwordConfirmation: initialValues?.password,
          localeId: initialValues?.localeId,
          acceptedTermsAndConditions: initialValues?.acceptedTermsAndConditions,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          delete values.passwordConfirmation;
          await onSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={Schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldValue,
          dirty,
          isValid,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} action="#" method="POST">
            <div className="border border-silver overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 text-dark bg-white sm:p-6">
                <h1 className="font-bold text-3xl my-2">{t("profile.form.title")}</h1>
                <h2 className="font-medium text-xl mb-3 py-2">{t("profile.form.details")}</h2>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.first && touched.first}
                      type="text"
                      name="first"
                      value={values["first"]}
                      autoComplete="given-name"
                      onChange={handleChange}
                      showLabel={true}
                      labelText={t("profile.form.name")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.last && touched.last}
                      type="text"
                      name="last"
                      value={values["last"]}
                      autoComplete="family-name"
                      onChange={handleChange}
                      showLabel={true}
                      labelText={t("profile.form.surname")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.formOfAddress && touched.formOfAddress}
                      type="text"
                      name="formOfAddress"
                      value={values["formOfAddress"]}
                      onChange={handleChange}
                      showLabel={true}
                      labelText={t("profile.form.formaddress")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoDatePicker
                      htmlFor="birth-date"
                      name={t("profile.field.birthdate")}
                      locale={initialValues?.localeId}
                      required={errors.dob && touched.dob}
                      error={errors.dob && touched.dob}
                      selected={values["dob"]}
                      onChange={(date: any) => setFieldValue("dob", date)}
                      maxDate={values.strokeDate ? new Date(values.strokeDate) : new Date(Date.now())}
                      minDate={new Date(1900, 1, 1)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <VigoInput
                      required={errors.placeOfResidence && touched.placeOfResidence}
                      type="text"
                      name="placeOfResidence"
                      value={values["placeOfResidence"]}
                      onChange={handleChange}
                      showLabel={true}
                      labelText={t("profile.form.address")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoPhoneInput
                      onChange={(phone: any) =>
                        setValues({
                          ...values,
                          phone: phone,
                        })
                      }
                      value={values["phone"]}
                      name={t("profile.form.phonenumber")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.email && touched.email}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      showLabel={true}
                      labelText={t("profile.form.email")}
                      autoComplete="email"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6" roloaria-labelledby="my-radio-group">
                    <div id="my-radio-group" className="text-sm font-bold text-dark">
                      {t("profile.form.gender")}
                    </div>
                    <div className="flex mt-2">
                      <VigoRadioButton
                        name="gender"
                        value="1"
                        label={t("general.gender.1")}
                        checked={values.gender.toString() === "1"}
                        onChange={() => setFieldValue("gender", 1)}
                      />
                      <VigoRadioButton
                        name="gender"
                        value="2"
                        label={t("general.gender.2")}
                        checked={values.gender.toString() === "2"}
                        onChange={() => setFieldValue("gender", 2)}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-6  mt-8">
                    <h2 className="font-medium text-xl py-2">{t("profile.form.strokeinformation")}</h2>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoDatePicker
                      htmlFor="stroke-date"
                      name={t("profile.form.dateofstroke")}
                      locale={initialValues?.localeId}
                      required={errors.strokeDate && touched.strokeDate}
                      error={errors.strokeDate && touched.strokeDate}
                      selected={values["strokeDate"]}
                      onChange={(date: any) => setFieldValue("strokeDate", date)}
                      maxDate={new Date()}
                      minDate={new Date(values.dob)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoSelect
                      required={errors.strokeType && touched.strokeType}
                      name="strokeType"
                      onChange={handleChange}
                      value={values.strokeType}
                      showLabel={true}
                      labelText={t("profile.form.type")}
                      options={strokeTypeOptions}
                    />
                  </div>
                  <div className="col-span-6">
                    <VigoSelect
                      required={errors.strokeSide && touched.strokeSide}
                      name="strokeSide"
                      onChange={handleChange}
                      value={values.strokeSide}
                      showLabel={true}
                      labelText={t("profile.form.strokeside")}
                      options={strokeSideOptions}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6  mt-8">
                    <h2 className="font-medium text-xl py-2">{t("profile.form.changepassword")}</h2>
                  </div>
                  <div className="col-span-6">
                    <VigoInput
                      required={errors.login && touched.login}
                      type="text"
                      name="login"
                      onChange={handleChange}
                      value={values.login}
                      autoComplete="username"
                      showLabel={true}
                      labelText={t("profile.form.login")}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {t(errors.login)}
                    </span>
                  </div>
                  <div className="col-span-6">
                    <VigoInput
                      required={errors.password && touched.password}
                      id="new-password-text-field"
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      showLabel={true}
                      labelText={t("profile.form.password")}
                      placeholder={t("profile.form.password")}
                    />
                  </div>
                  <div className="col-span-6">
                    <VigoInput
                      required={errors.passwordConfirmation && touched.passwordConfirmation}
                      id="confirm-password-text-field"
                      autoComplete="new-password"
                      type="password"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      onChange={handleChange}
                      showLabel={true}
                      labelText={t("profile.form.confirmnewpassword")}
                      placeholder={t("profile.form.confirmnewpassword")}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {t(errors.passwordConfirmation)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-white text-left sm:px-6">
                <input
                  className="appearance-none h-5 w-5 border border-dark rounded-sm bg-white checked:bg-active checked:border-active focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                  type="checkbox"
                  checked={values.acceptedTermsAndConditions}
                  onChange={handleChange}
                  onClick={() => setFieldValue("acceptedTermsAndConditions", !values.acceptedTermsAndConditions)}
                  value=""
                />
                <p className="px-8 text-lg">
                  {t("general.button.confirm")}{" "}
                  <a
                    href={
                      values.localeId === "lv_LV"
                        ? "https://vigo.health/lietosanas-noteikumi/"
                        : "https://vigo.health/en/terms-of-use/"
                    }
                    className="text-active"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("profile.form.termsofuse")}
                  </a>
                </p>
              </div>
              <div className="flex justify-end px-4 py-3 mb-3 bg-white text-right sm:px-6">
                <VigoButton
                  type="submit"
                  disabled={!isValid || !values.acceptedTermsAndConditions || !dirty}
                  appearance={!isValid || !values.acceptedTermsAndConditions || !dirty ? "disabled" : "primary"}
                >
                  {isSubmitting ? (
                    <div
                      style={{ borderTopColor: "transparent" }}
                      className="w-5 h-5 mx-3 py-1 border-2 border-active border-solid rounded-full animate-spin"
                    />
                  ) : (
                    t("profile.form.submit")
                  )}
                </VigoButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default OnboardingForm;
