import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import VigoButton from "../vigo-button/vigo-button.component";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { lv } from "date-fns/locale";
import moment from "moment";
import { EllipseIcon, RectangleIcon } from "../../assets/icons";
import BloodPressure from "../../types/models/blood-pressure";

const Bar = (props: any) => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const models = props.day.models
      ? props.day.models.sort((a: BloodPressure, b: BloodPressure) => a.createdAt > b.createdAt)
      : [];

  const handleDayClick = () => {
    if (models.length) {
      setOpen(true);
    }
  };

  function getWeekday(): string {
    if (moment(props.day.date).isSame(moment(), "day")) {
      return t("general.text.today");
    } else {
      return format(new Date(props.day.date), i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM", {
        locale: i18n.resolvedLanguage === "lv" ? lv : null,
      });
    }
  }

  const getAllDiastolic = () => {
    return models.map((model: any) => model.diastolic);
  };

  const getAllSystolic = () => {
    return models.map((model: any) => model.systolic);
  };

  const getAllCreatedAt = () => {
    return models.map((model: BloodPressure) => model.createdAt);
  };

  const isToday = moment(props.day.date).isSame(moment(), "day");
  return (
    <>
      <div
        onClick={handleDayClick}
        className={
          isToday
            ? "m-0 w-full flex flex-col relative justify-end  border-l border-dashed border-silver bg-chartBg "
            : "m-0 w-full flex flex-col relative justify-end  border-l border-dashed border-silver "
        }
        style={{ maxHeight: "440px" }}
      >
        <div className="border-b border-silver w-full z-20 absolute" style={{ top: "0" }} />
        <div className="border-b border-silver h-1 w-full z-20 absolute" style={{ top: "43px" }} />
        <div className="border-b border-silver h-1 w-full z-20 absolute" style={{ top: "143px" }} />
        <div className="border-b border-silver h-1 w-full z-20 absolute" style={{ top: "243px" }} />
        <div className="border-b border-silver h-1 w-full z-20 absolute" style={{ top: "343px" }} />
        <div className="flex justify-center ">
          {getAllSystolic().map((systolic: number, index: number) => {
            return (
                <div
                  key={index}
                  className="w-4 h-4 bg-red rounded-full absolute z-30"
                  style={{
                    bottom: `${(systolic / 200) * 380}px`,
                  }}
                />
            );
          })}
          {getAllDiastolic().map((diastolic: number, index: number) => {
            return (
              <div
                key={index}
                className="w-3 h-3 bg-dark absolute z-30"
                style={{
                  bottom: `${(diastolic / 200) * 388}px`,
                  transform: "rotate(45deg)",
                }}
              />
            );
          })}
          <div className="bg-transparent px-2 absolute bottom-10">
            <p className="text-sm text-dark font-semibold cursor-pointer" onClick={handleDayClick}>
              {getWeekday()}
            </p>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {format(new Date(props.day.date), i18n.resolvedLanguage === "lv" ? "dd. MMMM" : "MMMM dd", {
                        locale: i18n.resolvedLanguage === "lv" ? lv : null,
                      })}
                    </Dialog.Title>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table-auto border border-silver rounded">
                      <thead className="h-16">
                        <tr className="h-16">
                          <th></th>
                          {getAllCreatedAt().map((createdAt: Date, index: number) => {
                            return (
                              <th key={index} className="p-0 py-4 border border-silver ">
                                <p className="text-center font-">
                                  {format(new Date(createdAt), i18n.resolvedLanguage === "lv" ? "HH:mm" : "h:mm a", {
                                    locale: i18n.resolvedLanguage === "lv" ? lv : null,
                                  })}
                                </p>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="">
                        <tr>
                          <td className="p-4 w-44 h-16 items-center border-t border-silver text-center flex">
                            <img src={EllipseIcon} alt="Ellipse Icon" className="w-4 h-4 z-30 mx-2" />
                            <p className="text-center"> {t("general.text.systolic")} (SYS)</p>
                          </td>

                          {getAllSystolic().map((item: Number, index: number) => {
                            return <td key={index} className="border px-8 h-16 border-silver text-center text-sm">{item}</td>;
                          })}
                        </tr>
                        <tr>
                          <td className="p-4 w-44 h-16 items-center border-t border-silver text-center flex">
                            <img src={RectangleIcon} alt="Rectangle Icon" className="w-4 h-4 z-30 mx-2" />
                            <p className="text-center">{t("general.text.diastolic")} (DIA)</p>
                          </td>
                          {getAllDiastolic().map((item: Number, index: number) => {
                            return <td key={index} className="border px-8 h-16 border-silver text-center text-sm">{item}</td>;
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-5 flex justify-end">
                  <VigoButton onClick={() => setOpen(false)} appearance="primary">
                    {t("general.button.done")}
                  </VigoButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default Bar;
