export enum SubscriptionPlanType {
    Digital = 'digital',
    Full = 'full',
    Premium = 'premium',
    Hospital = 'hospital',
    Contract = 'contract',
}

class SubscriptionPlan {
    id: number;
    stripeProductId: string;
    key: SubscriptionPlanType;
    stripePriceId: string;
    price: number;
    createdAt: Date | string;
}

export default SubscriptionPlan;
