import { useQuery } from "react-query";
import UserDataService from "../../services/user-data/user-data.service";

const useMood = (userId: number) => {
  return useQuery(["mood", userId], () => UserDataService.getUserDataByUserId(userId, "Mood"), {
    staleTime: 5000,
  });
};

export default useMood;
