import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import User from "../../types/models/user";
import VigoInput from "../vigo-input/vigo-input.component";
import VigoPhoneInput from "../vigo-phone-input/vigo-phone-input.component";
import VigoDatePicker from "../vigo-datepicker/vigo-datepicker.component";
import VigoSelect, { VigoSelectOption } from "../vigo-select/vigo-select.component";
// import UserGender from "../../types/enums/user-gender";
import UserStrokeType from "../../types/enums/user-stroke-type";
import UserStrokeSide from "../../types/enums/user-stroke-side";
import VigoButton from "../vigo-button/vigo-button.component";
import VigoRadioButton from "../vigo-radio-button/vigo-radio-button.component";
import VigoNotification from "../vigo-notification/vigo-notification.component";
import { useEffect, useState } from "react";

class ProfileFormProps {
  initialValues?: User;
  onSubmit: (data: Partial<User>) => Promise<void>;
}

const Schema = Yup.object().shape(
  {
    first: Yup.string().required("Required"),
    last: Yup.string().required("Required"),
    formOfAddress: Yup.string().required("Required"),
    gender: Yup.number().required("Required"),
    placeOfResidence: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    login: Yup.string().required("Required"),
    strokeType: Yup.string().required("Required"),
    strokeSide: Yup.string().required("Required"),
    strokeDate: Yup.string().required("Required"),
    dob: Yup.string().required("Required"),
    password: Yup.string(),
    passwordConfirmation: Yup.string()
      .when("password", {
        is: (val: any) => !!val,
        then: Yup.string().required("profile.form.error.password"),
        otherwise: Yup.string().notRequired(),
      })
      .oneOf([Yup.ref("password"), null], "profile.form.error.confirmpassword"),
  },
  [["password", "passwordConfirmation"]],
);

function ProfileForm(props: ProfileFormProps) {
  const { t } = useTranslation();
  const { initialValues, onSubmit } = props;

  // const genderOptions: VigoSelectOption[] = Object.values(UserGender).map((g) => ({
  //   label: t(`general.gender.${g}`),
  //   value: g,
  // }));

  const strokeTypeOptions: VigoSelectOption[] = Object.values(UserStrokeType).map((st) => ({
    label: t(`general.stroketype.${st}`),
    value: st,
  }));

  const strokeSideOptions: VigoSelectOption[] = Object.values(UserStrokeSide).map((ss) => ({
    label: t(`general.strokeside.${ss}`),
    value: ss,
  }));

  const [successMessage, setSuccessMessage] = useState(false);
  const [passChanged, setPassChanged] = useState(false);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage(false);
      }, 3000);
    }
  }, [successMessage]);

  return (
    <>
      <VigoNotification show={successMessage} message={t("profile.form.success")} />
      <Formik
        enableReinitialize
        initialValues={{
          first: initialValues?.first,
          last: initialValues?.last,
          gender: initialValues?.gender,
          dob: initialValues?.dob && new Date(initialValues.dob),
          strokeDate: initialValues?.strokeDate && new Date(initialValues.strokeDate),
          strokeType: initialValues?.strokeType,
          strokeSide: initialValues?.strokeSide,
          login: initialValues?.login,
          phone: initialValues?.phone,
          placeOfResidence: initialValues?.placeOfResidence,
          formOfAddress: initialValues?.formOfAddress,
          email: initialValues?.email,
          password: initialValues?.password,
          passwordConfirmation: initialValues?.password,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          delete values.passwordConfirmation;
          await onSubmit(values);
          setSubmitting(false);
          setSuccessMessage(true);
          setPassChanged(true);
          resetForm({ values });
        }}
        validationSchema={Schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setValues,
          setFieldValue,
          dirty,
          isValid,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} action="#" method="POST">
            <div className="border border-silver overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 text-dark bg-white sm:p-6">
                <h1 className="font-bold text-3xl my-2">{t("profile.form.title")}</h1>
                <h2 className="font-medium text-xl mb-3 py-2">{t("profile.form.details")}</h2>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.first && touched.first}
                      type="text"
                      name="first"
                      value={values["first"]}
                      autoComplete="given-name"
                      onChange={(e) => {
                        setFieldValue("first", e.target.value);
                        setPassChanged(false);
                      }}
                      showLabel={true}
                      labelText={t("profile.form.name")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.last && touched.last}
                      type="text"
                      name="last"
                      value={values["last"]}
                      autoComplete="family-name"
                      onChange={(e) => {
                        setFieldValue("last", e.target.value);
                        setPassChanged(false);
                      }}
                      showLabel={true}
                      labelText={t("profile.form.surname")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.formOfAddress && touched.formOfAddress}
                      onBlur={() => setPassChanged(false)}
                      type="text"
                      name="formOfAddress"
                      value={values["formOfAddress"]}
                      onChange={(e) => {
                        setFieldValue("formOfAddress", e.target.value);
                        setPassChanged(false);
                      }}
                      showLabel={true}
                      labelText={t("profile.form.formaddress")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoDatePicker
                      htmlFor="birth-date"
                      name={t("profile.field.birthdate")}
                      locale={initialValues?.localeId}
                      required={errors.dob && touched.dob}
                      error={errors.dob && touched.dob}
                      selected={values["dob"]}
                      onChange={(date: any) => {
                        setFieldValue("dob", date);
                        setPassChanged(false);
                      }}
                      maxDate={values.strokeDate ? new Date(values.strokeDate) : new Date(Date.now())}
                      minDate={new Date(1900, 1, 1)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <VigoInput
                      required={errors.placeOfResidence && touched.placeOfResidence}
                      onBlur={() => setPassChanged(false)}
                      type="text"
                      name="placeOfResidence"
                      value={values["placeOfResidence"]}
                      onChange={(e) => {
                        setFieldValue("placeOfResidence", e.target.value);
                        setPassChanged(false);
                      }}
                      showLabel={true}
                      labelText={t("profile.form.address")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoPhoneInput
                      onChange={(phone: any) => {
                        setValues({
                          ...values,
                          phone: phone,
                        });
                        setPassChanged(false);
                      }}
                      value={values["phone"]}
                      name={t("profile.form.phonenumber")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoInput
                      required={errors.email && touched.email}
                      onBlur={() => setPassChanged(false)}
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                        setPassChanged(false);
                      }}
                      value={values.email}
                      showLabel={true}
                      labelText={t("profile.form.email")}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <div
                      id="my-radio-group"
                      roloaria-labelledby="my-radio-group"
                      className="text-sm font-bold text-dark"
                    >
                      {t("profile.form.gender")}
                    </div>
                    <div className="flex mt-2">
                      <VigoRadioButton
                        name="gender"
                        value="1"
                        label={t("general.gender.1")}
                        checked={values.gender.toString() === "1"}
                        onChange={(e: any) => {
                          setFieldValue("gender", 1);
                          setPassChanged(false);
                        }}
                      />
                      <VigoRadioButton
                        name="gender"
                        value="2"
                        label={t("general.gender.2")}
                        checked={values.gender.toString() === "2"}
                        onChange={(e: any) => {
                          setFieldValue("gender", 2);
                          setPassChanged(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-6  mt-8">
                    <h2 className="font-medium text-xl py-2">{t("profile.form.strokeinformation")}</h2>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoDatePicker
                      htmlFor="stroke-date"
                      name={t("profile.form.dateofstroke")}
                      locale={initialValues?.localeId}
                      required={errors.strokeDate && touched.strokeDate}
                      error={errors.strokeDate && touched.strokeDate}
                      selected={values["strokeDate"]}
                      onChange={(date: any) => {
                        setFieldValue("strokeDate", date);
                        setPassChanged(false);
                      }}
                      maxDate={new Date()}
                      minDate={new Date(values.dob)}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <VigoSelect
                      required={errors.strokeType && touched.strokeType}
                      name="strokeType"
                      onChange={(e: any) => {
                        setFieldValue("strokeType", e.target.value);
                        setPassChanged(false);
                      }}
                      value={values.strokeType}
                      showLabel={true}
                      labelText={t("profile.form.type")}
                      options={strokeTypeOptions}
                    />
                  </div>
                  <div className="col-span-6">
                    <VigoSelect
                      required={errors.strokeSide && touched.strokeSide}
                      name="strokeSide"
                      onChange={(e: any) => {
                        setFieldValue("strokeSide", e.target.value);
                        setPassChanged(false);
                      }}
                      value={values.strokeSide}
                      showLabel={true}
                      labelText={t("profile.form.strokeside")}
                      options={strokeSideOptions}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6  mt-8">
                    <h2 className="font-medium text-xl py-2">{t("profile.form.changepassword")}</h2>
                  </div>
                  <div className="col-span-6">
                    <VigoInput
                      disabled
                      type="text"
                      name="login"
                      onChange={handleChange}
                      value={values.login}
                      autoComplete="username"
                      showLabel={true}
                      labelText={t("profile.form.login")}
                    />
                  </div>
                  <div className="col-span-6">
                    <VigoInput
                      required={errors.password && touched.password}
                      id="new-password-text-field"
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      onChange={(e: any) => {
                        setFieldValue("password", e.target.value);
                        if (e.target.value.length > 0) {
                          setPassChanged(false);
                        }
                      }}
                      value={values.password}
                      showLabel={true}
                      labelText={t("profile.form.password")}
                      placeholder={t("profile.form.password")}
                    />
                  </div>
                  <div className="col-span-6">
                    <VigoInput
                      required={errors.passwordConfirmation && touched.passwordConfirmation}
                      id="confirm-password-text-field"
                      autoComplete="new-password"
                      type="password"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      onChange={(e: any) => {
                        setFieldValue("passwordConfirmation", e.target.value);
                      }}
                      showLabel={true}
                      labelText={t("profile.form.confirmnewpassword")}
                      placeholder={t("profile.form.confirmnewpassword")}
                    />
                    <span className="error" style={{ color: "red" }}>
                      {t(errors.passwordConfirmation)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 mb-3 bg-white text-right sm:px-6">
                <VigoButton
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting || passChanged}
                  appearance={!isValid || !dirty || isSubmitting || passChanged ? "disabled" : "primary"}
                >
                  {t("profile.form.submit")}
                </VigoButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ProfileForm;
