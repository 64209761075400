import {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {JitsiMeeting} from "@jitsi/react-sdk";
import useAuth from "../../hooks/useAuth/useAuth";
import * as jose from "jose";
import {IJitsiMeetingProps} from "@jitsi/react-sdk/lib/types";

async function generateJwt(options: IJitsiMeetingProps): Promise<string> {
    try {
        const secret = new TextEncoder().encode(process.env.REACT_APP_JITSI_SECRET);
        const alg = "HS256";

        const jwt = await new jose.SignJWT(options as any)
            .setProtectedHeader({ alg })
            .setSubject(process.env.REACT_APP_JITSI_DOMAIN)
            .setIssuer(process.env.REACT_APP_JITSI_APP_ID)
            .setProtectedHeader({ typ: "JWT", alg: "HS256" })
            .setAudience(process.env.REACT_APP_JITSI_APP_ID)
            .setExpirationTime(15000069230)
            .setNotBefore(1500006923)
            .sign(secret);

        console.log(jwt);

        return jwt;
    } catch (e){

    }
}

export const VideoCall = (props: { open: boolean, onClose: any }) => {
    const cancelButtonRef = useRef(null);
    const { user } = useAuth();
    const [jwt, setJwt] = useState(null);
    const [jitsiOptions, setJitsiOptions] = useState({
        domain: process.env.REACT_APP_JITSI_DOMAIN,
        roomName: user.id.toString(),
        configOverwrite: {
            startAudioOnly: false,
            startWithAudioMuted: false,
            startWithVideoMuted: false,
            hideConferenceSubject: true,
            disableModeratorIndicator: true,
            prejoinPageEnabled: false,
            remoteVideoMenu: {
                disabled: true,
                disableKick: true,
                disableGrantModerator: true,
                disablePrivateChat: true,
            },
            securityUi: {
                hideLobbyButton: true,
            },
            toolbarButtons: ["camera", "hangup", "microphone"],
            notifications: [],
        },
        interfaceConfigOverwrite: {
            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
        },
        userInfo: {
            displayName: user.first,
            email: user.email
        },
        getIFrameRef: (iframeRef: any) => { iframeRef.style.height = '100vh'; },
        onReadyToClose: () => props.onClose(),
        jwt: undefined
    });
    const jwtOptions: any = {
            context: {
                user: {
                    email: user.email,
                    name: user.first,
                },
            },
            room: user.id,
        };

    useEffect(() => {
        async function getToken() {
            const jwt = await generateJwt(jwtOptions);
            setJwt(jwt);
            setJitsiOptions({ ...jitsiOptions, jwt })
        }
        getToken().then();
    }, []);


    if(!props.open || !jwt) return <></>

    console.log(jitsiOptions);

    return (
        <div>
            <div className="">
                <Transition.Root show={props.open} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed z-50 inset-0 overflow-y-auto"
                        initialFocus={cancelButtonRef}
                        onClose={() => props.onClose(false)}
                    >
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
                            </Transition.Child>
                            {/* trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white text-left overflow-hidden transform transition-all h-screen w-full">
                                    <JitsiMeeting {...jitsiOptions}/>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </div>
    )
}