import { useTranslation } from "react-i18next";
import { StarTransparentIcon, StarIcon } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import useDailyPlanHistory from "../../../../hooks/useDailyPlanHistory/useDailyPlanHistory";
import useAuth from "../../../../hooks/useAuth/useAuth";
import DailyPlanHistoryItem from "../../../../types/models/daily-plan-history-item";
import moment from "moment";
import LocalStorageService from "../../../../services/local-storage/local-storage.service";
import UserService from "../../../../services/user/user.service";

function History() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { status, data, error, refetch } = useDailyPlanHistory("history", user.localeId);
  const [dataItems, setDataItems] = useState([]);

  const handleDataItemClick = async (item: DailyPlanHistoryItem) => {
    if (item.userDailyPlanModule && item.userDailyPlanModule.module) {
      LocalStorageService.dailyPlanModuleId = item.userDailyPlanModule.id;
      LocalStorageService.module = item.userDailyPlanModule.module;
      LocalStorageService.intent = item.userDailyPlanModule.module.startIntent;
      window.location.href = "/home";
    }
  };

  const toggleFavorites = async (item: DailyPlanHistoryItem) => {
    await UserService.toggleFavorites(user.id, item.userDailyPlanModule.id, !item.userDailyPlanModule.isFavorite);
    await refetch();
  };

  useEffect(() => {
    if (data && data.length) {
      setDataItems(data);
    }
  }, [status, data]);

  function daysSince(item: DailyPlanHistoryItem): number {
    if (user.strokeDate) {
      const strokeDate = moment(user.strokeDate);
      const itemDate = moment(item.createdAt);
      return itemDate.diff(strokeDate, 'day');
    }
    return 0;
  }

  return (
    <ul className="py-8">
      {dataItems.map((item) => (
        <li onClick={() => handleDataItemClick(item)} key={item.id} className="relative mb-12">
          {
            <>
              <p className="text-xl py-4">
                {t("history.label.days", { value: daysSince(item) })}
              </p>
              <div className="flex group w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                <span className="inline-block relative">
                  <div className="img-gradient">
                    <img
                      className="rounded-xl z-0 block w-72 h-48 object-cover"
                      src={item.userDailyPlanModule.module.localizedOrDefaultThumbnail}
                      alt=""
                    />
                    <img
                        className="absolute z-10 top-5 right-5 block h-7 w-7 rounded-full cursor-pointer"
                        onClick={() => toggleFavorites(item)}
                        src={item.userDailyPlanModule.isFavorite ? StarIcon : StarTransparentIcon}
                        alt="Check Icon"
                    />
                  </div>
                </span>
                <p className="mx-4 place-self-center text-xl font-bold text-dark pointer-events-none">
                  {item.userDailyPlanModule.module.localizedTitle}
                </p>
              </div>
            </>
          }
        </li>
      ))}
    </ul>
  );
}

export default History;
