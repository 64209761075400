import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth/useAuth";
import BloodPressure from "../../types/models/blood-pressure";
import VigoDatePicker from "../vigo-datepicker/vigo-datepicker.component";
import VigoInput from "../vigo-input/vigo-input.component";
import { useTranslation } from "react-i18next";
import VigoButton from "../vigo-button/vigo-button.component";

class BloodPressureFormProps {
  initialValues?: BloodPressure;
  onSubmit: (values: BloodPressure) => void;
}

const Schema = Yup.object().shape({
  systolic: Yup.number().max(200).min(40).required(),
  diastolic: Yup.number().max(200).min(40).required(),
  createdAt: Yup.date(),
});

const handleOnInput = (e: any) => {
  if (e.target.value.length > 3) {
    e.target.value = e.target.value.slice(0, 3);
  }
};

const BloodPressureForm = (props: BloodPressureFormProps) => {
  const { user } = useAuth();
  const { initialValues, onSubmit } = props;
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialValues ? new Date(initialValues.createdAt) : new Date(),
  );

  return (
    <div className="px-0 py-5 text-dark bg-white ">
      <Formik
        initialValues={{
          user: {
            id: user.id,
          },
          systolic: initialValues?.systolic,
          diastolic: initialValues?.diastolic,
          createdAt: initialValues?.createdAt,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          onSubmit(values);
          resetForm({ values });
          setSubmitting(false);
        }}
        validationSchema={Schema}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, dirty, isValid, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit} action="#" method="POST">
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-6 sm:col-span-3 ">
                <div className="col-span-6 sm:col-span-3">
                  <div className="relative">
                    <VigoDatePicker
                      htmlFor="date"
                      name={t("general.text.date")}
                      locale={t("general.text.locale")}
                      required={true}
                      minDate={new Date(1900, 1, 1)}
                      maxDate={new Date(Date.now())}
                      error={false}
                      selected={selectedDate}
                      onChange={(date: Date) => {
                        setSelectedDate(date);
                        setFieldValue("createdAt", date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <VigoInput
                  type="text"
                  name="time"
                  autoComplete="time"
                  defaultValue={new Date(values["createdAt"] ? values["createdAt"] : Date.now()).toLocaleTimeString(
                      i18n.resolvedLanguage,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    },
                  )}
                  showLabel={true}
                  labelText={t("general.text.time")}
                  onChange={(e: any) => {
                    setFieldValue(
                      "createdAt",
                      new Date(
                        selectedDate?.getFullYear(),
                        selectedDate?.getMonth(),
                        selectedDate?.getDate(),
                        parseInt(e.target.value.split(":")[0]),
                        parseInt(e.target.value.split(":")[1]),
                      ),
                    );
                  }}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <VigoInput
                  onInput={handleOnInput}
                  placeholder="40-200"
                  min="40"
                  max="200"
                  type="number"
                  name="systolic"
                  autoComplete="off"
                  onChange={handleChange}
                  showLabel={true}
                  labelText={t("general.text.systolic")}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <VigoInput
                  onInput={handleOnInput}
                  placeholder="40-200"
                  min="40"
                  max="200"
                  type="number"
                  name="diastolic"
                  autoComplete="off"
                  onChange={handleChange}
                  showLabel={true}
                  labelText={t("general.text.diastolic")}
                />
              </div>
            </div>
            <div className="mt-5 flex justify-end">
              <VigoButton
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
                appearance={!isValid || !dirty || isSubmitting ? "disabled" : "primary"}
              >
                {t("general.button.add")}
              </VigoButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default BloodPressureForm;
