import {useEffect, useState} from "react";
import {Logo, MinusIcon, PlusIcon} from "../../assets/icons";
import VideoLocalization from "../../components/video-localization/video-localization.component";
import SpeechGame from "../../components/speech-game/speech-game.component";
import Message, {fillLackingValuesIfNeeded} from "../../types/models/message";
import Answer from "../../types/models/answer";
import AnswerMood from "../../components/answer-mood/answer-mood.component";
import AnswerQuiz from "../../components/answer-quiz/answer-quiz.component";
import AnswerPhrase from "../../components/answer-phrase/answer-phrase.component";
import Module from "../../types/models/module";
import Intent from "../../types/models/intent";
import MessageText from "../../components/message-text/message-text.component";
import MessageImage from "../../components/message-image/message-image.component";
import MessageVideo from "../../components/message-video/message-video.component";
import MessageSpeech from "../../components/message-speech/message-speech.component";
import AnswerExgrade from "../../components/answer-exgrade/answer-exgrade.component";
import Layout from "../../components/layout/layout.component";
import useAuth from "../../hooks/useAuth/useAuth";
import useDailyPlan from "../../hooks/useDailyPlan/useDailyPlan";
import DailyPlanData from "../../types/models/daily-plan-data";
import DailyPlanService from "../../services/daily-plan/daily-plan.service";
import DailyPlanModuleService from "../../services/daily-plan-module/daily-plan-module.service";
import UserService from "../../services/user/user.service";
import UserDataService from "../../services/user-data/user-data.service";
import {calculateInventories} from "../../types/models/inventories";
import DailyPlanModuleStatus from "../../types/enums/daily-plan-module-status";
import LocalStorageService from "../../services/local-storage/local-storage.service";
import UniversalModuleType from "../../types/enums/universal-module-type";
import UserConvoSpeed from "../../types/enums/user-convo-speed";
import useInterval from "../../hooks/useInterval/useInterval";
import Video from "../../components/video/video.component";

// function Fader(props: any) {
//   const [opacity, setOpacity] = useState(0);
//   useEffect(() => {
//     setTimeout(() => {
//       setOpacity(1);
//     }, props.delay);
//   }, [props.delay, props.messages]);
//   return (
//     <div
//       className="py-2"
//       style={{
//         opacity,
//       }}
//     >
//       {props.children}
//     </div>
//   );
// }

export const HomePage = () => {
  const { user } = useAuth();
  const dailyPlan = useDailyPlan(user?.id, user?.localeId);
  const [dailyPlanData, setDailyPlanData] = useState<DailyPlanData>(null);

  useEffect(() => {
    if (dailyPlanData) {
      if (!dailyPlanData.dailyPlan.isStarted) {
        const dailyPlanModule = dailyPlanData.dailyPlan.userDailyPlanModules[0];
        if (dailyPlanModule) {
          setMessages([]);
          LocalStorageService.messages = [];
          startModule(dailyPlanModule.module, dailyPlanModule.id);
        }
      } else if (!messages.length && !intent) {
        const dailyPlanModule = dailyPlanData.dailyPlan.nextDailyPlanModule();
        if (dailyPlanModule) {
          startUniversalModule(UniversalModuleType.Loginback);
        }
      }
    }
  }, [dailyPlanData]);

  useEffect(() => {
    if (dailyPlan.data) {
      setDailyPlanData(dailyPlan.data);
    }
  }, [dailyPlan.error, dailyPlan.data]);

  const [openSpeechGame, setOpenSpeechGame] = useState(false);
  const [speechGameMessage, setSpeechGameMessage] = useState<Message>(null);

  const [openVideoLocalization, setOpenVideoLocalization] = useState(false);
  const [videoLocalizationMessage, setVideoLocalizationMessage] = useState<Message>(null);

  const [openVideo, setOpenVideo] = useState(false);
  const [videoMessage, setVideoMessage] = useState<Message>(null);

  useEffect(() => {
    if (!openVideoLocalization && videoLocalizationMessage && intent?.isPaused) {
      startShameOrExgrade(videoLocalizationMessage)
    }
  }, [openVideoLocalization]);

  useEffect(() => {
    if (intent?.isPaused) {
      if (!openVideo && videoMessage) {
        if (videoMessage.isExercise) {
          startShameOrExgrade(videoMessage)
        } else if (intent) {
          const newIntent = intent;
          newIntent.isPaused = false;
          setIntent(newIntent);
          LocalStorageService.intent = newIntent;
          setIsTicking(true);
        }
      }
    }
  }, [openVideo]);

  useEffect(() => {
    if (!openVideo && videoMessage && intent?.isPaused) {
      const newIntent = intent;
      newIntent.isPaused = false;
      setIntent(newIntent);
      LocalStorageService.intent = newIntent;
      setIsTicking(true);
    }
  }, [openSpeechGame]);

  function isExercising(): boolean {
    return openSpeechGame || openVideoLocalization || (openVideo && videoMessage?.isExercise);
  }

  const [textSize, setTextSize] = useState(1.4);

  const [dailyPlanModuleId, setDailyPlanModuleId] = useState(() => {
    return LocalStorageService.dailyPlanModuleId;
  });

  const [module, setModule] = useState<Module>(() => {
    return LocalStorageService.module;
  });

  const [intent, setIntent] = useState<Intent>(() => {
    return LocalStorageService.intent;
  });

  const [messages, setMessages] = useState(() => {
    return LocalStorageService.messages;
  });

  function getAnswers() {
    const answers = intent?.transformedAnswers;
    if (answers && answers.length) {
      if (!intent.messages || !intent.messages.length) {
        return answers;
      }
    }
    return [];
  }

  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    if (messages.length) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight - window.innerHeight,
          behavior: firstScroll ? "smooth" : "auto",
        });
        setFirstScroll(true);
      }, firstScroll ? 200 : 0);
    }
  }, [messages]);

  useEffect(() => {
    setIsTicking(!!intent);
  }, [intent]);

  const handleAnswer = async (answer: Answer) => {
    if (user?.callRequestAvailable
        && answer.statisticsType === "exgrade"
        && (answer.statisticsValue === 1 || answer.statisticsValue === 6)) {
      switch (answer.statisticsValue) {
        case 1:
          startUniversalModule(UniversalModuleType.TooHard);
          break;
        case 6:
          startUniversalModule(UniversalModuleType.TooEasy);
          break;
        default: break;
      }
    } else {
      let newIntent = answer.nextIntentId ? module?.intentById(answer.nextIntentId) : null;
      if (!newIntent) {
        newIntent = intent;
        newIntent.answers = [];
      }
      setIntent(newIntent);
      LocalStorageService.intent = newIntent;
    }
    setIsTicking(true);

    if (answer.statisticsType
        && (answer.statisticsType === UniversalModuleType.TooEasy
            || answer.statisticsType === UniversalModuleType.TooHard)) {
      await UserService.postCallRequest(module.internalTitle);
    } else if (answer.statisticsValue && answer.statisticsType) {
      const userData = {
        title: answer.phrase,
        value: answer.statisticsValue,
        type: answer.statisticsType,
      }
      await UserDataService.createUserData(userData, user.id);

      if (answer.statisticsType === "exgrade" && dailyPlanModuleId) {
        await DailyPlanModuleService.putFeedback(dailyPlanModuleId, answer.statisticsValue);
      }
    }

    if (answer.inventories) {
      const inventories = calculateInventories(user, answer.inventories);
      await UserService.updateUser(user.id, { inventories: inventories });
    }
  };

  const sendStatsInterval = 15;
  useInterval(() => {
        if (dailyPlanData && dailyPlanData.dailyPlan) {
          DailyPlanService.sendTimeStats(dailyPlanData.dailyPlan.id, sendStatsInterval, isExercising());
        }
      },sendStatsInterval * 1000,
  )

  const [isTicking, setIsTicking] = useState(false);
  useInterval(() => {
        checkIntent()
      },isTicking ? tickInterval() : null,
  )

  const checkIntent = async () => {
    if (intent && !intent.isPaused) {
      if (intent.isStartIntent) {
        const newIntent = intent;
        newIntent.isStartIntent = false;
        setIntent(newIntent);
        LocalStorageService.intent = newIntent;
        await putStatusIfNeeded(dailyPlanModuleId, DailyPlanModuleStatus.InProgress);

        setIsTicking(true);
      } else if (intent.messages && intent.messages.length) {
        const newMessage = fillLackingValuesIfNeeded(intent.messages[0], module);
        const newMessages = [...messages, newMessage];

        const newIntent = intent;
        newIntent.isPaused = !!newMessage.videoUrl || !!newMessage.videoExerciseId || !!newMessage.speechGame;
        newIntent.messages = intent.messages.slice(1, intent.messages.length);

        setIntent(newIntent);
        LocalStorageService.intent = newIntent;

        setMessages(newMessages);
        LocalStorageService.messages = newMessages;

        setIsTicking(true);
      } else {
        if (!intent.answers || !intent.answers.length) {
          if (intent.nextIntentId) {
            const newIntent = module?.intentById(intent.nextIntentId);
            if (newIntent) {
              setIntent(newIntent);
              LocalStorageService.intent = newIntent;

              setIsTicking(true);
            }
          } else if (intent.isTerminating || intent.isExiting) {
            const status = intent.isTerminating ? DailyPlanModuleStatus.Completed : DailyPlanModuleStatus.Exited;
            const newIntent = intent;
            newIntent.isTerminating = false;
            newIntent.isExiting = false;

            setIntent(newIntent);
            LocalStorageService.intent = newIntent;

            await putStatusIfNeeded(dailyPlanModuleId, status);
            if (dailyPlanModuleId) {
              await UserService.postHistory(user.id, dailyPlanModuleId);
            }

            const next = dailyPlanData?.dailyPlan.nextDailyPlanModule(dailyPlanModuleId);
            if (next) {
              await startModule(next.module, next.id);
            }
          }
        }
      }
    }
  };

  function tickInterval(): number {
    const multiplier = (messages && messages.length && messages[messages.length - 1].image) ? 2 : 1;
    switch (user.convoSpeed) {
      case UserConvoSpeed.Slow: return 6000 * multiplier;
      case UserConvoSpeed.Medium: return 3000 * multiplier;
      case UserConvoSpeed.Fast: return 1500 * multiplier;
    }
  }

  const putStatusIfNeeded = async (dailyPlanModuleId: number, status: DailyPlanModuleStatus) => {
    const dailyPlanModule = dailyPlanData.dailyPlan.userDailyPlanModules.find(x => x.id === dailyPlanModuleId);
    if (dailyPlanModule && dailyPlanModule.canChangeStatusTo(status)) {
      await DailyPlanModuleService.putStatus(dailyPlanModuleId, status);
      await dailyPlan.refetch();
    }
  };

  const startShameOrExgrade = (message: Message) => {
    const percentage = message.percentage ?? 80;
    startUniversalModule(
        (message.percentWatched >= percentage) ? UniversalModuleType.Exgrade : UniversalModuleType.Shame
    );
  };

  const startUniversalModule = (type: UniversalModuleType) => {
    const universalModule = dailyPlanData.universalModule(type);
    if (universalModule) {
      startModule(universalModule, dailyPlanModuleId);
    }
  };

  const startModule = async (module: Module, dpModuleId?: number) => {
    setDailyPlanModuleId(dpModuleId);
    LocalStorageService.dailyPlanModuleId = dpModuleId;

    setModule(module);
    LocalStorageService.module = module;

    const intent = module.startIntent;
    setIntent(intent);
    LocalStorageService.intent = intent;

    setIsTicking(true);
  };

  const increaseTextSize = () => {
    if (textSize < 1.8) {
      setTextSize(textSize + 0.2);
    }
  };
  const decreaseTextSize = () => {
    if (textSize > 0.8) {
      setTextSize(textSize - 0.2);
    }
  };
  const TextSizePercent = () => {
    const textSizePercent = Math.floor(textSize * 100);
    if (textSizePercent === 140) {
      return "100%";
    } else if (textSizePercent === 160) {
      return "110%";
    } else if (textSizePercent === 179) {
      return "120%";
    } else if (textSizePercent === 199) {
      return "130%";
    } else if (textSizePercent === 120) {
      return "90%";
    } else if (textSizePercent === 100) {
      return "80%";
    } else if (textSizePercent === 80) {
      return "70%";
    }
  };

  const handleOutgoing = (answer: Answer) => {
    const outgoingMessage = { answer: answer };
    const newMessages = [...messages, outgoingMessage];
    setMessages(newMessages);
    LocalStorageService.messages = newMessages;
  };

  const handleVideoOpen = (message: Message) => {
    setVideoMessage(message);
    setOpenVideo(true);
  };

  const handleVideoLocalizationOpen = (message: Message) => {
    setVideoLocalizationMessage(message);
    setOpenVideoLocalization(true);
  };

  const handleSpeechGameOpen = (message: Message) => {
    setSpeechGameMessage(message);
    setOpenSpeechGame(true);
  };

  function AnswerComponent(answer: Answer, isOutgoing: boolean) {
    if (answer.quiz) {
      return (
          <AnswerQuiz
              answer={answer}
              isOutgoing={isOutgoing}
              textSize={textSize}
              handleOutgoing={handleOutgoing}
              handleAnswer={handleAnswer}
          />
      );
    } else if (answer.exgrade && answer.phrase) {
      return (
          <AnswerExgrade
              answer={answer}
              isOutgoing={isOutgoing}
              textSize={textSize}
              handleOutgoing={handleOutgoing}
              handleAnswer={handleAnswer}
          />
      );
    } else if (answer.mood) {
      return (
          <AnswerMood
              answer={answer}
              isOutgoing={isOutgoing}
              handleOutgoing={handleOutgoing}
              handleAnswer={handleAnswer}
          />
      );
    } else if (answer.phrase) {
      return (
          <AnswerPhrase
              answer={answer}
              isOutgoing={isOutgoing}
              textSize={textSize}
              handleOutgoing={handleOutgoing}
              handleAnswer={handleAnswer}
          />
      );
    }
  }

  function MessageComponent(message: any) {
    if (message.answer) {
      return AnswerComponent(message.answer, true);
    } else if (message.text) {
      return <MessageText message={message} textSize={textSize} />;
    } else if (message.image) {
      return <MessageImage message={message} textSize={textSize} />;
    } else if (message.videoExerciseId) {
      return <MessageVideo message={message} handleVideoOpen={handleVideoLocalizationOpen} />;
    } else if (message.videoUrl) {
      return <MessageVideo message={message} handleVideoOpen={handleVideoOpen} />;
    } else if (message.speechGame && message.speechGame.length) {
      return <MessageSpeech message={message} handleSpeechGameOpen={handleSpeechGameOpen} />;
    }
  }

  return (
      <Layout>
        <Video
            open={openVideo}
            setOpen={setOpenVideo}
            message={videoMessage}
        />

        <VideoLocalization
            open={openVideoLocalization}
            setOpen={setOpenVideoLocalization}
            message={videoLocalizationMessage}
        />

        <SpeechGame open={openSpeechGame} setOpen={setOpenSpeechGame} message={speechGameMessage} />
        <div className="max-w-2xl py-14 mx-auto px-4 sm:px-6 md:px-8 min-h-screen">
          <img src={Logo} alt="Logo" />
          <div className="flex flex-col mt-3 py-8">
            <div id="chat">
              {messages.map((item: any, index: any) => {
                return <div key={index} className={messages.length > 0 && "py-2"}>{MessageComponent(item)}</div>;
              })}
              {getAnswers().map((item: any, index: any) => {
                return (
                    <div key={index + messages.length} className={messages.length > 0 && "py-2"}>
                      {AnswerComponent(item, false)}
                    </div>
                );
              })}
              <div className="fixed ml-8 bottom-10 center-10">
                {intent?.isStartIntent ? (
                    <div className="snippet" data-title=".dot-typing">
                      <div className="stage">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="fixed bottom-10 right-10">
          <div className="flex flex-col justify-center content-center text-center">
            <p className="mb-2">{TextSizePercent()}</p>
            {/* make current text size 100% and incremant and decremant by 10% */}
            <div className="px-4 py-1 rounded-sm bg-sky mb-2 cursor-pointer" onClick={increaseTextSize}>
              <img src={PlusIcon} className="py-2 " alt="plus" />
            </div>
            <div className="px-4 py-3 rounded-sm bg-sky cursor-pointer" onClick={decreaseTextSize}>
              <img src={MinusIcon} className="py-4" alt="minus" />
            </div>
          </div>
        </div>
      </Layout>
  );
};

export default HomePage;
