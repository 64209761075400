import { EditIcon, LeftArrowIcon, RightArrowIcon } from "../../../../assets/icons";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { lv } from "date-fns/locale";
import Bar from "../../../../components/activity-chart/activity-chart.component";
import VigoButton from "../../../../components/vigo-button/vigo-button.component";
import ActivityForm from "../../../../components/activity-form/activity-form.component";
import ActivityGoal from "../../../../components/activity-goal/activity-goal.component";
import UserService from "../../../../services/user/user.service";
import useAuth from "../../../../hooks/useAuth/useAuth";
import DailyActivityService from "../../../../services/daily-activity/daily-activity.service";
import useDailyActivity from "../../../../hooks/useDailyActivity/useDailyActivity";
import ChartBarData, {
  getDateRangeForChartBarData,
  transformToDailyChartBarData,
} from "../../../../types/classes/chart-bar-model";
import DailyActivity from "../../../../types/models/daily-activity";
import useActivityReport from "../../../../hooks/useActivityReport/useActivityReport";
import DailyActivityType from "../../../../types/enums/daily-activity-type";

const ActivityTab = () => {
  const { user, refreshState } = useAuth();
  const { t, i18n } = useTranslation();

  const dailyActivity = useDailyActivity(user.id);
  const [dailyActivityData, setDailyActivityData] = useState([]);

  const activityReport = useActivityReport(user.id);
  const [activityReportData, setActivityReportData] = useState([]);

  const [data, setData] = useState(transformToDailyChartBarData([]));
  const [dataIndex, setDataIndex] = useState(0);

  const [activityOpen, setActivityOpen] = useState(false);
  const [goalOpen, setGoalOpen] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (dailyActivity.error) {
      setErrorMessage(`${dailyActivity.error}`);
      setErrorModal(true);
    }
    setDailyActivityData(dailyActivity.data ?? []);
  }, [dailyActivity.data, dailyActivity.error]);

  useEffect(() => {
    if (activityReport.error) {
      setErrorMessage(`${activityReport.error}`);
      setErrorModal(true);
    }
    setActivityReportData(activityReport.data ?? []);
  }, [activityReport.data, activityReport.error]);

  useEffect(() => {
    const weekData = transformToDailyChartBarData(
      dailyActivityData.concat(
        activityReportData.map((x) => {
          return { value: x.timeInExercises, createdAt: x.date };
        }),
      ),
    );
    setDataIndex(weekData.length - 7);
    setData(weekData);
  }, [dailyActivityData, activityReportData]);

  const cancelButtonRef = useRef(null);

  const handleLeftArrowClick = () => {
    if (dataIndex === 0) return;
    setDataIndex(dataIndex - 7);
  };
  const handleRightArrowClick = () => {
    if (dataIndex + 7 > data.length - 1) return;
    setDataIndex(dataIndex + 7);
  };

  const handleSubmitActivity = async (data: any) => {
    setActivityOpen(false);
    await DailyActivityService.createDailyActivity(data, user.id)
      .then(function (response) {
        setSuccessMessage(t("exercise_time.add.activity.success.message"));
        setSuccessModal(true);
      })
      .catch(function (error) {
        setErrorMessage(`${error}`);
        setErrorModal(true);
      });

    await dailyActivity.refetch();
  };

  const handleUpdateActivity = async (data: DailyActivity) => {
    await DailyActivityService.updateDailyActivity(data).catch(function (error) {
      setErrorMessage(`${error}`);
      setErrorModal(true);
    });

    await dailyActivity.refetch();
  };

  const handleDeleteActivity = async (id: number) => {
    await DailyActivityService.deleteDailyActivity(id).catch(function (error) {
      setErrorMessage(`${error}`);
      setErrorModal(true);
    });

    await dailyActivity.refetch();
  };

  const handleSubmitGoal = async (data: any) => {
    setGoalOpen(false);
    await UserService.updateUser(user.id, data);
    await refreshState();
  };

  return (
    <>
      <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl"> {t("exercise_time.add.activity")}</h2>
          <VigoButton appearance="primary" onClick={() => setActivityOpen(true)}>
            {t("general.button.add")}
          </VigoButton>
        </div>
      </div>

      {/* Form modal */}
      <Transition.Root show={activityOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setActivityOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("exercise_time.add.activity")}
                    </Dialog.Title>
                  </div>
                  <ActivityForm
                    initialValues={{ type: DailyActivityType.Walking, value: null, createdAt: null }}
                    onSubmit={handleSubmitActivity}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Activity Goal modal */}
      <Transition.Root show={goalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setGoalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
            </Transition.Child>
            {/* trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl py-4 leading-6 font-medium">
                      {t("exercise_time.set.goal")}
                    </Dialog.Title>
                  </div>
                  <ActivityGoal initialValues={user.userActivityGoal > 0 ? user : null} onSubmit={handleSubmitGoal} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {user.userActivityGoal === 0 && (
        <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl">
              {" "}
              {t("exercise_time.set.goal")} <span className="font-bold text-dark">{t("exercise_time.no.goal")}</span>
            </h2>
            <div className="cursor-pointer">
              <img onClick={() => setGoalOpen(true)} src={EditIcon} alt="" />
            </div>
          </div>
        </div>
      )}

      <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
        <div className="pr-0">
          <div className="flex justify-between xl:px-16">
            <div className="grid justify-items-start">
              <h2 className="leading-2 font-bold text-2xl pb-4">{t("exercise_time.screen.title")}</h2>
              <div className="flex justify-center ">
                <button onClick={handleLeftArrowClick}>
                  <img src={LeftArrowIcon} alt="left icon" />
                </button>
                <p className="text-center w-44 ">
                  {getDateRangeForChartBarData(
                    data.slice(dataIndex, dataIndex + 7),
                    i18n.resolvedLanguage === "lv" ? lv : null,
                    i18n.resolvedLanguage === "lv" ? "dd. MMM" : "dd MMM",
                  )}
                </p>

                <button onClick={handleRightArrowClick}>
                  <img src={RightArrowIcon} alt="right icon" />
                </button>
              </div>
            </div>
            <div className="flex-col justify-center ">
              <div className="flex justify-between py-1 ">
                <div
                  className={
                    user.isColorImpairment
                      ? "w-6 h-6 absolute mini-activity-stripes z-30"
                      : "w-6 h-6 absolute bg-active z-30"
                  }
                />
                <span className="pl-8 mx-1 text-lg">{t("exercise_time.exercising.vigo")}</span>
              </div>

              <div className="flex justify-between py-1">
                <div
                  className={
                    user.isColorImpairment ? "w-6 h-6 absolute bg-active z-30" : "w-6 h-6 absolute bg-dark z-30"
                  }
                />
                <span className="pl-8 mx-1 text-lg">{t("exercise_time.exercising.self")}</span>
              </div>
            </div>
          </div>

          <div className="max-w-full xl:px-16 mt-6 mx-auto">
            <div className="bg-white overflow-hidden">
              <div className="flex mx-4 z-10 justify-center ">
                <div
                  style={{
                    width: "61%",
                    maxWidth: "900px",
                    marginTop: "20px",
                  }}
                  className="absolute"
                >
                  <span className="z-10 absolute -ml-8 text-silver -mt-3">
                    {150}
                  </span>
                  <span style={{ marginTop: "61px" }} className="z-10 absolute -ml-8 text-silver">
                    {120}
                  </span>
                  <span style={{ marginTop: "134px" }} className="z-10 absolute -ml-8 text-silver">
                    {90}
                  </span>
                  <span style={{ marginTop: "210px" }} className="z-10 absolute -ml-8 text-silver">
                    {60}
                  </span>
                  <span style={{ marginTop: "280px" }} className="z-10 absolute -ml-8 text-silver">
                    {30}
                  </span>
                  <span style={{ marginTop: "353px" }} className="z-10 absolute -ml-7 text-silver">
                    {0}
                  </span>

                  {user.userActivityGoal > 0 && (
                    <div
                      className="z-10 absolute"
                      style={{
                        width: "100%",
                        marginTop: "34px",
                        bottom: `${Math.floor(user.userActivityGoal * 2.45)}px`,
                      }}
                    >
                      <span className="z-10 absolute -ml-8 text-active">{user.userActivityGoal}</span>
                      <div className="border-dashed border-b-2 mt-3 border-active max-w-96" />
                    </div>
                  )}
                  <div style={{ height: "1px" }} className="bg-silver" />
                  <div style={{ height: "1px", marginTop: "72px" }} className="bg-silver" />
                  <div style={{ height: "1px", marginTop: "72px" }} className="bg-silver mt-20" />
                  <div style={{ height: "1px", marginTop: "72px" }} className="bg-silver mt-20" />
                  <div style={{ height: "1px", marginTop: "72px" }} className="bg-silver mt-20" />
                  <div style={{ height: "1px", marginTop: "72px" }} className="bg-silver mt-20" />
                </div>
              </div>

              <div className="grid grid-cols-7 gap-0 xl:px-0 px-8">
                {/* <div className="bg-white overflow-hidden" style={{ height: "440px" }}>
                  <div className="ml-20 z-20 absolute text-silver" style={{ top: "435px" }}>
                    150
                  </div>
                  <div className="ml-20 z-20 text-silver absolute" style={{ top: "615px" }}>
                    75
                  </div>
                  <div className="ml-20 z-20 text-silver absolute" style={{ top: "795px" }}>
                    0
                  </div>
                </div> */}
                {data.slice(dataIndex, dataIndex + 7).map((day: ChartBarData<DailyActivity>, index) => {
                  return (
                    <Bar
                      key={index}
                      day={day}
                      isColorImpairment={user.isColorImpairment}
                      goal={Math.floor(user.userActivityGoal * 2.9)}
                      onEdit={(data: DailyActivity) => handleUpdateActivity(data)}
                      onDelete={(id: number) => handleDeleteActivity(id)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {user.userActivityGoal > 0 && (
        <div className="mt-5 p-8 border border-silver bg-white rounded-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl">
              {" "}
              {t("exercise_time.set.goal")}{" "}
              <span className="font-bold text-dark">
                {user.userActivityGoal} {t("general.text.minutes")}
              </span>
            </h2>
            <div className="cursor-pointer">
              <img onClick={() => setGoalOpen(true)} src={EditIcon} alt="" />
            </div>
          </div>
        </div>
      )}
      <br />
      <br />
    </>
  );
};

export default ActivityTab;
