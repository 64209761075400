import {Expose, Type} from "class-transformer";
import DailyPlan from "./daily-plan";
import Module from "./module";
import UniversalModuleType from "../enums/universal-module-type";

class DailyPlanData {
  @Expose()
  @Type(() => DailyPlan)
  dailyPlan: DailyPlan;

  @Expose()
  @Type(() => Module)
  universalModules: Module[];

  universalModule(type: UniversalModuleType): Module {
    return this.universalModules.find(x => x.internalTitle === type);
  }
}

export default DailyPlanData;
