import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useAuth from "../../hooks/useAuth/useAuth";
import WelcomeComponent from "../../components/vigo-welcome/vigo-welcome.component";
import UserDataService from "../../services/user-data/user-data.service";

const WelcomePage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [welcome, setWelcome] = useState(true);
  useEffect(() => {
    const fetchLang = async () => {
      const lang = user.localeId;
      const langResult = lang === "lv_LV" ? "lv" : "en";
      await i18next.changeLanguage(`${langResult}`);
      setTimeout(() => {
        setWelcome(false);
        window.location.href = "/profile";

        const userData = {
          value: 1,
          type: "open-app-event",
        }
        UserDataService.createUserData(userData, user.id);
      }, 3500);
    };

    fetchLang();
  }, [welcome, setWelcome, user.localeId]);

  return <div> {welcome && <WelcomeComponent welcomeText={t("welcome.screen.title")} />} </div>;
};

export default WelcomePage;
