import { useTranslation } from "react-i18next";
import { DocumentIcon, HeartIcon, TimeIcon } from "../../assets/icons";
import useActivityReport from "../../hooks/useActivityReport/useActivityReport";
import useAuth from "../../hooks/useAuth/useAuth";
import useCompletionRate from "../../hooks/useCompletionRate/useCompletionRate";
import { useEffect, useState } from "react";
import moment from "moment";

const stats = [
  { key: "profile.stat.days_after", icon: HeartIcon, color: "orange" },
  { key: "profile.stat.minutes_vigo", icon: TimeIcon, color: "active" },
  { key: "profile.stat.completion_rate", icon: DocumentIcon, color: "blue" },
];

const ProfileStats = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const activityReport = useActivityReport(user.id);
  const completionRate = useCompletionRate(user.id);

  useEffect(() => {
    const data = activityReport.data ?? [];
    const seconds = data.reduce((acc, obj) => {
      return acc + obj.timeInApp;
    }, 0);
    setMinutesVigo(Math.floor(seconds / 60));
  }, [activityReport.data]);

  useEffect(() => {
    if (completionRate.data && completionRate.data[0]) {
      setProgramCompletion(Math.round(completionRate.data[0].completion * 100));
    } else {
      setProgramCompletion(0);
    }
  }, [completionRate.data]);

  const [minutesVigo, setMinutesVigo] = useState(0);
  const [programCompletion, setProgramCompletion] = useState(0);

  function numberOfDaysSinceStroke(): number {
    if (user.strokeDate) {
      const now = moment();
      const stroke = moment(user.strokeDate);
      return Math.max(0, now.diff(stroke, "days"));
    }
    return 0;
  }

  function valueAt(index: number) {
    switch (index) {
      case 0:
        return numberOfDaysSinceStroke();
      case 1:
        return minutesVigo;
      case 2:
        return `${programCompletion}%`;
      default:
        return 0;
    }
  }

  return (
    <dl className="my-5 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-3 border border-silver rounded-lg ">
      {stats.map((item, index) => (
        <div key={index} className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-2 overflow-hidden rounded-lg">
          <dt>
            <div className="absolute bg-white rounded-md p-3">
              <img src={item.icon} className="h-10 w-10 text-white" alt="" />
            </div>
          </dt>
          <dd className="ml-16 pb-8 flex items-baseline sm:pb-4">
            <p className={`text-6xl font-semibold text-${item.color}`}>{valueAt(index)}</p>
            <div className="absolute bottom-0 inset-x-0 px-4 py-2 sm:px-2">
              <div>
                <p className="ml-4 font-medium text-lg md:text-md sm:pb-4">{t(item.key)}</p>
              </div>
            </div>
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default ProfileStats;
