import { FunctionComponent, useState } from "react";
import Layout from "../../components/layout/layout.component";

import Moods from "./tabs/my-mood/my-mood.tab";
import Activity from "./tabs/activity/activity.tab";
import Goals from "./tabs/program-goals/program-goals.tab";

import { Tab } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const Progress: FunctionComponent = () => {
  const { t } = useTranslation();

  let [categories] = useState({
    "goals.title": [
      {
        id: 1,
        component: <Goals />,
      },
    ],
    "exercise_time.screen.title": [
      {
        id: 1,
        component: <Activity />,
      },
    ],
    "mood.segment.button": [
      {
        id: 1,
        component: <Moods />,
      },
    ],
  });
  return (
    <Layout>
      <Tab.Group>
        <Tab.List className="flex  rounded-xl">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  " w-full py-12 text-xl bg-white leading-5 font-bold",
                  "focus:outline-none border-silver",
                  selected
                    ? "bg-tone text-active border-l border-r "
                    : "text-dark hover:bg-tone border-b over:text-active",
                )
              }
            >
              {t(category)}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {Object.values(categories).map((posts, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                "bg-tone rounded-xl ",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
              )}
            >
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8  h-full">
                {Array.isArray(posts) ? posts.map((post) => <div key={post.id}>{post.component}</div>) : null}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  );
};

export default Progress;
