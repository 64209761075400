import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { lv } from "date-fns/locale";

const MyProgressChart = (props: any) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="m-0 w-full flex flex-col relative justify-end border-silver" style={{ maxHeight: "404px" }}>
        <div className="flex z-10 xl:pl-4 pl-8 ">
          <div className="flex-col mt-auto">
            <div className="text-center">
              <span className="shadow-lg text-xl p-2 font-bold bg-white">{props.performance}</span>
            </div>
            <div
              className={
                props.isColorImpairment
                  ? "rounded-t-xl performance-stripes xl:w-24 w-20 mx-auto mt-4"
                  : "rounded-t-xl bg-lightGreen xl:w-24 w-20 mx-auto mt-4"
              }
              style={{ height: `${props.performance * 37}px` }}
            />
          </div>
          <div className="flex-col mt-auto">
            <div className="text-center  ">
              <span className="shadow-lg text-xl p-2 font-bold bg-white">{props.satisfaction}</span>
            </div>
            <div
              className={
                props.isColorImpairment
                  ? "rounded-t-xl satisfaction-stripes xl:w-24 w-20 mx-auto mt-4"
                  : "rounded-t-xl bg-darkGreen xl:w-24 w-20 mx-auto mt-4"
              }
              style={{ height: `${props.satisfaction * 37}px` }}
            />
          </div>
        </div>
        <div
          className="absolute flex justify-start xl:pl-4 pl-8 "
          // if title exist style botton -40 else -80
          style={{ bottom: props.title ? "-80px" : "-47px" }}
        >
          <p className="text-lg text-dark font-semibold ">
            {format(props.date, i18n.resolvedLanguage === "lv" ? "dd. MMMM" : "MMMM dd", {
              locale: i18n.resolvedLanguage === "lv" ? lv : null,
            })}
            <span className="flex text-xl font-normal">{props.title}</span>
          </p>
        </div>
      </div>
    </>
  );
};
export default MyProgressChart;
