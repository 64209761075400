import { VideoPlayIcon } from "../../assets/icons";

const MessageVideo = (props: any) => {
  return (
    <>
      <button
        key={props.index}
        type="button"
        onClick={() => {
          props.handleVideoOpen(props.message);
        }}
        className="relative block  w-full rounded-t-2xl overflow-hidden"
      >
        <div className="home-img-gradient">
          <img
            src={props.message.thumbnailUrl}
            alt="imageas"
            className="w-full max-h-80  object-cover cursor-pointer "
          />
        </div>

        <div className="absolute inset-0 w-full h-full flex items-center justify-center" aria-hidden="true">
          <img src={VideoPlayIcon} alt="Video Play" />
        </div>
      </button>
    </>
  );
};

export default MessageVideo;
