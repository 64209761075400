import { useQuery } from "react-query";
import VideoExerciseService from "../../services/video-exercises/video-exercises.service";

const useVideoExercise = (id: number, localeId: string) => {
  return useQuery(["videoExercise", id], () => VideoExerciseService.getVideoExercise(id, localeId), {
    staleTime: 30000,
  });
};

export default useVideoExercise;
