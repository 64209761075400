import { useQuery } from "react-query";
import GoalService from "../../services/goal/goal.service";

const useGoal = () => {
  return useQuery(["goal"], () => GoalService.fetch(), {
    staleTime: 30000,
  });
};

export default useGoal;
