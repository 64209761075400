import loginLogo from "../../assets/logos/loginLogo.svg";
import LoginForm from "../../components/login-form/login-form.component";
import { useContext } from "react";
import AuthContext from "../../contexts/auth/auth.context";
import { useTranslation } from "react-i18next";
function LoginPage() {
  const { login: performLogin } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleLogin = async (login: string, password: string) => {
    await performLogin(login, password);
  };

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="absolute left-16 top-16 h-18 w-auto" src={loginLogo} alt="Vigo Health Logo" />
      </div>
      <div className="flex justify-center items-center h-screen ">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
          <div className="bg-white py-12 px-8 shadow-3xl sm:rounded-lg sm:px-16">
            <h2 className="mb-6 text-left text-7xl font-bold ">{t("auth.screen.title")}</h2>
            <LoginForm onLogin={handleLogin} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
