import SubscriptionPlan from "./subscription-plan";
import PromotionCode from "./promotion-code";

export enum SubscriptionState {
    Active = 'ACTIVE',
    AwaitingPayment = 'AWAITING_PAYMENT',
    Expired = 'EXPIRED',
    PaymentFailed = 'PAYMENT_FAILED',
    Paid = 'PAID',
}

export class PaymentMethod {
    id: string;
    brand: string;
    country: string;
    expMonth: number;
    expYear: number;
    last4: string;
}

export class SubscriptionMetadata {
    paymentMethod: PaymentMethod;
    invoice: {
        downloadLink: string;
    };
    plan: {
        id: string;
        amount: string;
        name: string;
    };
}

class Subscription {
    id: number;
    state: SubscriptionState;
    stripeSubscriptionId: string;
    metadata: SubscriptionMetadata;
    createdAt: Date | string;
    expireDate: Date | string;
    userId: number;
    subscriptionPlan: SubscriptionPlan;
    subscriptionPlanId: number;
    isTrial: boolean;
    promotionCode: PromotionCode;
    promotionCodeId: number;
}

export default Subscription;
