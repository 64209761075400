import { ReactNode, useState } from "react";
import AuthContext, { AuthContextState } from "../../contexts/auth/auth.context";
import AuthService from "../../services/auth/auth.service";
import UserService from "../../services/user/user.service";
import LocalStorageService from "../../services/local-storage/local-storage.service";
// import { useHistory } from "react-router-dom";
import User from "../../types/models/user";
import Subscription from "../../types/models/subscription";
// import subscriptionService from "../../services/subscription/subscription.service";
import i18next from "i18next";

function AuthProvider(props: { children: ReactNode }) {
  const [user, setUser] = useState<User>(null);
  const [subscription, setSubscription] = useState<Subscription>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const history = useHistory();

  const handleLogin = async (login: string, password: string) => {
    const tokens = await AuthService.login(login, password);
    LocalStorageService.updateTokens(tokens);
    const user = await UserService.getUserByToken();
    setUser(user);
    // const subscription = await subscriptionService.getLatestSubscription();
    // setSubscription(subscription);
    await i18next.changeLanguage(user.localeId === "lv_LV" ? "lv" : "en");
    setIsLoggedIn(true);
    window.location.href = "/welcome";
  };

  const handleLogout = () => {
    LocalStorageService.clearTokens();
    LocalStorageService.clearChatData();
    setSubscription(null);
    setUser(null);
    setIsLoggedIn(false);
    // history.push("/");
    window.location.href = "/";
  };

  const handleRefreshState = async () => {
    const user = await UserService.getUserByToken();
    setSubscription(subscription);
    setUser(user);
    setIsLoggedIn(true);
  };

  const value: AuthContextState = {
    user,
    isLoggedIn,
    login: handleLogin,
    logout: handleLogout,
    refreshState: handleRefreshState,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export default AuthProvider;
