import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import IUser from "./types/user.type";
import 'reflect-metadata';

import ProfilePage from "./pages/profile/profile.page";
import ProtectedRoute from "./containers/protected-route/protected-route.component";
import LoginPage from "./pages/login/login.page";
import WelcomePage from "./pages/welcome/welcome.page";
import ProgressPage from "./pages/progress/progress.page";
import OnboardingPage from "./pages/onboarding/onboarding.page";
import HomePage from "./pages/home/home.page";
import AdvicePage from "./pages/advice/advice.page";
import DailyPlanPage from "./pages/daily-plan/daily-plan.page";
import AuthProvider from "./containers/auth-provider/auth-provider.component";
import QueryProvider from "./containers/query-provider/query-provider.component";

type Props = {};

type State = {
  showModeratorBoard: boolean;
  showAdminBoard: boolean;
  currentUser: IUser | undefined;
};

class App extends Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // EventBus.on("logout", this.logOut);
  }

  componentWillUnmount() {
    // EventBus.remove("logout", this.logOut);
  }

  render() {
    return (
      <QueryProvider>
        <AuthProvider>
          <Switch>
            <Route exact path={["/"]}>
              <LoginPage />
            </Route>
            <Route exact path={["/welcome"]}>
              <ProtectedRoute>
                <WelcomePage />
              </ProtectedRoute>
            </Route>
            <Route exact path={["/home"]}>
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            </Route>
            <Route exact path={["/progress"]}>
              <ProtectedRoute>
                <ProgressPage />
              </ProtectedRoute>
            </Route>
            <Route exact path={["/advice"]}>
              <ProtectedRoute>
                <AdvicePage />
              </ProtectedRoute>
            </Route>
            <Route exact path={["/daily-plan"]}>
              <ProtectedRoute>
                <DailyPlanPage />
              </ProtectedRoute>
            </Route>
            <Route exact path={["/onboarding"]}>
              <OnboardingPage />
            </Route>
            <Route exact path={["/profile"]}>
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            </Route>
          </Switch>
        </AuthProvider>
      </QueryProvider>
    );
  }
}

export default App;
