import LoginResponse from "../../types/classes/login-response";
import {plainToInstance} from "class-transformer";
import Module from "../../types/models/module";
import Intent from "../../types/models/intent";

class LocalStorageService {
    private _prefix = 'vigo';

    get accessToken() {
        return localStorage.getItem(this._getValueKey('accessToken'));
    }
    set accessToken(value: string | null) {
        this._setValueOrRemove(this._getValueKey('accessToken'), value);
    }

    get refreshToken() {
        return localStorage.getItem(this._getValueKey('refreshToken'));
    }
    set refreshToken(value: string | null) {
        this._setValueOrRemove(this._getValueKey('refreshToken'), value);
    }

    get expiresIn() {
        return localStorage.getItem(this._getValueKey('expiresIn'));
    }
    set expiresIn(value: string | null) {
        this._setValueOrRemove(this._getValueKey('expiresIn'), value);
    }

    get dailyPlanModuleId() {
        const item = localStorage.getItem(this._getValueKey('dailyPlanModuleId'));
        return item ? JSON.parse(item) : null;
    }
    set dailyPlanModuleId(value: number | null) {
        this._setJsonValueOrRemove(this._getValueKey('dailyPlanModuleId'), value);
    }

    get module() {
        const item = localStorage.getItem(this._getValueKey('module'));
        return item
            ? plainToInstance(Module, JSON.parse(item), {
                excludeExtraneousValues: true,
                enableImplicitConversion: true,
            })
            : null;
    }
    set module(value: Module | null) {
        this._setJsonValueOrRemove(this._getValueKey('module'), value);
    }

    get intent() {
        const item = localStorage.getItem(this._getValueKey('intent'));
        return item
            ? plainToInstance(Intent, JSON.parse(item), {
                excludeExtraneousValues: true,
                enableImplicitConversion: true,
            })
            : null;
    }
    set intent(value: Intent | null) {
        this._setJsonValueOrRemove(this._getValueKey('intent'), value);
    }

    get messages() {
        const item = localStorage.getItem(this._getValueKey('messages'));
        return item ? JSON.parse(item) : [];
    }
    set messages(value: any[] | null) {
        this._setJsonValueOrRemove(this._getValueKey('messages'), value);
    }

    public updateTokens(data: LoginResponse){
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this.expiresIn = data.expiresIn.toString();
    }

    public clearTokens(){
        this.accessToken = null;
        this.refreshToken = null;
        this.expiresIn = null;
    }

    public clearChatData() {
        this.dailyPlanModuleId = null;
        this.module = null;
        this.messages = [];
        this.intent = null;
    }

    private _getValueKey(valueName: string) {
        return `${this._prefix}_${valueName}`;
    }

    private _setValueOrRemove(key: string, value: string | null) {
        if(!value) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }

    private _setJsonValueOrRemove(key: string, value: any | null) {
        if(!value) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }
}

export default new LocalStorageService();
