import { useQuery } from "react-query";
import UserTestService from "../../services/user-test/user-test.service";

const useUserTest = (userId: number) => {
  return useQuery(["userTest", userId], () => UserTestService.getUserTestByUserId(userId), {
    staleTime: 5000,
  });
};

export default useUserTest;
