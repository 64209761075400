import Advice from "../../components/advice/advice.component";
import Layout from "../../components/layout/layout.component";

const MeditationData = [
  {
    id: 1,
    title: "Meditation 1",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: true,
  },
  {
    id: 2,
    title: "Meditation 2",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 3,
    title: "Meditation 3",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 4,
    title: "Meditation 4",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 5,
    title: "Meditation 5",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 6,
    title: "Meditation 6",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 7,
    title: "Meditation 7",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 8,
    title: "Meditation 8",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 9,
    title: "Meditation 9",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
  {
    id: 10,
    title: "Meditation 10",
    image: "https://vigothumbnails.s3.eu-central-1.amazonaws.com/speech-games.png",
    new: false,
  },
];

const AdvicePage = () => {
  return (
    <Layout>
      <Advice title="Title 1" data={MeditationData} />
      <Advice title="Title 2" data={MeditationData} />
      <Advice title="Title 3" data={MeditationData} />
      <Advice title="Title 4" data={MeditationData} />
    </Layout>
  );
};

export default AdvicePage;
