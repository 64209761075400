import {FunctionComponent} from "react";
import {Tab} from "@headlessui/react";
import Layout from "../../components/layout/layout.component";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import MyDataTab from "./tabs/my-data/my-data.tab";
import useSubscription from "../../hooks/useSubscription/useSubscription";
import classNames from "classnames";
import {InstallModal} from "../../components/vigo-modals/";
import useAuth from "../../hooks/useAuth/useAuth";
import UserState from "../../types/enums/user-state";
import PaymentsTab from "./tabs/payments/payments.tab";
import BloodPressureTab from "./tabs/blood-pressure/blood-pressure.tab";

export const ProfilePage: FunctionComponent = () => {
  const subscription = useSubscription();
  const { user } = useAuth();
  const { t } = useTranslation();

  function getCategories() {
    const categories = [
      {
        key: "profile.segment.my_data",
        component: <MyDataTab/>,
      }
    ];
    if (user?.state === UserState.Active) {
      categories.push(
          {
            key: "profile.segment.blood_pressure",
            component: <BloodPressureTab />,
          }
      );
    }
    if (subscription.data?.subscriptionPlan?.stripeProductId) {
      categories.push(
          {
            key: "profile.segment.payments",
            component: <PaymentsTab />,
          }
      );
    }
    return categories;
  }

  return (
    <>
      <Layout>
        <InstallModal />
        <Tab.Group>
          <Tab.List className="flex  rounded-xl">
            {getCategories().map((category, index: number) => (
                <Tab
                    key={index}
                    className={({ selected }) =>
                        classNames(
                            " w-full py-12 text-xl bg-white leading-5 font-bold",
                            "focus:outline-none border-silver",
                            selected
                                ? "bg-tone text-active border-l border-r "
                                : "text-dark hover:bg-tone border-b over:text-active",
                        )
                    }
                >
                  {t(category.key)}
                </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {getCategories().map((category, index) => (
              <Tab.Panel
                key={index}
                className={classNames(
                  "bg-tone rounded-xl ",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                )}
              >
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8  h-full">
                  {<div key={index}>{category.component}</div>}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </Layout>
    </>
  );
};

export default ProfilePage;
