import GoalActivity from "./goal-activity";

class UserTest {
  id: number;
  goalActivity: GoalActivity;
  currentMark: number;
  aimMark: number;
  createdAt: Date;
}

export default UserTest;
