import PropTypes from "prop-types";

export default function VigoErrorModal(props: any) {
  return (
    props.modal && (
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-channel border-2 border-firecracker">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 18L18 6M6 6L18 18"
                    stroke="#dd7453"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-xl leading-7 font-medium text-dark">{props.text}</h3>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-firecracker text-base font-medium text-white hover:bg-firecracker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-firecracker sm:text-xl"
                onClick={() => props.setModal(false)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

VigoErrorModal.propTypes = {
  text: PropTypes.string,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
};
