import OnboardingForm from "../..//components/onboarding-form/onboarding-form.component";
import UserService from "../../services/user/user.service";
import { useHistory, useLocation } from "react-router-dom";
import useOnboardingUser from "../../hooks/useOnboardingUser/useOnboardingUser";
import { useContext } from "react";
import AuthContext from "../../contexts/auth/auth.context";
import LoadingComponent from "../../components/loading/loading.component";

function OnboardingPage() {
  const history = useHistory();
  const location = useLocation();
  const { login: performLogin } = useContext(AuthContext);
  const validationHash = location.search.match(/hash=([^&]*)/) ? location.search.match(/hash=([^&]*)/)[1] : null;
  const { data: user } = useOnboardingUser(validationHash);

  const handleSubmit = async (data: any) => {
    await UserService.completeRegistration(validationHash, data);
    await performLogin(data.login, data.password);
    history.push("/profile");
  };

  if (!user) {
    return <LoadingComponent />;
  }
  return (
    <div className="max-w-7xl mx-auto sm:px-6 sm:py-12 lg:px-8">
      <OnboardingForm onSubmit={handleSubmit} initialValues={user} />
    </div>
  );
}

export default OnboardingPage;
