import UserTest from "../../types/models/user-test";
import AbstractService from "../abstract/abstract.service";

class UserTestService extends AbstractService<UserTest> {
  API_SEGMENT = "user-test";

  async getUserTestByUserId(userId: number) {
    const result = await this.axios.get<UserTest[]>(`${this.API_SEGMENT}/patients/${userId}`);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async createUserTests(this: any, data: UserTest[], userId: number) {
    const now = new Date();
    const params = {
      bulk: data.map(x => Object.assign(x, { createdAt: now, userId: userId, goalActivityId: x.goalActivity.id })),
    };
    return await this.axios.post(`${this.API_SEGMENT}/bulk`, params);
  }
}

export default new UserTestService();
