import { useQuery } from "react-query";
import BloodPressureService from "../../services/blood-pressure/blood-pressure.service";

const useBloodPressure = (userId: number) => {
  return useQuery(["bloodPressure", userId], () => BloodPressureService.getBloodPressureByUserId(userId), {
    staleTime: 5000,
  });
};

export default useBloodPressure;
