import Goal from "../../types/models/goal";
import AbstractService from "../abstract/abstract.service";

class GoalService extends AbstractService<Goal> {
  API_SEGMENT = "goal";

  async fetch() {
    const result = await this.axios.get<Goal[]>(`${this.API_SEGMENT}/fetch`);
    if ((result.status >= 200) && (result.status < 300)) {
      return result.data;
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }
}

export default new GoalService();
