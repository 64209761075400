import User from "../../types/models/user";
import AbstractService from "../abstract/abstract.service";
import axios from "axios";
import moment from "moment";
import DailyPlanData from "../../types/models/daily-plan-data";
import {plainToInstance} from "class-transformer";
import DailyPlanHistoryItem from "../../types/models/daily-plan-history-item";
import DailyPlanService from "../daily-plan/daily-plan.service";

class UserService extends AbstractService<User> {
  API_SEGMENT = "user";
  API_SEGMENT_USERS = "users";

  constructor() {
    super();
    this.axios.interceptors.response.use(function (response) {
      return response;
    },  this.dailyPlanAutomateInterceptor);
  }

  private async dailyPlanAutomateInterceptor(error: any) {
    const config = error?.response?.config;
    const status = error?.response?.status;

    if (config && config.automateDailyPlanUserId
        && status && status === 404) {

      await DailyPlanService.automate(config.automateDailyPlanUserId);
      config.automateDailyPlanUserId = null;

      return this.axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }

  async fetchDailyPlan(userId: number, localeId: string) {
    const dateStr = moment().format('yyyy-MM-DD');
    const config = { headers: { 'Accept-Language': localeId }, automateDailyPlanUserId: userId };
    const result = await this.axios.get<DailyPlanData>(`${this.API_SEGMENT}/daily-plan/${dateStr}`, config);
    if (result.status >= 200 && result.status < 300) {
      return plainToInstance(DailyPlanData, result.data, {
        excludeExtraneousValues: true,
        enableImplicitConversion: true
      });
    } else {
      throw new Error(`${result.statusText}: ${result.status}`)
    }
  }

  async getUserByToken() {
    const result = await this.axios.get<User>(`${this.API_SEGMENT}/profile`);

    return result.data;
  }

  async updateUser(id: number, dto: Partial<User>) {
    return this.axios.put<any>(`${this.API_SEGMENT_USERS}/${id}`, dto);
  }

  async postCallRequest(internalTitle: string = null): Promise<any> {
    if (internalTitle) {
      const params = { internalTitle: internalTitle };
      return this.axios.post('call-request', params);
    }
    return this.axios.post('call-request');
  }

  async completeRegistration(hash: string, dto: Partial<User>) {
    const axiosWithoutAuth = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

    const result = await axiosWithoutAuth.put<any>(`${this.API_SEGMENT_USERS}/complete?hash=${hash}`, dto);

    return result.data;
  }

  async checkLogin(login: string): Promise<boolean> {
    const result = await this.axios.get<{ taken: boolean }>(`${this.API_SEGMENT_USERS}/check-login?login=${login}`);

    return result.data.taken;
  }

  async getUserByHash(hash: string) {
    const axiosWithoutAuth = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

    const result = await axiosWithoutAuth.get<User>(`${this.API_SEGMENT_USERS}/hash?hash=${hash}`);

    return result.data;
  }

  async fetchDailyPlanHistory(key: string, localeId: string) {
    const config = { headers: { 'Accept-Language': localeId } };
    const result = await this.axios.get<DailyPlanHistoryItem[]>(`${this.API_SEGMENT}/${key}`, config);
    switch (result.status) {
      case 200:
        return result.data.map(x => plainToInstance(DailyPlanHistoryItem, x, {
          excludeExtraneousValues: true,
          enableImplicitConversion: true
        }));
      case 404:
        return null;
      default:
        throw new Error(`Unexpected status code at daily plan service: ${result.status}`);
    }
  }

  async postHistory(userId: number, dailyPlanModuleId: number) {
    const params = {
      user: userId,
      userDailyPlanModule: dailyPlanModuleId,
    };
    return this.axios.post('user-history', params);
  }

  async toggleFavorites(userId: number, dailyPlanModuleId: number, isFavorite: boolean) {
    if (isFavorite) {
      const params = {
        user: userId,
        userDailyPlanModule: dailyPlanModuleId,
      };
      return this.axios.post('user-favorites', params);
    } else {
      return this.axios.delete(`${this.API_SEGMENT}/favorites/${dailyPlanModuleId}`);
    }
  }
}

export default new UserService();
