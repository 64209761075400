import { useTranslation } from "react-i18next";

const ProfileDisclaimer = () => {
  const { t } = useTranslation();
  return (
      <p className="p-2 text-sm text-dark opacity-90">
        {t("profile.terms.text.0")}
        <br/>
        {t("profile.terms.text.1")}
      </p>
  );
};

export default ProfileDisclaimer;
