import AbstractService from "../abstract/abstract.service";
import PaymentCustomer from "../../types/models/payment-customer";
import Payment from "../../types/models/payment";

class PaymentsService extends AbstractService<any> {
    API_SEGMENT = 'payments'

    async getPaymentCustomer() {
        const result = await this.axios.get<PaymentCustomer>(`${this.API_SEGMENT}/customer`)

        return result.data;
    }

    async getPaymentsHistory() {
        const result = await this.axios.get<Payment[]>(`${this.API_SEGMENT}/history`)

        return result.data;
    }

    async getPortalLink(returnUrl: string) {
        const result = await this.axios.get<{ url: string }>(`${this.API_SEGMENT}/portal?returnUrl=${returnUrl}`)

        return result.data;
    }

    async getCheckoutLink(returnUrl: string) {
        const result = await this.axios.post<{ url: string }>(`${this.API_SEGMENT}/checkout?returnUrl=${returnUrl}`)

        return result.data;
    }
}

export default new PaymentsService();
