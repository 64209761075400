import { StarIcon } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import useDailyPlanHistory from "../../../../hooks/useDailyPlanHistory/useDailyPlanHistory";
import useAuth from "../../../../hooks/useAuth/useAuth";
import LocalStorageService from "../../../../services/local-storage/local-storage.service";
import DailyPlanHistoryItem from "../../../../types/models/daily-plan-history-item";
import UserService from "../../../../services/user/user.service";

function Favorites() {
  const { user } = useAuth();
  const { status, data, error, refetch } = useDailyPlanHistory("favorites", user.localeId);
  const [dataItems, setDataItems] = useState([]);

  const handleDataItemClick = async (item: DailyPlanHistoryItem) => {
    if (item.userDailyPlanModule && item.userDailyPlanModule.module) {
      LocalStorageService.dailyPlanModuleId = item.userDailyPlanModule.id;
      LocalStorageService.module = item.userDailyPlanModule.module;
      LocalStorageService.intent = item.userDailyPlanModule.module.startIntent;
      window.location.href = "/home";
    }
  };

  const removeFromFavorites = async (item: DailyPlanHistoryItem) => {
    await UserService.toggleFavorites(user.id, item.userDailyPlanModule.id, false);
    await refetch();
  };

  useEffect(() => {
    if (data) {
      setDataItems(data);
    }
  }, [status, data]);

  return (
    <div className="py-12 min-h-screen">
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
        {dataItems.map((item) => (
          <li onClick={() => handleDataItemClick(item)} key={item.id} className="relative cursor-pointer">
            {
              <>
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg  overflow-hidden">
                  <span className="inline-block relative">
                    <div className="img-gradient">
                      <img
                        className="rounded-xl z-0 w-72 h-48 object-cover"
                        src={item.userDailyPlanModule.module.localizedOrDefaultThumbnail}
                        alt=""
                      />
                      <img
                        className="absolute z-10 top-5 right-5 block h-7 w-7 rounded-full cursor-pointer"
                        onClick={() => removeFromFavorites(item)}
                        src={StarIcon}
                        alt="Check Icon"
                      />
                    </div>
                  </span>
                </div>
                <p className="mt-2 block text-xl font-bold text-dark  pointer-events-none">
                  {item.userDailyPlanModule.module.localizedTitle}
                </p>
              </>
            }
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Favorites;
