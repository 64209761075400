import { useQuery } from "react-query";
import DailyPlanService from "../../services/daily-plan/daily-plan.service";

const useActivityReport = (userId: number) => {
  return useQuery(["activityReport", userId], () => DailyPlanService.fetchActivityReport(userId), {
    staleTime: 30000,
  });
};

export default useActivityReport;
